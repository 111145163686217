import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
  getProductsRequest,
  getCategoriesRequest,
  getPromotionalRequest,
} from '@fingermarkglobal/restaurant';
import {
  secondaryCategoriesState,
  secondaryProductsState,
  secondaryPromotionalState,
  currentMenuIdState,
  menuIdState,
  syncMenuState,
} from '@fingermarkglobal/atoms';
import { createHydrater } from '@fingermarkglobal/hydrate';
import { filterByVisibilityFlag } from '../../../../../../utilities/product';
import { settingsResolve } from '@fingermarkglobal/settings';
import { differenceInMinutes } from 'date-fns';

const useMenuScheduler = ({ settings }) => {
  const currentMenuId = useRecoilValue(currentMenuIdState);
  const [menuId, setMenuId] = useRecoilState(menuIdState);
  const [secondaryProducts, setSecondaryProducts] = useRecoilState(secondaryProductsState);
  const [isSyncMenuStateCalled] = useRecoilState(syncMenuState);

  const setSecondaryCategories = useSetRecoilState(secondaryCategoriesState);
  const setSecondaryPromotions = useSetRecoilState(secondaryPromotionalState);

  const { menu = 60 } = settings?.settings?.interval?.refresh || {};
  const { serial } = settings || {};
  const menuRefreshInterval = menu * 60 * 1000;

  const setValues = useCallback(
    ({ resolvedCategories, resolvedProducts, resolvedPromotions, newMenuId }) => {
      const productsToHydrate = [...resolvedProducts];

      const productHydration = createHydrater({ items: resolvedProducts });
      const hydratedProducts = productsToHydrate
        .map(({ productId }) => productHydration({ id: productId }))
        .filter(filterByVisibilityFlag);

      setSecondaryProducts(hydratedProducts);
      setSecondaryCategories(resolvedCategories);
      setSecondaryPromotions(resolvedPromotions);

      setMenuId(newMenuId);
    },
    [setSecondaryProducts, setSecondaryCategories, setSecondaryPromotions, setMenuId],
  );

  const syncMenu = useCallback(async () => {
    const { count, settings: resolvedSettings } = await settingsResolve(serial);
    const { menuId: newMenuId = '' } = resolvedSettings || {};

    const { timestamp } = count || {};
    const { _seconds: seconds } = timestamp;

    const lastMenuSync = new Date(seconds * 1000);
    const now = new Date();
    const timeDifference = differenceInMinutes(now, lastMenuSync);

    logger.debug('cringer.components - menu scheduler checking for menu updates');

    if (!newMenuId || (!count && !isSyncMenuStateCalled)) {
      logger.debug('cringer.components - menu scheduler no menu found. aborting...');

      return;
    }

    if (secondaryProducts && menuId === newMenuId && !isSyncMenuStateCalled) {
      logger.debug(
        'cringer.components - menu scheduler new menu already downloaded. awaiting to get to home screen...',
      );
      return;
    }

    // This section checks whether the current menu ID stored at kiosk startup
    // matches the latest synced menu ID.
    // Additionally, it prevents downloading the menu if the last sync occurred less than
    // 5 minutes ago. This delay ensures that images have enough time to download
    // before the new menu is displayed on the kiosk.
    if (newMenuId === currentMenuId || (timeDifference < 5 && !isSyncMenuStateCalled)) {
      logger.debug('cringer.components - menu id did not change or menu updated just recently...');
      return;
    }

    if (isSyncMenuStateCalled) {
      logger.debug(
        `cringer.components - menu scheduler new menu(${newMenuId}) found. reloading...`,
      );
      window.location.reload();
      return;
    }

    const [resolvedProducts, resolvedCategories, resolvedPromotions] = await Promise.all([
      // changed from using `settings` to `resolvedSettings` so it uses the latest menu id
      getProductsRequest(count, resolvedSettings),
      getCategoriesRequest(resolvedSettings),
      getPromotionalRequest(count, resolvedSettings),
    ]);

    logger.debug(
      'cringer.components - menu resolve, updating products, categories and promotions...',
    );

    setValues({
      resolvedCategories,
      resolvedProducts,
      resolvedPromotions,
      newMenuId,
    });
  }, [currentMenuId, serial, setValues, secondaryProducts, menuId, isSyncMenuStateCalled]);

  useEffect(() => {
    if (isSyncMenuStateCalled) {
      return logger.debug(
        'cringer.components - pusher sync enabled. not initializating the menu scheduler. aborting...',
      );
    }

    const interval = setInterval(() => {
      (async () => {
        try {
          syncMenu();
        } catch (error) {
          logger.debug('cringer.components - menu scheduler no menu found. aborting...');

          return;
        }
      })();
    }, menuRefreshInterval);

    return () => clearInterval(interval);
  }, [
    currentMenuId,
    menuRefreshInterval,
    serial,
    setValues,
    settings,
    secondaryProducts,
    menuId,
    syncMenu,
    isSyncMenuStateCalled,
  ]);

  return {
    syncMenu,
  };
};

export { useMenuScheduler };
