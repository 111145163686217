import { USBClient } from '@fingermarkglobal/protocols';
import { isAcknowledged } from '../utils/helpers';
import { getStatusById } from '../utils/enums';
import { parseMessage, isMessageStatus, isMessageResponse, isStatusIdle } from '../utils/parsers';

const executeRequest = async ({ buffer = '', config = {} } = {}) => {
  logger.debug('ksa-mada - request - start');

  const { port, settings } = config || {};
  const { timeout, port: usbPort } = settings || {};

  const usbClientConfig = { usbPort, timeout };

  const usbClient = new USBClient({ port, config: usbClientConfig });

  let message;

  try {
    const { payload: payloadResponse } = await usbClient.send({
      payload: { buffer },
      bufferTimeout: 0,
    });
    const { buffer: hexResponse } = payloadResponse || {};

    if (!isAcknowledged(hexResponse)) {
      throw new Error('The message was not acknowledged by the device');
    }

    do {
      /* eslint-disable no-await-in-loop */
      const { payload: response } = await usbClient.read({ bufferTimeout: 250 });

      message = parseMessage(Buffer.from(response.buffer, 'hex'));

      if (isMessageStatus(message)) {
        if (message.statusArray != null) {
          message.statusArray.forEach(status => {
            logger.debug(
              `ksa-mada - request - received status [${status}] [${getStatusById(status)}]`,
            );
          });
        }

        if (isStatusIdle(message.statusArray)) {
          throw new Error(
            'device is idle without a response, the transaction was failed or cancelled',
          );
        }
      }

      if (!isMessageResponse(message)) {
        logger.debug(
          `ksa-mada - request - still not received a response or idle status, will wait again`,
        );
      }
    } while (!isMessageResponse(message));

    return message?.parsed;
  } catch (error) {
    logger.error(`ksa-mada - request - error ${JSON.stringify(error)}`);
    throw new Error(error);
  } finally {
    if (usbClient?.connectionId) await usbClient.disconnect();
  }
};

export { executeRequest };
