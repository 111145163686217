import { useIdle } from 'react-use';
import { useEffect, useRef, useState } from 'react';

const useTiming = ({ idleTime = 30, warningTime = 10 } = {}) => {
  const isIdle = useIdle(idleTime * 1000);

  const [timeLeft, setTimeLeft] = useState(warningTime);
  const [started, setStarted] = useState(false);
  const [timerExpired, setTimerExpired] = useState(false);

  const timer = useRef({});

  useEffect(() => {
    setTimerExpired(timeLeft < 0);
  }, [setTimerExpired, timeLeft]);

  useEffect(() => {
    if (!started) return;
    if (timer.current.id) return;

    timer.current.id = setInterval(() => {
      try {
        setTimeLeft(time => time - 1);
      } catch (err) {
        logger.error('Components - useTiming - error:', err);
      }
    }, 1000);

    return () => clearInterval(timer.current.id);
  }, [started]);

  const startTimer = () => {
    setStarted(true);
  };

  const resetTimer = () => {
    clearInterval(timer.current.id);
    setStarted(false);
    timer.current = {};
    setTimeLeft(warningTime);
  };

  return {
    isIdle,
    timeLeft: timeLeft >= 0 ? timeLeft : 0,
    startTimer,
    resetTimer,
    timerExpired,
    started,
  };
};

export { useTiming };
