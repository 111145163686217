import { useCallback } from 'react';
import { compact } from 'lodash';
import { offersAndRewardsState, formattedProductsState } from '@fingermarkglobal/atoms';
import { useRecoilValue } from 'recoil';

export const useOffersAndRewardsMenu = () => {
  const offersAndRewards = useRecoilValue(offersAndRewardsState);
  const formattedProducts = useRecoilValue(formattedProductsState);

  const getPromoItem = useCallback(
    ({ productId, title = '', type }) => {
      const product = formattedProducts?.find(item => item.id === productId);
      if (!product) {
        return null;
      }

      const promos = [...offersAndRewards.promosWithPoints, ...offersAndRewards.promos];

      const promoItem = promos?.find(reward => reward.promoId === product.providerData.PromoId);

      if (type === 'combo' && promoItem) {
        const [productName, productSize] = title.split('-').map(namePart => namePart.trim());
        const translatedNameWithSize = compact([promoItem.name, productSize]).join(' - ');
        return {
          ...promoItem,
          name: translatedNameWithSize,
        };
      }

      return promoItem;
    },
    [formattedProducts, offersAndRewards],
  );

  return {
    offersAndRewards,
    getPromoItem,
    offerInCart: false, // TODO: move this logic outside of this hook SUPERAPAC-880
  };
};
