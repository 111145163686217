import React from 'react';
import { useCallback } from 'react';
import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';
import {
  Alert,
  Loading,
  useRestaurantHomeView,
  AdminButton,
  useModalPromise,
  Modal,
} from '@fingermarkglobal/cringer.components';
import { MemberSignInModal } from '../../components/stateless/generic/loyalty-login/MemberSignInModal';
import { CustomModalHeader } from '../../components/stateless/generic/loyalty-login/CustomModalHeader';

const CustomHomeView = () => {
  const {
    t,
    state,
    loading,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    languages,
    primaryAction,
    secondaryAction,
    notAMemberAction,
    onChangeLanguage,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
    showSecondaryAction,
    loyaltyEnabled,
  } = useRestaurantHomeView();

  const { modal, showModal } = useModalPromise({ timeoutSeconds: 10 });

  const [sessionType, setSessionType] = React.useState(null);

  const shouldSkipLoyaltyLogin = useCallback(async () => {
    let skipLoyaltyLogin = true;
    if (!loyaltyEnabled) return { skipLoyaltyLogin };
    try {
      const goToLoyalty = await showModal((resolve, _) => {
        return {
          onLoginClick: () => resolve(true),
          onSkipClick: () => resolve(false),
        };
      });
      skipLoyaltyLogin = !goToLoyalty;
    } catch (e) {
      if (e !== undefined) throw e;
      return { timedOut: true };
    }
    return { skipLoyaltyLogin };
  }, [showModal, loyaltyEnabled]);

  const handleTakeawayWithLoyaltyCheck = useCallback(async () => {
    setSessionType('TAKEAWAY');
    const { skipLoyaltyLogin, timedOut } = await shouldSkipLoyaltyLogin();
    if (timedOut) return;
    primaryAction({ skipLoyaltyLogin });
  }, [primaryAction, shouldSkipLoyaltyLogin]);

  const handleDineInWithLoyaltyCheck = useCallback(async () => {
    setSessionType('DINE_IN');

    const { skipLoyaltyLogin, timedOut } = await shouldSkipLoyaltyLogin();
    if (timedOut) return;
    secondaryAction({ skipLoyaltyLogin });
  }, [secondaryAction, shouldSkipLoyaltyLogin]);

  const params = {
    accessibilityEnabled,
    languages,
    onChangeLanguage,
    primaryAction: handleTakeawayWithLoyaltyCheck,
    secondaryAction: showSecondaryAction ? handleDineInWithLoyaltyCheck : undefined,
    t,
    card,
    cash,
    icons,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-8">
      <AdminButton onActivate={onAdminActivate} />
      {loading && <Loading isFirstLoad={true} />}
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {state?.matches('success') &&
        (!isOnline ? (
          <Alert type="warning" message={t('homeConnectivityIssues')} />
        ) : showSecondaryHome ? (
          <SecondaryHome {...params} />
        ) : (
          <PrimaryHome {...params} />
        ))}

      <Modal
        open={modal.open}
        ModalHeader={CustomModalHeader}
        title={t('loyaltyLoginOrSkipModalTitle')}
      >
        <MemberSignInModal
          t={t}
          sessionType={sessionType}
          notAMemberAction={notAMemberAction}
          {...modal}
        />
      </Modal>
    </div>
  );
};

export { CustomHomeView };
