import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import { LoyaltyLoginPayload } from '@fingermarkglobal/types';
import ky from 'ky';
import isNetworkError from 'is-network-error';

const loyaltyQrLogin = async ({
  payload,
  abortSignal,
}: {
  payload: LoyaltyLoginPayload;
  abortSignal: AbortSignal;
}) => {
  logger.info('fm-api - loyaltyQrLogin - started');

  const maxRetries = 10;

  for (let i = 0; i < maxRetries; i++) {
    try {
      const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/qr-login-result`;
      if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT)
        throw new Error(
          `fm-api - loyaltyQrLogin - failed to get loyalty login endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.`,
        );

      const response = await ky.get(endpoint, {
        signal: abortSignal,
        searchParams: {
          kioskId: payload.kioskSerialKey,
          storeId: payload.storeId,
          sessionId: payload.sessionId,
        },
        retry: 0,
        timeout: false,
      });

      if (response.status === 204) {
        continue;
      }

      const responseJson = await response.json();

      if (!responseJson)
        throw new Error(`fm-api - loyaltyQrLogin - request failed. Response {${responseJson}}`);

      logger.info('fm-api - loyaltyQrLogin - finished', responseJson);
      return responseJson;
    } catch (err) {
      // Needed to detect when 504 Gateway Timeout recieved
      if (isNetworkError(err)) {
        logger.log('fm-api - loyaltyQrLogin - timeout - retrying:', err);
        continue;
      }

      if (err instanceof HTTPError || err instanceof TimeoutError) throw err;
      logger.error('fm-api - loyaltyQrLogin - error:', err);
      throw err;
    }
  }

  throw new Error('QR Scan has run out of retries');
};

export { loyaltyQrLogin };
