import { useCallback, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useCart } from '@fingermarkglobal/cart';
import { settingsState, formattedSettingsState } from '@fingermarkglobal/atoms';
import { useRestaurantCart } from '../../../../hooks/restaurant/cart';
import { useCustomer } from '../../../../hooks/restaurant/customer';
import { useOrigin } from '../../../../hooks/generic/origin';
import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';
import { usePrice } from '../../../../hooks/generic/utilities/price';
import { useSettingsAtPath } from '@fingermarkglobal/utilities';
import { useAnalytics } from '@fingermarkglobal/jitsu';

const useMiniCart = ({
  origin = '',
  onSelectCustomisationProduct,
  shouldSkipCustomerDetailsPath = false,
}) => {
  const [showCartPreview, setShowCartPreview] = useState(false);
  const kioskSettings = useRecoilValue(formattedSettingsState);

  const history = useHistory();
  const { t } = useLocaleSelection();
  const { total, count, onCartUpdateHandler } = useRestaurantCart();
  const { orderCompletedTrack, cartItemEditTrack } = useAnalytics();

  const { customer } = useCustomer();
  const { cart } = useCart();
  const { setOrigin } = useOrigin();

  const customerSkipPath = '/validate';
  const nextPath = '/customer-details';

  const { formatPrice } = usePrice();

  const beaconEnabled = useSettingsAtPath({
    path: 'settings.modes.beacon',
    defaultValue: false,
  });

  const computedNextPath =
    beaconEnabled && shouldSkipCustomerDetailsPath
      ? customerSkipPath
      : !!customer
      ? customerSkipPath
      : nextPath;

  useEffect(() => {
    if (count === 0 && showCartPreview) {
      setShowCartPreview(false);
    }
  }, [count, showCartPreview, setShowCartPreview]);

  const settingsObject = useRecoilValue(settingsState);
  const { settings = {} } = settingsObject || {};

  const { miniCart = false } = settings?.modes || {};

  const completeOrder = () => {
    orderCompletedTrack();
    history.push(computedNextPath);
  };

  const nextAction = useCallback(() => {
    orderCompletedTrack();
    history.push(computedNextPath);
  }, [history, computedNextPath, orderCompletedTrack]);

  const cartHandler = !miniCart
    ? completeOrder
    : () => {
        setShowCartPreview(!showCartPreview);
      };

  const onEditProductHandler = product => () => {
    const { type = '', uid = '' } = product;

    cartItemEditTrack(kioskSettings.kiosk);

    setOrigin(origin);

    switch (type) {
      case 'combo':
        return history.push(`/product/${product.productId}/combo/${product.id}/cart/${uid}`);
      case 'customise':
        setShowCartPreview(false);
        return onSelectCustomisationProduct(product);
      default:
        return history.push(`/product/${product.productId}`);
    }
  };

  return {
    miniCart,
    t,
    total,
    count,
    cart,
    onCartUpdateHandler,
    onEditProductHandler,
    cartHandler,
    showCartPreview,
    nextAction,
    formatPrice,
  };
};

export { useMiniCart };
