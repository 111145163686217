import React from 'react';
import ShoppingCart from 'heroicons/solid/shopping-cart.svg';
import { Link } from 'react-router-dom';

const BasketCount = ({
  className = '',
  path,
  count = 0,
  onClick = () => {},
  Icon = ShoppingCart,
  OverwriteIcon = null,
} = {}) => {
  const BasketIcon = OverwriteIcon || Icon;

  const BasketCountLink = ({ children = null, className = '' }) => {
    if (count && !path)
      return (
        <a className={className} onClick={onClick}>
          {children}
        </a>
      );

    if (count > 0 && path)
      return (
        <Link className={className} to={path || '/customer-details'}>
          {children}
        </Link>
      );

    return <span className={className}>{children}</span>;
  };

  return (
    <BasketCountLink className={`relative inline-block ${className}`}>
      <BasketIcon id="cart-icon" className="w-8 md:w-16 h-auto" />
      <span
        data-test="basket-count"
        className="absolute -top-1 md:-top-2 -right-1 md:-right-2 inline-flex items-center justify-center w-6 md:w-12 h-6 md:h-12 text-lg sm:text-2xl font-bold text-secondary bg-primary rounded-full"
      >
        {count ? count : '0'}
      </span>
    </BasketCountLink>
  );
};

export { BasketCount };
