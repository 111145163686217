const validation = ({ handler, callback }) => {
  if (!handler) throw new Error('No `handler` passed into a command handler');
  if (!callback) throw new Error('No `callback` passed into a command handler');
};

// callback is a function that sends a response to a `client-${serial}` event
// payload is optional data that gets sent with the command

const handleReboot = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });
  handler();
};

const handleScreenshot = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });

  const image = handler();
  callback(image.toDataURL());
};

const handleLock = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });
  handler(payload);
};

const handleUnlock = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });
  handler();
};

const handleReload = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });
  handler();
};

const handleSyncMenu = ({ handler = null, callback = null } = {}) => payload => {
  validation({ handler, callback });
  handler();
};

export { handleReboot, handleScreenshot, handleLock, handleUnlock, handleReload, handleSyncMenu };
