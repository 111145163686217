import { useMemo, useState, useEffect, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { resolveMenu } from '@fingermarkglobal/menu.resolver';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { createHydrater } from '@fingermarkglobal/hydrate';
import {
  filterByVisibilityFlag,
  updatePricesByTimeRestrictions,
  filterImageUrlFallback,
  filterByTimeRestrictions,
} from '../../../utilities/product';
import { useSettings } from '@fingermarkglobal/utilities';
import { useRestaurantLock } from '../lock';

import {
  countState,
  productsState,
  formattedProductsState,
  categoriesState,
  formattedCategoriesState,
  promotionalState,
  rawProductsState,
} from '@fingermarkglobal/atoms';

const useRestaurantMenu = () => {
  const [menuSend, setMenuSend] = useState();
  const [menuState, setMenuState] = useState();

  const flags = useFlags();

  const [formattedProducts] = useRecoilState(formattedProductsState);
  const setRawProducts = useSetRecoilState(rawProductsState);
  const products = useRecoilValue(productsState);
  const setProducts = useSetRecoilState(productsState);
  const categories = useRecoilValue(formattedCategoriesState);

  const setCategories = useSetRecoilState(categoriesState);
  const [promotions, setPromotions] = useRecoilState(promotionalState);
  const [count] = useRecoilState(countState);

  const { settings } = useSettings();
  const { isWithinWorkingHours, lockMachineState } = useRestaurantLock();

  const isKioskClosed = settings?.settings?.site?.isKioskClosed ?? false;

  const populateComboImages = (product, previousImages) => {
    const images = [];

    product?.combo?.filters?.forEach(filter => {
      const { imageUrl } = filter || {};

      if (!previousImages.includes(imageUrl)) {
        const img = new Image();
        img.src = imageUrl;

        images.push(imageUrl);
      }
    });

    return images;
  };

  // Pre load the images before user interaction
  const populateImages = useCallback(({ source = [] } = []) => {
    source.reduce((previousImages, currentItem) => {
      const { imageUrl } = currentItem || {};

      const comboImages =
        currentItem?.type === 'combo' ? populateComboImages(currentItem, previousImages) : [];

      // Do not load repeated images
      if (previousImages.includes(imageUrl)) return [...previousImages, ...comboImages];

      const img = new Image();
      img.src = imageUrl;

      return [...previousImages, imageUrl, ...comboImages];
    }, []);
  }, []);

  const loading = useMemo(() => {
    if (!menuState) return true;
    const states = ['idle', 'waiting', 'populating'];
    return states.map(state => menuState.matches(state)).some(item => item);
  }, [menuState]);

  const existing = useMemo(() => {
    const existing = [products, promotions, categories];
    return existing.every(item => item);
  }, [products, promotions, categories]);

  const retry = useCallback(() => {
    menuSend({ event: 'EXTERNAL_TRANSITION_RETRY' });
  }, [menuSend]);

  useEffect(() => {
    /* Before we fetch the menu, we need to check if the kiosk is closed or outside working hours 
       because we redirect to the lock page when it's true.
       
       After getting the response from the menu, we try to update the menuState.
       Due to redirection, the state is no longer available, leading to a memory leak. 
       To avoid it, we need to check the status of the kiosk before starting the process.

       But, we ignore if the kiosk is closed when the user unlock it manually through the admin page
    */
    if (isKioskClosed || (!isWithinWorkingHours() && !lockMachineState.matches('manualUnlocked'))) {
      return;
    }

    if (menuState) return;

    const run = resolveMenu({
      transition: machine => setMenuState(machine),
    });

    // If the items are already in the store then skip straight to complete
    if (existing) run({ event: 'SKIP' });
    if (!existing) run({ event: 'INITIALISE', config: { count, settings } });

    // NOTE: first function would normally return previous state...
    setMenuSend(() => (...args) => run(...args));
  }, [
    count,
    existing,
    settings,
    menuState,
    setMenuSend,
    setMenuState,
    isKioskClosed,
    isWithinWorkingHours,
    lockMachineState,
  ]);

  useEffect(() => {
    if (!menuState) return;
    if (!menuState.matches('waiting')) return;

    const { context } = menuState;

    const productsToHydrate = [...context?.products];

    const productHydration = createHydrater({ items: context?.products });
    const rawHydratedProducts = productsToHydrate
      .map(product => {
        const hydratedProduct = productHydration({ id: product.productId });
        return updatePricesByTimeRestrictions({ product: hydratedProduct, settings });
      })
      .map(filterImageUrlFallback);

    const hydratedProducts = rawHydratedProducts
      .filter(filterByVisibilityFlag)
      .filter(filterByTimeRestrictions({ settings, flags }))
      .map(product => updatePricesByTimeRestrictions({ product, settings }))
      .map(filterImageUrlFallback);

    const promotions = Array.isArray(context?.promotions) ? context?.promotions : [];

    populateImages({
      source: [...hydratedProducts, ...promotions, ...context?.categories],
    });
    setProducts(hydratedProducts);
    setRawProducts(rawHydratedProducts);
    setPromotions(context?.promotions);
    setCategories(context?.categories);
  }, [
    menuState,
    settings,
    flags,
    setProducts,
    setPromotions,
    setCategories,
    setRawProducts,
    populateImages,
  ]);

  useEffect(() => {
    if (!existing) return;

    if (!menuState) return;
    if (!menuState.matches('waiting')) return;

    menuSend({ event: 'EXTERNAL_TRANSITION_SUCCESS' });
  }, [existing, menuSend, menuState]);

  return {
    send: menuSend,
    state: menuState,
    retry,
    loading,
    settings,
    products: formattedProducts,
    categories,
    promotions,
  };
};

export { useRestaurantMenu };
