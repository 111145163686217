import React from 'react';
import { setLoyaltyLogoutSuccessTrack } from '@fingermarkglobal/jitsu';
import { useLoyaltyBanner } from './hook';
import { LoggedIn } from './LoggedIn';
import { LoggedOut } from './LoggedOut';
import { DefaultSignOutModal } from './DefaultSignoutModal';

const EMPTY_FUNC = () => {};

const LoyaltyBanner = ({
  computedAction = EMPTY_FUNC,
  SignOutModal = DefaultSignOutModal,
  hasOffersPageLink = false,
  MemberTitleComponent,
  GuestTitleComponent,
  signOutModalEnabled = false,
}) => {
  const {
    modal,
    showModal,
    cancelOrder,
    loyaltyEnabled,
    openLoyaltyLoginPage,
    loyaltyUser,
    isLoggedIn,
    modalAccessibilityClass,
    t,
  } = useLoyaltyBanner();

  const handleSignout = async () => {
    const shouldSignout = await showModal((resolve, _) => {
      return {
        onSignOut: () => resolve(true),
        onCancel: () => resolve(false),
      };
    });
    if (shouldSignout) {
      setLoyaltyLogoutSuccessTrack();
      cancelOrder();
    }
  };

  if (!loyaltyEnabled) return <></>;
  if (isLoggedIn)
    return (
      <>
        <LoggedIn
          loyaltyUser={loyaltyUser}
          computedAction={computedAction}
          onSignOut={handleSignout}
          MemberTitleComponent={MemberTitleComponent}
          hasOffersPageLink={hasOffersPageLink}
          t={t}
        />
        <SignOutModal modal={modal} t={t} modalAccessibilityClass={modalAccessibilityClass} />
      </>
    );
  return (
    <LoggedOut
      GuestTitleComponent={GuestTitleComponent}
      openLoyaltyLoginPage={openLoyaltyLoginPage}
      t={t}
    />
  );
};

export { LoyaltyBanner };
