import React, { Fragment } from 'react';

import { useSecondaryResolver } from './hook';
import { Alert as GenericAlert } from '../../../../components/stateless/alert';
import { Loading as GenericLoading } from '../../../../components/stateless/loading';

const SecondaryResolverView = ({
  Loading = GenericLoading,
  Alert = GenericAlert,
  children = null,
} = {}) => {
  const { state, retry } = useSecondaryResolver();
  return (
    <Fragment>
      {state?.matches('failure') && (
        <div className="flex items-center justify-center h-screen p-8">
          <Alert type="danger" retry={retry} message={state.context.message} />
        </div>
      )}
      {state?.matches('loading') && (
        <div className="flex items-center justify-center h-screen">
          <Loading isFirstLoad={true} />
        </div>
      )}
      {state?.matches('success') && children}
    </Fragment>
  );
};

export { SecondaryResolverView };
