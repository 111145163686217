import loglevel from 'loglevel';
import stringify from 'stringify-object';
import async from 'async';
import axios from 'axios';
import { isObject } from 'mout/lang';
import { validate } from '@fingermarkglobal/validation';
import { getRelevantLevel } from '../utilities';

class NewRelicLogger {
  constructor({
    fields = {},
    project = null,
    level = 'trace',
    apiKey = undefined,
    endpoint = undefined,
    batchNumber = 20,
    interval = 30,
    retries = 3,
    retriesInterval = 5,
  } = {}) {
    validate({ name: 'Logger', parameters: { project, apiKey, endpoint } });

    this.fields = fields;
    this.project = project;
    this.level = level;
    this.apiKey = apiKey;
    this.endpoint = endpoint;
    this.batchNumber = batchNumber;
    this.interval = interval;
    this.retries = retries;
    this.retriesInterval = retriesInterval;

    this.init();
  }

  init() {
    this.logQueue = [];

    this.scopes = {};
    this.additionalFields = {};
    this.overrideProject = null;

    this.loglevel = loglevel;

    // Show only log levels based on `level`
    this.loglevel.setDefaultLevel(this.level);

    this.updateAdditionalFields();

    this.processLogs = this.processLogs.bind(this);
    this.handleBatch = this.handleBatch.bind(this);
    this.sendLogsBatch = this.sendLogsBatch.bind(this);
    setInterval(this.processLogs, parseInt(this.interval, 10) * 1000);
  }

  clearAdditionalFields() {
    this.additionalFields = {};
  }

  updateAdditionalFields({ fields = {} } = {}) {
    this.additionalFields = {
      ...this.fields,
      ...this.additionalFields,
      ...fields,
    };
  }

  async resetLogs() {
    await this.processLogs();
  }

  async sendLogsBatch(logs) {
    try {
      await axios.post(this.endpoint, [{ logs }], {
        headers: { 'Api-Key': this.apiKey },
      });
    } catch (err) {
      // handle it but don't log it otherwise it will get into an infinite loop if it's offline
    }
  }

  handleBatch() {
    const batch = this.logQueue.splice(0, this.batchNumber);
    let batchSize = JSON.stringify(batch).length;
    // New Relic has a limit of 1MB
    const maxSize = 900 * 1024;
    if (batchSize > maxSize) {
      let i = batch.length - 1;
      while (i >= 0 && batchSize > maxSize) {
        const logSize = JSON.stringify(batch[i]).length;
        if (batchSize - logSize <= maxSize) {
          this.logQueue.unshift(batch[i]);
          batchSize -= logSize;
          batch.splice(i, 1);
        }
        i -= 1;
      }
    }
    return batch;
  }

  async processLogs() {
    if (this.logQueue.length === 0 || !this.endpoint || !this.apiKey) return;
    const processBatch = async () => {
      const batch = this.handleBatch();
      try {
        await async.retry(
          { times: this.retries, interval: parseInt(this.retriesInterval, 10) * 1000 },
          async () => {
            await this.sendLogsBatch(batch);
          },
        );
      } catch (error) {
        this.logQueue.unshift(...batch);
      }
    };
    do {
      processBatch();
    } while (this.logQueue.length > 0);
  }

  addLog({ level = null, options = [] } = {}) {
    validate({ name: 'log', parameters: { level } });

    const loglevelNumber = loglevel.levels[level.toUpperCase()];

    if (loglevelNumber < this.loglevel.getLevel()) return;

    // Determine whether or not there are 'custom' options
    const duplicate = [...options];
    const config = duplicate.pop();
    const custom = isObject(config) && config.loggerOptions;

    // Set based on whether or not custom options exist
    const messages = custom ? duplicate : options;
    const fields = custom ? config.fields || {} : {};
    const scopes = custom ? config.scopes || [] : [];
    const project = custom ? config.project : this.overrideProject || this.project;

    const scoped = scopes.reduce(
      (result, next) => ({
        ...result,
        ...this.scopes[next],
      }),
      {},
    );

    const message = messages.reduce((result, next) => {
      if (next) {
        if (!result.length) return stringify(next);
        if (next instanceof Error) {
          const errorName = next?.name || 'Unknown error name';
          const errorMessage = next?.message || 'Unknown error message';
          return `${result} | ${errorName} ${errorMessage}`;
        }
        return `${result} | ${stringify(next)}`;
      }
      return result;
    }, '');

    this.loglevel[level](...messages);
    const currentDate = new Date();
    const timestamp = currentDate.getTime();

    const customAdditionalFields = { ...this.additionalFields };

    if (
      config?.sendSessionId !== null &&
      config?.sendSessionId !== undefined &&
      config?.sendSessionId === false &&
      customAdditionalFields.sessionId
    ) {
      delete customAdditionalFields.sessionId;
    }

    const stackTrace = options.find(element => element?.stack)?.stack;
    const componentStackTrace = options.find(element => element?.componentStack)?.componentStack;

    this.logQueue.push({
      timestamp,
      project,
      level: getRelevantLevel({ level }),
      message: JSON.stringify(message),
      time: new Date().toISOString(),
      ...(stackTrace && { stackTrace }),
      ...(componentStackTrace && { componentStackTrace }),
      ...this.fields,
      ...customAdditionalFields,
      ...scoped,
      ...fields,
    });
  }

  // Methods from loglevel...
  // https://github.com/pimterry/loglevel#documentation
  log(...args) {
    this.addLog({ level: 'debug', options: args });
  }

  info(...args) {
    this.addLog({ level: 'info', options: args });
  }

  warn(...args) {
    this.addLog({ level: 'warn', options: args });
  }

  trace(...args) {
    this.addLog({ level: 'trace', options: args });
  }

  debug(...args) {
    this.addLog({ level: 'debug', options: args });
  }

  error(...args) {
    this.addLog({ level: 'error', options: args });
  }
}

export { NewRelicLogger };
