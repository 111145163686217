import {
  PreOrderPayload,
  SaveOrderPayload,
  PlaceOrderPayload,
  checkRequestDetails,
} from '@fingermarkglobal/types';

export const isLocalUrl = (url: string) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  const host = match && match[2] ? match[2] : null;

  if (!host) {
    // No valid host found in the URL
    return false;
  }

  // Define regular expressions for IPv4 and IPv6 and localhost variations
  const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  const ipv4LocalhostPattern = /^(https?:\/\/)?(127\.0\.0\.1|localhost)(:\d+)?(\/.*)?$/i;
  const ipv6LocalhostPattern = /^(https?:\/\/)?(\[::1\]|localhost)(:\d+)?(\/.*)?$/i;

  // Check if the URL matches the patterns for IPv4 or IPv6 localhost addresses
  return (
    ipv4Pattern.test(host) ||
    ipv6Pattern.test(host) ||
    ipv4LocalhostPattern.test(url) ||
    ipv6LocalhostPattern.test(url)
  );
};

type MergedPayload = PreOrderPayload | SaveOrderPayload | PlaceOrderPayload | checkRequestDetails;

type RequestOptionArgs = {
  endpoint: string;
  payload: MergedPayload;
  token: string;
  timeout: number;
};

type GetRequestOptionArgs = {
  endpoint: string;
  token: string;
  timeout: number;
  query: any;
};

type GetRequestOptions = {
  headers: any;
  timeout: number;
  searchParams: any;
};

type RequestOptions = {
  json: any;
  headers: any;
  timeout: number;
};

export const buildRequestOptions = ({
  endpoint,
  token,
  timeout = 30,
  payload,
}: RequestOptionArgs): RequestOptions => {
  return {
    json: payload,
    timeout: timeout * 1000,
    headers: { Authorization: `Bearer ${token}` },
    ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
  };
};

export const buildGetRequestOptions = ({
  endpoint,
  token,
  query,
  timeout = 30,
}: GetRequestOptionArgs): GetRequestOptions => {
  return {
    timeout: timeout * 1000,
    headers: { Authorization: `Bearer ${token}` },
    searchParams: query,
    ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
  };
};
