import React from 'react';
import { Modal } from '../../stateless/modal';
import { useLoyaltyBanner } from './hook';

export const DefaultSignOutModal = ({ modal, modalAccessibilityClass, t }) => {
  return (
    <Modal
      className={modalAccessibilityClass}
      open={modal.open}
      title={t('loyaltyLogOutModalConfirm')}
      action={modal.onCancel}
      closeModal={modal.onSignOut}
      actionText={t('cancelOrderButtonModalPrimaryAction')}
      closeText={t('cancelOrderButtonModalSecondaryAction')}
    />
  );
};
