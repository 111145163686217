import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useRootViewContext } from '../../../root/provider';
import { resolveRestaurant } from '@fingermarkglobal/restaurant.resolver';

const usePrimaryResolver = () => {
  const {
    count,
    setCount,
    serial,
    setSerial,
    system,
    setSystem,
    settings,
    setSettings,
    transactions,
    setTransactions,
    setCurrentMenuId,
    resolverProps,
    retryTime,
  } = useRootViewContext();

  const [restaurantRun, setRestaurantRun] = useState();
  const [restaurantState, setRestaurantState] = useState();

  const { isRetryActive = false } = resolverProps;

  const [timeLeft, setTimeLeft] = useState(retryTime);
  const [timerExpired, setTimerExpired] = useState(false);
  const timer = useRef({});

  // TODO: refactor the useTiming hook and use it here.
  // Task: https://fingermarkglobal.atlassian.net/browse/S2-3803
  useEffect(() => {
    setTimerExpired(timeLeft <= 0);
  }, [setTimerExpired, timeLeft]);

  useEffect(() => {
    if (!restaurantState?.matches('failure') && isRetryActive) return;

    const id = setInterval(() => {
      try {
        setTimeLeft(time => time - 1);
      } catch (err) {
        logger.error('components - useTiming - error in useTiming at primary resolver:', err);
      }
    }, 1000);

    timer.current.id = id;

    return () => clearInterval(id);
  }, [restaurantState, isRetryActive]);

  useEffect(() => {
    if (!timerExpired) return;
    window.location.reload();
  }, [timerExpired]);

  const loading = useMemo(() => {
    if (!restaurantState) return true;
    const states = ['serial', 'settings', 'waiting', 'transactions'];
    return states.map(state => restaurantState.matches(state)).some(item => item);
  }, [restaurantState]);

  const retry = useCallback(() => {
    setTimeLeft(retryTime);
    return restaurantRun({ event: 'EXTERNAL_TRANSITION_RESET' });
  }, [restaurantRun, setTimeLeft, retryTime]);

  useEffect(() => {
    if (restaurantState) return;

    const run = resolveRestaurant({
      transition: machine => setRestaurantState(machine),
    });

    // NOTE: first function would normally return previous state...
    setRestaurantRun(() => (...args) => run(...args));
  }, [restaurantState, setRestaurantRun, setRestaurantState]);

  useEffect(() => {
    if (!restaurantState) return;
    if (!restaurantState.matches('waiting')) return;

    const {
      port,
      count,
      serial,
      origin,
      settings: resolvedSettings,
      transactions: resolvedTransactions,
    } = restaurantState.context;

    setCount(count);
    setSerial(serial);
    setSettings(resolvedSettings);
    setTransactions(resolvedTransactions);
    setCurrentMenuId(resolvedSettings?.menuId || '');
    setSystem({
      type: origin,
      port,
    });
  }, [
    setCount,
    setSerial,
    setSystem,
    setSettings,
    setTransactions,
    setCurrentMenuId,
    restaurantState,
  ]);

  useEffect(() => {
    if (!restaurantState) return;
    if (!restaurantState.matches('waiting')) return;

    if (count === null) return;
    if (system === null) return;
    if (serial === null) return;
    if (settings === null) return;
    if (transactions === null) return;

    // Once everything is set then we can resolve to next step.
    restaurantRun({ event: 'EXTERNAL_TRANSITION_SUCCESS' });
  }, [count, system, serial, settings, transactions, restaurantRun, restaurantState]);

  return {
    retry,
    loading,
    settings,
    transactions,
    state: restaurantState,
    timeLeft,
    isRetryActive,
  };
};

export { usePrimaryResolver };
