import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  orderTotalState,
  orderCountState,
  validatedOrderPayloadState,
  templateState,
  settingsState,
  onlineStatusState,
} from '@fingermarkglobal/atoms';
import { useAccessibility } from '../../../components/stateful/accessibility/hook';
import { useLocaleSelection } from '../../../hooks/generic/locale-selection';
import { usePrice } from '../../../hooks/generic/utilities/price';

const useTemplate = ({
  timeoutEnabled = false,
  templateOverride = false,
  accessibilityActionEnabled,
  footerEnabled,
}) => {
  const { formatPrice } = usePrice();
  const { accessibilityEnabled } = useAccessibility();
  const { t } = useLocaleSelection();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const total = useRecoilValue(orderTotalState);
  const count = useRecoilValue(orderCountState);
  const settings = useRecoilValue(settingsState);
  const orderPayload = useRecoilValue(validatedOrderPayloadState);
  const onlineStatus = useRecoilValue(onlineStatusState);
  const { isOnline = true } = onlineStatus;
  const { settings: config = {} } = settings;
  const { content = {}, homePageCustomisation } = config;
  const { homeBurger: isSecondaryHome } = content;
  const homeMode = isSecondaryHome ? 'secondary' : 'primary';
  const { taxes = null } = orderPayload?.financialSummary || {};
  const formattedTaxes = total === 0 ? 0 : taxes && Number(taxes.toFixed(2));
  const isCouponEnabled = settings?.settings?.modes?.coupon;
  const hasUnavailableItems = orderPayload?.cart?.items?.some(item => !item.isAvailable);
  const {
    header,
    footer,
    timeoutEnabledOverride = timeoutEnabled,
    timeoutActionOverride,
    ...rest
  } = useRecoilValue(templateState);
  const currentRoute = history?.location?.pathname || '';

  const modalAccessibilityClass = accessibilityEnabled
    ? 'h-half bottom-0 left-0 right-0'
    : 'inset-0';

  const isTimeoutEnabled = templateOverride ? timeoutEnabledOverride : timeoutEnabled;

  const menuText = t('templateMenuAction');
  const menuAction = useCallback(() => history.push('/categories'), [history]);
  const couponText = t('templateCouponAction');
  const computedAction = useCallback(
    path => history.push(path, { ...locationState, from: currentRoute }),
    [history, currentRoute, locationState],
  );
  const couponAction = useCallback(() => computedAction('/coupon'), [computedAction]);
  const basketText = t('templateBasketAction');
  const cancelOrderText = t('templateCancelAction');
  /**
   * These are static props which means that they aren't view dependent.
   * Even though, the views can still override them.
   */
  const staticProps = {
    menuAction,
    computedAction,
    couponAction,
    menuText,
    couponText,
    cancelOrderText,
    basketText,
    currentRoute,
    total: formatPrice({ price: total }),
    count,
    showAccessibilityButton: accessibilityActionEnabled && footerEnabled,
    showCouponButton: false,
    formattedTaxes: formatPrice({ price: formattedTaxes }),
  };

  const getBackgroundImage = (props = {}) => {
    const fallbackImage = isSecondaryHome ? props.secondaryImage : props.primaryImage;
    const serverImageUrl = isSecondaryHome
      ? homePageCustomisation?.secondaryBackgroundUrl
      : homePageCustomisation?.primaryBackgroundUrl;
    const images = [serverImageUrl, fallbackImage]
      .filter(image => image)
      .map(image => `url('${image}')`);
    if (images.length === 0) {
      return {};
    }
    return { backgroundImage: images.join(',') };
  };

  return {
    ...rest,
    modalAccessibilityClass,
    accessibilityEnabled,
    isTimeoutEnabled,
    isCouponEnabled,
    header: { ...staticProps, ...header, homeMode },
    footer: { ...staticProps, ...footer },
    showAccessibilityButton: accessibilityActionEnabled,
    timeoutActionOverride,
    t,
    isOnline,
    hasUnavailableItems,
    getBackgroundImage,
    isSecondaryHome,
  };
};

export { useTemplate };
