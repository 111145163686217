import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMachine } from '@xstate/react';
import { flow } from '../utilities/flow';
import { loadingService } from '../../../../../services/loading';
import { useRootViewContext } from '../../../root/provider';
import { useSystem } from '../../../../../hooks/generic/system';
import { rebootState, reloadState, onlineStatusState } from '@fingermarkglobal/atoms';
import { resolveI18n } from './i18n';
import { resolveCommands } from './commands';
import { resolveMonitoring } from './monitoring';
import { resolveEnvironment } from './environment';
import { resolveReboots } from './reboot';
import { useMenuScheduler } from './menu';
import { resolveReload } from './reload';

const useSecondaryResolver = () => {
  const { settings, setSettings, serial, overwriteSettings = {} } = useRootViewContext();
  const { loadingMachine } = loadingService({ initial: 'idle' });
  const [state, send] = useMachine(loadingMachine);

  const setShouldReboot = useSetRecoilState(rebootState);
  const [reloadStateValue, setReloadState] = useRecoilState(reloadState);
  const { lastReloadDate } = reloadStateValue || {};

  const setOnlineStatus = useSetRecoilState(onlineStatusState);

  const { lock, reboot, screenshot, unlock, reload, syncMenu } = useSystem();

  useMenuScheduler({ settings });

  useEffect(() => {
    if (!state.matches('idle')) return;
    send('START');

    (async send => {
      try {
        // Initialise services, crons and listeners here
        const { settings: resolvedSettings } = await flow([
          resolveCommands({ lock, unlock, reboot, screenshot, serial, reload, syncMenu }),
          resolveI18n({ settings, overwriteSettings }),
          resolveMonitoring({ serial, setOnlineStatus }),
          resolveEnvironment,
          resolveReboots({ setShouldReboot }),
          resolveReload({ setReloadState, lastReloadDate }),
        ]);

        setSettings(resolvedSettings);

        send('SUCCESS');
      } catch (error) {
        send('FAILURE', { message: error });
      }
    })(send);
  }, [
    send,
    state,
    settings,
    setSettings,
    lock,
    unlock,
    reboot,
    screenshot,
    serial,
    setShouldReboot,
    overwriteSettings,
    reload,
    setReloadState,
    lastReloadDate,
    setOnlineStatus,
    syncMenu,
  ]);

  const retry = () => {
    send('RESET');
  };

  return {
    state,
    retry,
  };
};

export { useSecondaryResolver };
