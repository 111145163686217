const isProduction = process.env.POI_APP_ENV === 'prd' || process.env.POI_APP_ENV === 'canary';
const isUat = process.env.POI_APP_ENV === 'uat';

const blockClick = event => {
  event.preventDefault();
  event.stopPropagation();
  return false;
};

const blockRightClick = () => {
  const shouldBlock = isProduction || isUat;

  if (shouldBlock) window.oncontextmenu = blockClick;
};

export { blockRightClick };
