import React from 'react';

import { MiniCart } from '../../../../stateful/mini-cart';
import { usePreviousLocation } from '../../../../../hooks/generic/previous-location';

const MiniCartWrapper = ({
  onSelectCustomisationProduct = null,
  showMiniCartSlider = false,
  count = 0,
  shouldSkipCustomerDetailsPath = false,
  showComboFilterName = true,
} = {}) => {
  const showCart = showMiniCartSlider && count;

  const previousLocation = usePreviousLocation();

  if (!showCart || !previousLocation?.pathname) return null;

  return (
    <div className="relative">
      <MiniCart
        origin={previousLocation?.pathname}
        showCart={showCart}
        onSelectCustomisationProduct={onSelectCustomisationProduct}
        shouldSkipCustomerDetailsPath={shouldSkipCustomerDetailsPath}
        showComboFilterName={showComboFilterName}
      />
    </div>
  );
};

export { MiniCartWrapper };
