import React from 'react';

import {
  PaymentMethods,
  BannerWarning,
  PrimaryButtonLarge,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import Logo from '../../../assets/images/logo.svg';
import LoveBucketImage from '../../../assets/images/love-bucket.png';
import DefaultHeroImage from '../../../assets/images/burger.png';

const SecondaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  healthMsg = true,
  className = '',
  primaryAction,
  secondaryAction,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const isBordered = !!(icons?.length || card);

  return (
    <HomeCustomisation.Provider
      fallback={{
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden  ${className}`}
      >
        <div
          data-test="home-upper"
          className="flex flex-col items-center justify-start w-full h-full"
        >
          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}
        </div>

        <div
          data-test="home-lower"
          className="flex flex-col-reverse items-center justify-start w-full h-full"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              className={`w-4/5 mb-${accessibilityEnabled ? '28' : '64'}`}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={LoveBucketImage}
              className={`w-4/5 mb-${
                showTermsAndConditionsButton ? '2' : accessibilityEnabled ? '28' : '64'
              }`}
            />
          )}

          <div
            data-test="home-services"
            className={`flex items-stretch justify-center w-4/5 ${
              healthMsg ? 'mb-16' : 'mb-72'
            } space-x-16`}
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                className="w-1/2"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            {!!primaryAction && (
              <PrimaryButtonLarge
                className="w-1/2"
                data-test="button-primary"
                onClick={primaryAction}
              >
                {t('homePrimaryAction')}
              </PrimaryButtonLarge>
            )}
          </div>
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
