import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import mockSettings from '../../../../../../../mock-settings.json';

const unionLanguages = ({ settings = {}, customSettings = {} }) => {
  const keys = Object.keys(settings);

  const merged = keys.reduce(
    (result, lang) => ({
      ...result,
      [lang]: {
        translation: {
          ...settings[lang]?.translation,
          ...customSettings[lang]?.translation,
        },
      },
    }),
    {},
  );

  return { languages: { ...merged } };
};

const resolveI18n = ({ settings, serial, overwriteSettings = {} }) => async next => {
  try {
    // TODO: Resolve from settings once new translations
    // get ported to KAS.
    const isOverwrited = !!Object.keys(overwriteSettings).length;
    const mergedLanguages = unionLanguages({
      settings: { ...mockSettings.languages },
      customSettings: { ...overwriteSettings.languages },
    });

    const mergedSettings = isOverwrited
      ? {
          ...mockSettings,
          languages: {
            ...mockSettings.languages,
            ...mergedLanguages.languages,
            default: settings?.settings?.languages?.default,
          },
        }
      : mockSettings;

    const { languages } = mergedSettings;

    const i18nConfig = {
      resources: languages,
      lng: 'en',

      keySeparator: false,

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    };

    i18n.use(initReactI18next).init(i18nConfig);

    next(null, {
      serial,
      settings: {
        ...settings,
        settings: { ...settings.settings, ...mergedSettings },
      },
    });
  } catch (error) {
    next(error);
  }
};

export { resolveI18n, unionLanguages };
