import { differenceInMinutes } from 'date-fns';

const INTERVAL = 60 * 1000;

const checkReloadSchedule = ({ settings, lastReloadDate, callback }) => {
  const refreshAppInterval = settings?.settings?.interval?.refresh?.app || 60;
  const currentDate = new Date();
  const lastReloadInMinutes = differenceInMinutes(currentDate, lastReloadDate);

  if (lastReloadInMinutes >= refreshAppInterval) {
    callback({ shouldReload: true, lastReloadDate: currentDate });
  }
};

const setup = ({ settings, lastReloadDate, callback }) => {
  const run = () => {
    try {
      checkReloadSchedule({ settings, lastReloadDate, callback });
    } catch (err) {
      logger.error('components - reload - setup - error:', err);
    }
  };

  setInterval(run, INTERVAL);
};

const resolveReload = ({ setReloadState, lastReloadDate }) => ({ settings }, next) => {
  setup({ settings, lastReloadDate, callback: setReloadState });

  next(null, { settings });
};

export { resolveReload };
