import { atom, selector } from 'recoil';

const lockState = atom({
  key: 'lockState',
  default: null,
});

const lockMessageState = atom({
  key: 'lockMessageState',
  default: 'lockMessage',
});

const systemState = atom({
  key: 'systemState',
  default: null,
});

const serialState = atom({
  key: 'serialState',
  default: null,
});

const settingsState = atom({
  key: 'settingsState',
  default: null,
});

const menuIdState = atom({
  key: 'menuIdState',
  default: null,
});

const currentMenuIdState = atom({
  key: 'currentMenuIdState',
  default: null,
});

const syncMenuState = atom({
  key: 'syncMenuState',
  default: null,
});

const formattedSettingsState = selector({
  key: 'formattedSettingsState',
  // This will most eventually be moved to a `api-only settings` endpoints
  // and the request for settings will be made in whiplash / webstor
  get: ({ get }) => {
    const settingsObject = get(settingsState);

    const { settings = {}, timezone, serial, name: device_name } = settingsObject || {};

    const {
      labs = {},
      order = {},
      mocks = {},
      properties = {},
      datasource = {},
      site = {},
      payment = {},
      interval = {},
      taxRegion = {},
      content = {},
      receipt = {},
    } = settings;

    const { type, headers = {} } = order;
    const { order: mock } = mocks;
    const { currency } = payment;
    const { type: receiptType } = receipt;

    const { storeId, customer: organisation } = datasource;
    const menuOrganisationId = process.env.POI_APP_ORGANISATION_ID;

    const {
      deviceId: deviceFirestoreId,
      storeId: storeFirestoreId,
      organisation: organisationFirestoreId,
    } = properties;

    const { autoRetry } = interval;

    const apexLoggingProject = process.env.POI_APP_LOGGER_PROJECT;

    return {
      mock,
      labs,
      apexLoggingProject,
      interval: {
        autoRetry,
      },
      providerType: type,
      provider: order[type],
      kiosk: {
        organisation,
        serial,
        storeId,
        timezone,
        currency,
        organisationFirestoreId,
        storeFirestoreId,
        deviceFirestoreId,
        headers,
        device_name,
        menuOrganisationId,
        taxRegion,
        site,
        receiptType,
      },
    };
  },
});

const metadataState = atom({
  key: 'metadataState',
  default: {
    accessibilityEnabled: false,
  },
});

const rebootState = atom({
  key: 'rebootState',
  default: null,
});

const reloadState = atom({
  key: 'reloadState',
  default: {
    lastReloadDate: new Date(),
    shouldReload: false,
  },
});

export {
  lockState,
  lockMessageState,
  systemState,
  serialState,
  settingsState,
  metadataState,
  rebootState,
  formattedSettingsState,
  menuIdState,
  currentMenuIdState,
  reloadState,
  syncMenuState,
};
