import React, { Fragment } from 'react';

import { usePrimaryResolver } from './hook';
import { Alert as GenericAlert } from '../../../../components/stateless/alert';
import { Loading as GenericLoading } from '../../../../components/stateless/loading';

const PrimaryResolverView = ({
  Loading = GenericLoading,
  Alert = GenericAlert,
  children = null,
} = {}) => {
  const { state, retry, loading, timeLeft, isRetryActive } = usePrimaryResolver();

  const additionalMessage = isRetryActive ? `Kiosk will try to recover itself in ${timeLeft}` : '';

  return (
    <Fragment>
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <Loading dataTest="resolver-loading" isFirstLoad={true} />
        </div>
      )}
      {state?.matches('failure') && (
        <div className="flex items-center justify-center h-screen p-8">
          <Alert
            type="danger"
            onRetryAction={retry}
            additionalMessage={additionalMessage}
            message={state.context.message}
          />
        </div>
      )}
      {state?.matches('success') && children}
    </Fragment>
  );
};

export { PrimaryResolverView };
