import { RequestBuilder } from './RequestBuilder';
import { PaymentConfig, InquiryMessage, TransType, FoodDescription } from '../types';
import { getCurrentFormattedDate, parseCheckNumber } from '../utils';
import { parseTransactionAmout } from '../utils/parse-transaction-amount';

export class InquiryRequestBuilder extends RequestBuilder {
  constructor({
    amount,
    config,
    idempotencyKey,
    referenceNumber,
  }: {
    amount: number;
    config: PaymentConfig;
    idempotencyKey: string;
    referenceNumber: string;
  }) {
    super({ method: 'POST', amount, config, idempotencyKey, referenceNumber });
  }

  setBody(): void {
    const { settings } = this.config;
    const {
      responseChannelType,
      isLocal,
      messageType,
      responsePayloadFormat,
      taxIndicator = "1",
      taxType = "TX"
    } = settings;

    const { identifiers } = this.getIdentifiersFromPairingResponse();

    const inquiryMessage: InquiryMessage = {
      messageId: this.getIntegratorId(),
      responseChannelType,
      responseChannel: { responsePayloadFormat },
      messageType,
      message: {
        referenceNumber: this.referenceNumber,
        identifiers,
        transType: TransType.Inquiry,
        transAmount: parseTransactionAmout(this.amount),
        transactionDateTime: getCurrentFormattedDate(isLocal),
        compliance: {
          uniqueDeviceId: this.terminalSerialNumber,
          customerCode: this.referenceNumber
        },
        industry: {
          data: {
            chargeDescription: FoodDescription.foodAndBev,
            checkNumber: parseCheckNumber(this.referenceNumber),
            foodDescription: FoodDescription.foodAndBev,
            employeeNumber1: 999,
            foodAmount: parseTransactionAmout(this.amount),
            beverageAmount: '0.00',
            tip1: '0.00'
          }
        },
      },
    };

    this.body = inquiryMessage;
  }

  getBody(): InquiryMessage {
    return this.body;
  }
}
