import React from 'react';
import { useLocaleSelection } from '../../../hooks/generic/locale-selection';

import 'spinkit/spinkit.css';

const Spinner = ({ className }) => {
  return (
    <div className={`sk-chase ${className}`}>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  );
};

const useFormattedMessages = ({ title, message, isFirstLoad } = {}) => {
  if (title === '' && message === '' && isFirstLoad === false) {
    const { t } = useLocaleSelection();
    const formattedTitle = t('defaultLoadingTitle');
    const formattedMessage = t('defaultLoadingMessage');

    return { formattedTitle, formattedMessage };
  }

  return { formattedTitle: title, formattedMessage: message };
};

const Loading = ({
  'data-test': dataTest = 'loading',
  title = '',
  message = '',
  Image = null,
  isFirstLoad = false,
} = {}) => {
  const { formattedTitle, formattedMessage } = useFormattedMessages({
    title,
    message,
    isFirstLoad,
  });

  return (
    <div
      data-test={dataTest}
      className="flex flex-col items-center justify-center space-y-12 text-3xl md:space-y-32 md:text-4xl loading-wrapper h-3/3"
    >
      {!isFirstLoad && <p className="font-bold loading-wrapper-title">{formattedTitle}</p>}
      {Image ? (
        <Image className="block h-1/2 loading-wrapper-image" />
      ) : (
        <Spinner
          className={`w-40 h-40 md:w-64 md:h-64 loading-wrapper-spinner ${isFirstLoad && 'mt-8'}`}
        />
      )}
      {!isFirstLoad && <p className="text-center loading-wrapper-message">{formattedMessage}</p>}
    </div>
  );
};

export { Loading };
