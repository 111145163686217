/** 
    @see https://www.npmjs.com/package/@jitsu/sdk-js
*/
import { useCallback, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setIdentify } from './identify';
import {
  setDineMode,
  setProductAddedToCart,
  setProductRemoved,
  setUpsellAddedToCart,
  setOrderUpdated,
  setOrderCompleted,
  setPaymentStarted,
  setOrderViewed,
  setPaymentCompleted,
  setProductViewed,
  setUpsellDismissed,
  setPage,
  setBeginCheckoutTrack,
  setUpsizeItemSelected,
  setCartItemEdit,
  setItemSelectTrack,
  setEmbeddedErrorTrack,
  setViewItemListTrack,
} from './track';
import { setResetAnalytics } from './reset';
import { JITSU_LOG_TAG } from './utilities';
import { EVENT_KEYS, getEventTag } from './constants';

export const useAnalytics = ({ isFirstCall = false } = {}) => {
  const { ga4EnableYumGlobalTags } = useFlags();

  useEffect(() => {
    if (isFirstCall) {
      if (process.env.POI_APP_JITSU_API_KEY) {
        logger.debug(`${JITSU_LOG_TAG} enabled`);
      } else {
        logger.error(`${JITSU_LOG_TAG} disabled. Jitsu API key is missing`);
      }
    }
  }, [isFirstCall]);

  // Helper function to append event tag automatically
  const appendEventTag = useCallback(
    (eventKey, func, ...args) => {
      const eventTag = getEventTag(eventKey, ga4EnableYumGlobalTags);
      func(...args, eventTag);
    },
    [ga4EnableYumGlobalTags],
  );

  const setAnalyticsIdentify = useCallback(async (session_id, settings) => {
    await setIdentify(session_id, settings);
  }, []);

  const resetAnalytics = useCallback(() => {
    setResetAnalytics();
  }, []);

  return {
    setAnalyticsIdentify,
    resetAnalytics,
    setPageTrack: setPage,
    dineModeTrack: (...args) => appendEventTag(EVENT_KEYS.DINE_MODE, setDineMode, ...args),
    productAddedToCartTrack: (...args) =>
      appendEventTag(EVENT_KEYS.PRODUCT_ADDED, setProductAddedToCart, ...args),
    beginCheckoutTrack: (...args) =>
      appendEventTag(EVENT_KEYS.BEGIN_CHECKOUT, setBeginCheckoutTrack, ...args),
    productRemovedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.PRODUCT_REMOVED, setProductRemoved, ...args),
    upsizeItemSelectedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.UPSIZE_SELECTED, setUpsizeItemSelected, ...args),
    upsellAddedToCartTrack: (...args) =>
      appendEventTag(EVENT_KEYS.UPSELL_ADDED, setUpsellAddedToCart, ...args),
    cartItemEditTrack: (...args) =>
      appendEventTag(EVENT_KEYS.EDIT_CART_ITEM, setCartItemEdit, ...args),
    orderCompletedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.ORDER_COMPLETED, setOrderCompleted, ...args),
    paymentStartedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.PAYMENT_STARTED, setPaymentStarted, ...args),
    paymentCompletedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.PAYMENT_COMPLETED, setPaymentCompleted, ...args),
    itemSelectTrack: (...args) =>
      appendEventTag(EVENT_KEYS.SELECT_ITEM, setItemSelectTrack, ...args),
    embeddedErrorTrack: (...args) =>
      appendEventTag(EVENT_KEYS.SHOW_EMBEDDED_ERROR, setEmbeddedErrorTrack, ...args),
    productViewedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.PRODUCT_VIEWED, setProductViewed, ...args),
    orderViewedTrack: (...args) => appendEventTag(EVENT_KEYS.ORDER_VIEWED, setOrderViewed, ...args),
    orderUpdatedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.ORDER_UPDATED, setOrderUpdated, ...args),
    upsellDismissedTrack: (...args) =>
      appendEventTag(EVENT_KEYS.UPSELL_DISMISSED, setUpsellDismissed, ...args),
    setViewItemListTrack: (...args) =>
      appendEventTag(EVENT_KEYS.VIEW_ITEM_LIST, setViewItemListTrack, ...args),
  };
};
