import React from 'react';

const PrimaryButtonLarge = ({
  className = 'capitalize',
  onClick = () => {},
  children,
  disabled = false,
  'data-test': dataTest = 'button-primary',
}) => {
  return (
    <PrimaryButton
      className={`py-6 px-8 min-w-24 text-button-large focus:outline-none border-primary-button bg-primary-button text-primary-button font-button-large ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
    >
      {children}
    </PrimaryButton>
  );
};

const PrimaryButton = ({
  className = 'capitalize',
  onClick = () => {},
  children,
  disabled = false,
  'data-test': dataTest = 'button-primary',
}) => {
  return (
    <button
      disabled={disabled}
      className={`py-4 px-3 text-button rounded-button border-button border-primary-button bg-primary-button text-primary-button disabled:opacity-50 font-button custom-primary-button primary-button font-button-regular ${className}`}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
    </button>
  );
};

export { PrimaryButton, PrimaryButtonLarge };
