import React from 'react';

export const NotAMemberSection = ({ t, notAMemberAction }) => {
  return (
    <div className="pb-5 text-3xl text-center" onClick={notAMemberAction}>
      <p className="font-bold">{t('loyaltyNotAMemberQuestion')}</p>
      <p>{t('loyaltyNotAMemberAnswer')}</p>
    </div>
  );
};
