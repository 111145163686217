import React from 'react';
import { LoadableImage } from '../../stateful/loadable-image';

const BannerWarning = ({
  className = '',
  image = null,
  t = () => {},
  width = '',
  placeholder = null,
  title = t('bannerWarningTitle'),
  description = t('bannerWarningDescription'),
  additionalDescription = null,
  additionalDescriptionAction = () => {},
  backgroundColor = 'bg-warning',
  additionalDescriptionColor = 'text-blue-800',
  dataTest = 'banner-warning',
} = {}) => {
  return (
    <div
      data-test={dataTest}
      className={`flex justify-start items-center px-6 md:px-12 py-4 rounded-lg ${backgroundColor} ${className}`}
    >
      {image && (
        <LoadableImage
          className="mb-6 sm:mb-0 sm:mr-6 md:mr-12"
          src={image}
          alt="Banner warning"
          width={width}
          placeholder={placeholder}
          data-test="banner-image"
        />
      )}
      <div className="flex flex-col">
        <p data-test="banner-title" className="text-3xl font-bold sm:text-4xl">
          {title}
        </p>
        <div className="flex">
          <p data-test="banner-description" className="text-2xl sm:text-3xl">
            {description}
            {additionalDescription && (
              <span
                data-test="banner-additional-description"
                onClick={additionalDescriptionAction}
                className={`ml-1 text-2xl ${additionalDescriptionColor} sm:text-3xl`}
              >
                {additionalDescription}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export { BannerWarning };
