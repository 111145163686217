import { waterfall, detectSeries } from 'async-es';

const validateDependency = async (item, handler) => {
  try {
    const result = await item;
    handler(null, result);
  } catch (error) {
    handler(error);
  }
};

// https://medium.com/velotio-perspectives/understanding-node-js-async-flows-parallel-serial-waterfall-and-queues-6f9c4badbc17
const flow = (dependencies = []) =>
  new Promise((resolve, reject) =>
    waterfall(dependencies, (error, response) => {
      if (error) return reject(error);
      return resolve(response);
    }),
  );

// As soon as a promise resolve's with a value resolve the value
// https://caolan.github.io/async/v3/docs.html#detectSeries
const find = (dependencies = []) =>
  new Promise((resolve, reject) =>
    detectSeries(dependencies, validateDependency, (error, result) => {
      if (error) return reject(error);
      return resolve(result);
    }),
  );

export { flow, find };
