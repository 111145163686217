import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RootViewProvider as Root, DefaultTemplate } from '@fingermarkglobal/cringer.components';
import { buildRoutes } from './utilities';

import { base } from './config';

const RouteBuilder = ({ Template = DefaultTemplate, root = {}, config = base }) => {
  const flags = useFlags();
  config.cart.config.provider = {
    ...config.cart.config.provider,
    ...{ showOrderTotalSummary: !!flags.kioskEnableOrderTotalSummary }
  }

  const routes = buildRoutes({ config, Template, Route });

  return (
    <Router>
      <Root {...root}>
        <Switch>
          {routes.map(route => {
            // This needs to be inlined as components get wrapped in fragments which breaks routing
            // https://github.com/remix-run/react-router/issues/5785
            // this is all to allow `404` pages to work
            const { Route, Template, View, Provider, url, config } = route;

            return (
              <Route key={url} exact path={url}>
                <Template {...(config?.template || {})}>
                  <Provider {...(config?.provider || {})}>
                    <View {...(config?.props || {})} />
                  </Provider>
                </Template>
              </Route>
            );
          })}
        </Switch>
      </Root>
    </Router>
  );
};

export { RouteBuilder, base };
