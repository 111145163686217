import { SaleRequestBuilder } from '../../../builders';
import { PaymentContext, TransType } from '../../../types';
import { destructSaleTransaction } from '../../../utils/destructerers/sale';
import { requestTransaction } from './request-transaction';

export const requestSaleTransaction = async (context: PaymentContext) => {
  const response = await requestTransaction({
    RequestBuilder: SaleRequestBuilder,
    context,
    transactionType: TransType.Sale,
  });

  return destructSaleTransaction(response, context);
};
