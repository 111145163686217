import { ResponseCodeResult, TransactionStatus, TransType } from '../types';
import { inquiryNeeded, inquiryNoNeeded } from './data-map';

export const responseCodeHandler = (responseCode: string, type: TransType): ResponseCodeResult => {
  const numericCode = parseInt(responseCode, 10);

  logger.info(`[Elavon] Handling "${type}" transaction with response code "${numericCode}"`);

  if (Number.isNaN(numericCode)) {
    // This transaction, if successful, don't have a responseCode
    if (type === TransType.Inquiry || type === TransType.Cancel) {
      logger.info(`[Elavon] Successfully completed "${type}" transaction`);

      return {
        status: TransactionStatus.success,
        friendlymessage: type === TransType.Inquiry ? 'Inquiry completed' : 'Transaction cancelled',
      };
    }

    // Same
    if (type === TransType.Sale) {
      logger.info(`[Elavon] Successfully cancelled "${type}" transaction`);

      return {
        status: TransactionStatus.cancel,
        friendlymessage: 'Transaction cancelled',
      };
    }

    logger.error(
      `[Elavon] Unknown or missing response code for "${type}" transaction`,
      responseCode,
    );

    return {
      status: TransactionStatus.failure,
      friendlymessage: 'Unknown or missing response code',
    };
  }

  const inquiryNeededItem = inquiryNeeded.find(response => response.code === numericCode);
  const inquiryNoNeededItem = inquiryNoNeeded.find(response => response.code === numericCode);

  if (numericCode === 0) {
    logger.info(`[Elavon] Successfully completed "${type}" transaction`);

    return {
      status: TransactionStatus.success,
      friendlymessage: 'Transaction completed successfully',
    };
  }

  if (inquiryNeededItem) {
    logger.info(`[Elavon] Inquiry needed for response code "${numericCode}"`);

    return {
      status: TransactionStatus.inquiry,
      friendlymessage: inquiryNeededItem.friendlymessage,
    };
  }

  if (inquiryNoNeededItem) {
    logger.info(`[Elavon] Inquiry not needed for response code "${numericCode}"`);

    return {
      status: TransactionStatus.failure,
      friendlymessage: inquiryNoNeededItem.friendlymessage,
    };
  }

  logger.error(`[Elavon] Unknown response code "${numericCode}" for "${type}" transaction`);

  return {
    status: TransactionStatus.failure,
    friendlymessage: 'Unknown response code',
  };
};
