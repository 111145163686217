class TimeoutError extends Error {
  code: number;
  constructor(message: string) {
    super('[Kiosk] ' + message);
    this.name = 'TimeoutError';
    this.code = 408;
  }
}

const AXIOS_TIMEOUT = 'ECONNABORTED';
const CHROME_MESSAGE_TIMEOUT = 'Response Timeout';

/**
 * Executes a provided asynchronous function and checks for timeout errors.
 * If the error is a known timeout (Axios or Chrome), it throws a TimeoutError with HTTP status 408.
 * Otherwise, it propagates the original error.
 */
export async function executeWithTimeoutCheck(fn: () => Promise<any>) {
  try {
    const result = await fn();
    return result;
  } catch (err) {
    if (err.code === AXIOS_TIMEOUT || err.message === CHROME_MESSAGE_TIMEOUT) {
      throw new TimeoutError(err.message);
    } else {
      throw err;
    }
  }
}
