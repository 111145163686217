import { validate } from '@fingermarkglobal/validation';

const mergeEventObject = ({ previous = {}, next = {} } = {}) => {
  validate({ name: 'mergeEventObject', parameters: { next, previous } });

  const merged = Object.entries(previous).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: [...value, ...(next[key] || [])],
    }),
    {},
  );

  return {
    // Spread `next` in the event there are 'keys' that `previous` did not have...
    ...next,
    ...merged,
  };
};

const getRelevantLevel = ({ level = null } = {}) => {
  validate({ name: 'getRelevantLevel', parameters: { level } });

  switch (level) {
    case 'info':
      return 'info'; // notice
    case 'warn':
    case 'trace':
      return 'warning'; // alert
    case 'error':
      return 'error';
    case 'log':
    case 'debug':
    default:
      return 'debug';
  }
};

export { mergeEventObject, getRelevantLevel };
