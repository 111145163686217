import { atom, selector } from 'recoil';

const countState = atom({
  key: 'countState',
  default: null,
});

const promotionalState = atom({
  key: 'promotionalState',
  default: null,
});

const rawProductsState = atom({
  key: 'rawProductsState',
  default: null,
});

const productsState = atom({
  key: 'productsState',
  default: null,
});

const formattedProductsState = atom({
  key: 'formattedProductsState',
  default: null,
});

const categoriesState = atom({
  key: 'categoriesState',
  default: null,
});

const formattedCategoriesState = selector({
  key: 'formattedCategoriesState',
  get: ({ get }) => {
    const categories = get(categoriesState) || [];
    return categories
      .filter(({ isVisible }) => isVisible)
      .sort((a, b) => {
        const { order: orderA = 99 } = a;
        const { order: orderB = 99 } = b;
        return orderA - orderB;
      });
  },
});

const categoryState = atom({
  key: 'categoryState',
  default: null,
});

const secondaryPromotionalState = atom({
  key: 'secondaryPromotionalState',
  default: null,
});

const secondaryProductsState = atom({
  key: 'secondaryProductsState',
  default: null,
});

const secondaryCategoriesState = atom({
  key: 'secondaryCategoriesState',
  default: null,
});

const offersAndRewardsState = atom({
  key: 'offersAndRewardsState',
  default: {
    promos: [],
    promosWithPoints: [],
  },
});

const showOriginalPriceForComboState = atom({
  key: 'showOriginalPriceForComboState',
  default: false,
});

export {
  countState,
  promotionalState,
  rawProductsState,
  productsState,
  categoriesState,
  formattedCategoriesState,
  formattedProductsState,
  categoryState,
  secondaryCategoriesState,
  secondaryProductsState,
  secondaryPromotionalState,
  offersAndRewardsState,
  showOriginalPriceForComboState,
};
