import { useRecoilValue } from 'recoil';
import { formatPrice } from '@fingermarkglobal/utilities';
import { computedMetadata } from '@fingermarkglobal/atoms';

const usePrice = () => {
  const { currency } = useRecoilValue(computedMetadata);

  const format = (params = {}) => formatPrice({ ...params, currency });

  return { formatPrice: format };
};

export { usePrice };
