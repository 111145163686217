export const isLocalUrl = (url: string): boolean => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  const host = match && match[2] ? match[2] : null;

  if (!host) {
    return false;
  }

  const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
  const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  const ipv4LocalhostPattern = /^(https?:\/\/)?(127\.0\.0\.1|localhost)(:\d+)?(\/.*)?$/i;
  const ipv6LocalhostPattern = /^(https?:\/\/)?(\[::1\]|localhost)(:\d+)?(\/.*)?$/i;

  return (
    ipv4Pattern.test(host) ||
    ipv6Pattern.test(host) ||
    ipv4LocalhostPattern.test(url) ||
    ipv6LocalhostPattern.test(url)
  );
};

export const buildGetRequestOptions = ({ endpoint, token, query, timeoutInSeconds = 30 }: any): any => {
  return {
    timeout: timeoutInSeconds * 1000,
    headers: { Authorization: `Bearer ${token}` },
    searchParams: query,
    ...(isLocalUrl(endpoint) && { prefixUrl: '' }),
  };
};
