import { createMachine, assign, LogAction } from 'xstate';
import { loyaltyQrLogin } from '../requests/loyalty-qr-login';
import { LoyaltyLoginPayload } from '@fingermarkglobal/types';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`api - loyaltyQrLogin - FSM data recieved:`, data);
      return data;
    }

    if (error) {
      logger.error(`api - loyaltyQrLogin - FSM error recieved:`, error);
      return error;
    }

    logger.error(`api - loyaltyQrLogin - FSM message recieved:`, message);
    return message;
  },
});

type MachinePayloadParameters = {
  payload?: null | LoyaltyLoginPayload;
};

const createLoyaltyQrLogInMachine = ({ payload = null }: MachinePayloadParameters = {}) =>
  createMachine<{
    data: unknown;
    payload: null | LoyaltyLoginPayload;
  }>(
    {
      id: 'loyaltyQrLogin',
      initial: 'processing',
      context: {
        payload,
        data: null,
      },
      states: {
        processing: {
          invoke: {
            src: 'loyaltyQrLogin',
            onDone: {
              target: 'success',
              actions: ['populateMessage'],
            },
            onError: {
              target: 'error',
              actions: ['populateMessage'],
            },
          },
        },
        success: {
          on: {
            IDLE: 'processing',
          },
        },
        error: { type: 'final' },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

export { createLoyaltyQrLogInMachine };
