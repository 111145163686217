import React from 'react';
import { PrimaryButtonLarge } from '@fingermarkglobal/cringer.components';
import { CustomModalHeader } from './CustomModalHeader';

export const LoginFailedModal = ({ onClose, t }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <CustomModalHeader title={t('loyaltyLoginFailedModalTitle')} />
      <div className="flex flex-col flex-initial gap-6 pb-6 items-center justify-center">
        <p className="text-4xl font-bold pt-12 modal-text modal-content">
          {t('loyaltyLoginFailedModalContent')}
        </p>
        <p className="text-4xl p-0 modal-text modal-content">
          {t('loyaltyLoginFailedModalSubContent')}
        </p>
      </div>

      <PrimaryButtonLarge
        className="w-1/2 mx-auto mb-12"
        data-test="button-primary"
        onClick={onClose}
      >
        {t('loyaltyLoginFailedModalClose')}
      </PrimaryButtonLarge>
    </div>
  );
};
