import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { useLoadableImage } from './hook';

import defaultImage from './images/default-image.png';

const ProductCardLoadableImage = ({
  src = null,
  className = '',
  customDefaultImage = defaultImage,
  'data-test': dataTest = 'product-card-image',
  ...props
}) => {
  const imageSrc = src || customDefaultImage;

  const { hiddenImageClassName, onLoad, isLoading, handleFailure } = useLoadableImage({
    customDefaultImage,
  });

  return (
    <>
      {isLoading && (
        <Skeleton className="mb-4 h-card-image w-card-image" data-test="skeleton-loader" />
      )}

      <img
        {...props}
        data-test={dataTest}
        key={imageSrc}
        src={imageSrc}
        onLoad={onLoad}
        onError={handleFailure}
        className={`object-contain ${hiddenImageClassName} ${className}`}
      />
    </>
  );
};

export { ProductCardLoadableImage };
