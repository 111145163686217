import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  addedUpsellState,
  couponNumberState,
  formattedSettingsState,
  orderTotalState,
} from '@fingermarkglobal/atoms';
import Ban from 'heroicons/solid/ban.svg';
import { formatEnergyInfo } from '../../../utilities/cart/formatters';
import { useAnalytics } from '@fingermarkglobal/jitsu';
import { useOffersAndRewardsMenu } from '../../../hooks/restaurant/menu';
import { SecondaryButton } from '../../stateless/secondary-button';
import { QuantityControllerButtons } from '../../stateless/quantity-controller-buttons';

const OrderHistoryItemUnitPrice = ({ promoItem, unitPrice, t, formatPrice }) => {
  const formattedPrice = t('cartProductUnitPriceEach', {
    price: formatPrice({ price: unitPrice }),
  });
  if (promoItem) {
    if (unitPrice) {
      return formattedPrice;
    }
    return null;
  }
  return formattedPrice;
};

const UnmappedOrderItemCard = ({
  item = null,
  removeText = 'Remove',
  formatPrice = () => {},
  t = () => {},
  showEnergyInformation = false,
  showAddonAvailability = false,
  disableModifyUpsellOnCart = false,
  RewardPointsComponent = null,
  hideNoPrefixWhenDefaultNotSelected = false,
} = {}) => {
  const { productRemovedTrack } = useAnalytics();

  const { getPromoItem } = useOffersAndRewardsMenu();
  const promoItem = getPromoItem({ productId: product?.productId });

  const orderTotal = useRecoilValue(orderTotalState);
  const couponNumber = useRecoilValue(couponNumberState);
  const kioskSettings = useRecoilValue(formattedSettingsState);
  const addedUpsells = useRecoilValue(addedUpsellState);

  const { price, quantity, product, removeAll } = item;

  const { name, count = {}, energyInformation = '', isBag = false } = product;

  const shouldRenderButtonsBag = true;

  return (
    <div
      data-test="cart-product"
      className={`cart-product flex flex-col justify-between rounded shadow-lg p-2 md:p-4 w-full bg-red-100`}
    >
      <div className="flex items-center justify-center space-x-4 text-4xl text-center text-red-600">
        <Ban className="w-auto h-12"></Ban>
        <span data-test="cart-product-unavailable-text">{t('cartProductUnavailableGeneral')}</span>
      </div>

      <div className="flex flex-row w-full p-2 md:p-4">
        <div className="flex items-center w-1/2 my-2 mr-2">
          <div
            className="text-2xl font-bold md:text-4xl line-through"
            data-test="cart-product-name"
          >
            {name}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-1/2 my-2 sm:flex-row">
          {shouldRenderButtonsBag && (
            <QuantityControllerButtons
              product={{ ...product, count: { current: quantity }, isAvailable: false }}
              className="quantity-controller-cart"
              disableModifyUpsellOnCart={disableModifyUpsellOnCart}
            />
          )}
          <div className="flex flex-col items-end ml-0 md:ml-auto line-through">
            {promoItem?.pointsCost ? (
              <RewardPointsComponent pointsCost={promoItem?.pointsCost} />
            ) : (
              <div className="text-2xl font-bold md:text-4xl" data-test="cart-product-total">
                {formatPrice({ price: price })}
              </div>
            )}
            {showEnergyInformation && energyInformation && (
              <div className="text-2xl font-bold" data-test="cart-product-energy-information">
                {formatEnergyInfo(energyInformation, quantity)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col justify-between p-2 md:p-4 pt-0
          ${showAddonAvailability ? 'sm:flex-col' : 'sm:flex-row'}
        `}
      >
        <div className="flex flex-col w-full">
          <div
            className="pb-2 text-2xl md:pb-4 md:text-3xl"
            data-test="cart-product-unit-price"
          ></div>
        </div>
        <div
          className={`flex custom-modal-actions items-modal-actions space-y-modal-actions-none space-x-modal-actions max-h-modal-actions
            ${
              showAddonAvailability
                ? 'flex-row justify-center items-center space-x-4 pb-4 py-8'
                : 'flex-col items-stretch space-y-4'
            }
          `}
        >
          {shouldRenderButtonsBag && (
            <SecondaryButton
              className="remove-button py-small-secondary-button footer-action-button"
              data-test="cart-product-remove-button"
              disabled={isBag}
              onClick={() => {
                removeAll();
                productRemovedTrack(
                  product,
                  count.current,
                  kioskSettings.kiosk,
                  orderTotal,
                  couponNumber,
                );
              }}
            >
              {removeText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { UnmappedOrderItemCard };
