import SHA1 from 'crypto-js/sha1';
import { formatAmountInCents } from '@fingermarkglobal/utilities';
import { transactionType } from '../utils';

const buildRefund = ({ amount, config } = {}) => {
  const amountInCents = formatAmountInCents({ amount });

  const request = {
    transType: transactionType.REFUND,
    amount: amountInCents,
    needPassword: false,
  };

  const body = {
    SHA1: SHA1(JSON.stringify(request)).toString(),
    request,
  };

  return { config, body };
};

export { buildRefund };
