import { TransactionStatus } from '../../../types';

const getStatusString = event => {
  const status = event?.data?.status;
  return typeof status === 'string' ? status.toLowerCase() : null;
};

export const hasToInquiry = (_, event) => {
  const status = getStatusString(event);
  return status === TransactionStatus.inquiry;
};

export const hasSuccessStatus = (_, event) => {
  const status = getStatusString(event);
  return status === TransactionStatus.success;
};

export const hasFailureStatus = (_, event) => {
  const status = getStatusString(event);
  return status === TransactionStatus.failure;
};

export const hasTimeoutStatus = (_, event) => {
  return event?.data?.status === 408;
};

export const hasCancelSuccess = (context, event) => {
  return hasSuccessStatus(context, event);
};

export const hasToRetry = (context, event) => {
  if (hasToInquiry(context, event) || (event?.data?.status >= 400 && !!event?.data?.error)) {
    logger.info(`[Elavon] Inquiry attempt: ${context?.inquiryRetryCount}`);
    return context?.inquiryRetryCount < 3;
  }
  return false;
};
export const hasCancelRetry = (context, event) => {
  logger.info(`[Elavon] Cancel attempt: ${context?.cancelRetryCount}`);
  return context?.cancelRetryCount < 3;
};
