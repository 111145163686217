import { EventObject } from 'xstate';
import { IdempotencyKey } from '../class';

export enum TransactionStatus {
  success = 'success',
  cancel = 'cancel',
  inquiry = 'inquiry',
  failure = 'failure',
}

export enum FoodDescription {
  food = 'FOOD',
  beverage = 'BEVERAGE',
  foodAndBev = 'FOOD/BEV',
}

export type Transaction = {
  id: string;
  amountInCents: number;
  status?: TransactionStatus;
  paymentReceipt?: string;
  cardNumber?: string;
  rawResponse?: string;
  cardType?: string;
  authCode?: string;
  result?: string;
};

export interface PaymentEvent extends EventObject {
  type: 'INITIALISE' | 'RETRY' | 'CANCEL';
  amount: number;
  config: any;
  idempotencyKey?: string;
  data: Transaction;
}

export type AuthTokenResponse = {
  accessToken: string;
  token_type: string;
  expires_in: number;
  jti: string;
};

export interface PaymentContext {
  inquiryRetryCount: number;
  cancelRetryCount: number;
  amount: number;
  config: PaymentConfig;
  id?: string;
  amountInCents?: number;
  status?: TransactionStatus;
  paymentReceipt?: string;
  cardNumber?: string;
  rawResponse?: string;
  cardType?: string;
  authCode?: string;
  result?: string;
  referenceNumber: string;
  idempotencyKey: IdempotencyKey;
}

export type HeadersT = {
  'Accept-Version': string;
  'Correlation-Id'?: string;
  Authorization: string;
  'Cache-Control'?: string;
  'Content-Type': string;
};

export type Tax = {
  taxIndicator: string;
  taxType: string;
  taxAmount: string;
};

export type Identifiers = {
  chain: string;
  location: string;
  terminal: string;
};

export type Compliance = {
  uniqueLaneId?: string;
  customerCode?: string;
  uniqueDeviceId?: string;
};

export interface ResponseCodeResult {
  status: TransactionStatus;
  friendlymessage: string;
}

export type Currency = {
  merchantCurrencyCode: string;
};

export type Item = {
  description: string;
  code: string;
  quantity: string;
  unitPrice: string;
};

export type Hsa = {
  accountType: string;
  amountType: string;
  currencyType: string;
  signAmount: string;
  expenseAmount: string;
};

export type IndustryData = {
  itemList?: Item[];
  hsaList?: Hsa[];
  chargeDescription: string;
  checkNumber: string;
  foodDescription: string;
  employeeNumber1: number;
  foodAmount: string;
  beverageAmount: string;
  tip1?: string;
};

export type Industry = {
  data?: IndustryData;
};

export type Message = {
  referenceNumber: string;
  transType: string;
  transAmount: string;
  taxList?: Tax[];
  transactionDateTime: string;
  identifiers: Identifiers;
  partialAuthFlag?: string;
  cashierId?: string;
  compliance?: Compliance;
  currency?: Currency;
  industry?: Industry;
};

export type ResponseChannel = {
  responsePayloadFormat: string;
};

export type SaleMessage = {
  responseChannelType: string;
  responseChannel: ResponseChannel;
  messageId: string;
  messageType: string;
  message: Message;
};

export interface RequestBuilderInterface {
  method: string;
  url: string;
  setHeaders(params: { config: PaymentConfig; idempotencyKey: string }): void;
  setBody(params: RequestBuilderBodyParams): void;
  getRequest(): any;
  getBody(): any;
}

export enum TransType {
  Sale = 'Sale',
  Cancel = 'Cancel',
  Inquiry = 'Inquiry',
  HealthCheck = 'HealthCheck',
  AUTH = 'AUTH',
}

type RequestBuilderBodyParams = {
  transType: TransType;
};

export type PaymentConfig = {
  order: {
    orderId: number | string;
  };
  settings: {
    integratorId: string;
    storeId: string;
    siteId: string;
    timeout: number;
    messageType: string;
    responsePayloadFormat: string;
    partialAuthFlag: string;
    responseChannelType: string;
    inquiryRetriesAttempts?: string;
    merchantCurrencyCode: string;
    versionAPI: string;
    url: string;
    isLocal?: boolean;
    pairingResponse: PairingResponse;
    taxIndicator?: string;
    taxType?: string;
  };
  sessionId: string;
  port: any;
};

export type InquiryMessage = {
  responseChannelType: string;
  messageType: string;
  messageId: string;
  responseChannel: {
    responsePayloadFormat: string;
  };
  message: Message;
};

export interface CancelMessage {
  responseChannelType: string;
  responseChannel: {
    responsePayloadFormat: string;
  };
  messageId: string;
  messageType: string;
  message: {
    identifiers: {
      chain: string;
      location: string;
      terminal: string;
      deviceId: string;
      integratorId: string;
    };
    referenceNumber: string;
    transType: string;
  };
  transactionDateTime: string;
  cashierId: string;
}

export type RequestT = {
  url: string;
  method: string;
  headers: any;
  data: any;
};

export type MetaDataItem = {
  key: string;
  value: string;
};

export type DeviceIdentifiers = {
  deviceId: string;
  deviceFriendlyName: string;
  location: string;
  serialNumber: string;
};

export type PairingResponse = {
  deviceIdentifiers: DeviceIdentifiers;
  deviceManufacturer: string;
  deviceType: string;
  pairedStatus: string;
  metaData: MetaDataItem[];
  createdDateTime: string;
  integratorId: string;
};
