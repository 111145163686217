import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { sessionState, settingsState } from '@fingermarkglobal/atoms';

import { usePrice } from '../../../../hooks/generic/utilities/price';
import { useTiming } from '../../../../hooks/generic/timing';
import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';

const useTimeoutModal = ({ onTimeout = null } = {}) => {
  const history = useHistory();

  const { t } = useLocaleSelection();
  const { formatPrice } = usePrice();

  const [showWarning, setShowWarning] = useState(false);

  const settingsObject = useRecoilValue(settingsState);

  const { settings = {} } = settingsObject || {};
  const { warning: warningTime = 10 } = settings?.interval?.timeout || {};

  const updatedSession = useRecoilValue(sessionState);
  const setSession = useSetRecoilState(sessionState);

  const cancelReason = 'CANCELLED_BY_CART_TIMEOUT';

  const idleTimeByRoute = useMemo(() => {
    const { pathname } = history.location;
    const { receipt = 30, session = 30, order = 30 } = settings?.interval?.timeout || {};

    const orderPaths = ['/cart', '/payment'];
    const sessionPaths = ['/categories', '/category', '/product'];
    const receiptPaths = ['/receipt'];

    if (orderPaths.some(path => pathname.includes(path))) return order;
    if (sessionPaths.some(path => pathname.includes(path))) return session;
    if (receiptPaths.some(path => pathname.includes(path))) return receipt;

    return session;
  }, [settings, history.location]);

  const { isIdle, timeLeft, startTimer, resetTimer, timerExpired } = useTiming({
    idleTime: idleTimeByRoute,
    warningTime,
  });

  const reset = () => {
    resetTimer();
    setShowWarning(false);
  };

  // useEffect for idle timing
  useEffect(() => {
    // cant just set to `isIdle` value as un-idling will close modal
    // - might be the inteded functionality later anyways?
    if (isIdle) setShowWarning(true);
  }, [isIdle]);

  // useEffect for warning timing
  useEffect(() => {
    if (!showWarning) return;

    startTimer();
  }, [showWarning, startTimer]);

  useEffect(() => {
    const { pathname } = history.location;
    const receiptPaths = ['/receipt'];

    if (!timerExpired) return;

    if (onTimeout) {
      onTimeout();
      return;
    }
    if (!receiptPaths.some(path => pathname.includes(path))) {
      setSession({
        ...updatedSession,
        cancelReason,
      });

      if (!!updatedSession?.cancelReason) {
        history.push('/reset');
      }
    } else {
      history.push('/reset');
    }
  }, [timerExpired, onTimeout, history, updatedSession, setSession, history.location]);

  return {
    t,
    reset,
    timeLeft,
    showWarning,
    formatPrice,
  };
};

export { useTimeoutModal };
