import {
  retrieveSerialFromCache,
  retrieveSerialFromQueryString
} from '@fingermarkglobal/platforms.web';
import { pingChrome } from '@fingermarkglobal/platforms.chrome';

interface SerialConfig {
  port?: number;
  origin: string;
  serial: string;
}

// Change this to invoke
const chromeSerialResolve = async (): Promise<SerialConfig> => {
  try {
    const { port, serialKey: serial } = await pingChrome();
    return {
      origin: 'chrome',
      port,
      serial
    };
  } catch (err) {
    logger.warning('serial-resolver - chromeSerialResolve - error:', err);
    throw err;
  }
};

const querySerialResolve = async (): Promise<SerialConfig> => {
  try {
    const serial = retrieveSerialFromQueryString();
    if (!serial) throw new Error('No valid serial found in query string!');
    return {
      origin: 'local',
      serial
    };
  } catch (err) {
    logger.warning('serial-resolver - querySerialResolve - error:', err.message);
    throw err;
  }
};

// Change this to invoke
const cacheSerialResolve = async (): Promise<SerialConfig> => {
  try {
    const serial = await retrieveSerialFromCache();
    if (!serial) throw new Error('No valid serial found in cache!');
    return {
      origin: 'local',
      serial
    };
  } catch (err) {
    logger.warning('serial-resolver - cacheSerialResolve - error:', err.message);
    throw err;
  }
};

export { chromeSerialResolve, querySerialResolve, cacheSerialResolve };
