/* eslint-disable max-lines */
// Routes is for the applications workflow definitions.
import React from 'react';

import { Loading } from '@fingermarkglobal/kfc-styles';

import { RouteBuilder, base } from '@fingermarkglobal/router';

// Custom views
import { CustomHomeView } from './src/views/home';
import { CustomBeaconView } from './src/views/beacon';
import { CustomReceiptView } from './src/views/receipt';
import { CustomRestaurantCategoryView } from './src/views/category';

// Custom templates
import { CustomDefaultTemplate } from './src/templates/default';

// Custom components
import { Warning } from './src/components/stateless/warning';

import Logo from './src/assets/images/logo.svg';

import { version } from './package.json';
import mockSettings from './mock-settings.json';
import { LoginWelcomeModal } from './src/components/stateless/generic/loyalty-login/LoginWelcomeModal';
import { LoginFailedModal } from './src/components/stateless/generic/loyalty-login/LoginFailedModal';
import { NotAMemberSection } from './src/components/stateless/generic/loyalty-login/NotAMemberSection';

const LogoComponent = () => <Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />;

const config = {
  ...base,
  auth: {
    ...base.auth,
    config: {
      ...base.auth.config,
      template: {
        ...base.auth.config.template,
        timeoutEnabled: true,
      },
    },
  },
  home: {
    ...base.home,
    View: CustomHomeView,
    config: {
      ...base.home.config,
      provider: {
        allowedLanguages: ['en'],
      },
    },
  },
  beacon: {
    ...base.beacon,
    View: CustomBeaconView,
    config: {
      ...base.beacon.config,
      provider: {
        primaryPath: '/categories',
        secondaryPath: '/reset',
      },
      template: {
        headerEnabled: false,
      },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
      provider: {
        limitUpsellModalToOnce: false,
        randomiseUpsells: false,
      },
    },
  },
  category: {
    ...base.category,
    View: CustomRestaurantCategoryView,
    config: {
      ...base.category.config,
      provider: {
        displayDescription: false,
        isCustomiseCategoryTitleEnabled: true,
        placeholder: { width: 56, height: 112 },
        limitUpsellModalToOnce: false,
        randomiseUpsells: false,
        showCategoriesHeader: true,
      },
    },
  },
  customer: {
    ...base.customer,
    config: {
      ...base.customer.config,
      template: {
        ...base.customer.config.template,
        footerEnabled: true,
      },
      provider: {
        displayRequiredLabel: false,
        showPrimaryAction: true,
        showEmail: false,
        isFirstNameRequired: true,
        isLastNameRequired: true,
        showNumbers: false,
      },
    },
  },
  cart: {
    ...base.cart,
    config: {
      ...base.cart.config,
      provider: {
        crossSellEnabled: true,
        showCrossSellCategoryTitle: false,
        limitUpsellModalToOnce: false,
        randomiseUpsells: false,
        showCategoriesHeader: true,
        hideNoPrefixWhenDefaultNotSelected: false,
      },
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
    },
  },
  validate: {
    ...base.validate,
    config: {
      ...base.validate.config,
      provider: {
        image: LogoComponent,
        isLogoEnabled: true,
        showFinancialSummaryTotal: true,
      },
    },
  },
  confirmation: {
    ...base.confirmation,
    config: {
      ...base.confirmation.config,
      provider: { image: LogoComponent, isLogoEnabled: true },
    },
  },
  receipt: {
    ...base.receipt,
    View: CustomReceiptView,
  },
  composed: {
    ...base.composed,
    config: {
      ...base.composed.config,
      provider: {
        showCategoriesHeader: true,
      },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      template: {
        ...base.lock.config.template,
        timeoutEnabled: false,
      },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
      provider: {
        ...base.loyalty.config.provider,
        qrLoginEnabled: true,
      },
      props: {
        CustomLoginFailedModal: LoginFailedModal,
        CustomLoginWelcomeModal: LoginWelcomeModal,
        CustomNotAMemberSection: NotAMemberSection,
        CustomClassName: 'kfc-au-keyboard-theme',
      },
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
  appDownload: {
    ...base.appDownload,
    config: {
      ...base.appDownload.config,
      provider: {
        ...base.appDownload.config.provider,
        appDownloadUrl: 'https://www.kfc.com.au/kfc-app',
      },
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    config={config}
    root={{
      ErrorComponent: Warning,
      overwriteSettings: mockSettings,
      resolverProps: {
        Loading,
        isRetryActive: true,
      },
    }}
  />
);

export { Routes };
