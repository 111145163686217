import React, { useCallback } from 'react';
import { validate } from '@fingermarkglobal/validation';
import { SecondaryButtonLarge } from '../secondary-button';
import { DefaultTemplate as GenericTemplate } from '../../../templates/default';
import { useLocaleSelection } from '../../../hooks/generic/locale-selection';

const Warning = ({ error = null, resetErrorBoundary = null, Template = GenericTemplate } = {}) => {
  validate({ name: `'Warning' component`, parameters: { error, resetErrorBoundary } });

  const { t } = useLocaleSelection();

  logger.error(`cringer.components - warning:`, error);

  const handleReset = useCallback(() => {
    resetErrorBoundary();
  }, [resetErrorBoundary]);

  return (
    <Template headerEnabled={false} footerEnabled={false} timeoutEnabled={false}>
      <div data-test="warning" className="flex flex-col items-center justify-center w-full h-full">
        <p data-test="warning-text" className="mb-8 text-3xl font-bold text-center md:text-5xl">
          {t('warningTitle')}
        </p>
        <SecondaryButtonLarge
          data-test="warning-button"
          className="home-primary-button"
          onClick={handleReset}
        >
          {t('warningCancelAction')}
        </SecondaryButtonLarge>
      </div>
    </Template>
  );
};

export { Warning };
