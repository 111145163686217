import { assign } from 'xstate';
import merge from 'lodash.merge';
import { PaymentContext, PaymentEvent } from '../../../types';
import { IdempotencyKey } from '../../../class';
import { generateUniqueReferenceNumber } from '../../../utils';

export const startStateMachineContext = assign<PaymentContext, PaymentEvent>((context, event) => {
  const updatedContext = merge({}, context, {
    amount: event?.amount,
    config: merge({}, context.config, event.config),
    idempotencyKey: new IdempotencyKey(),
    referenceNumber: generateUniqueReferenceNumber(),
  });
  logger.debug(
    `[@fingermarkglobal/elavon] - Starting Context - amount: ${updatedContext.amount}, config:${
      updatedContext.config
    }, idempotencyKey: ${updatedContext.idempotencyKey.get()} - startStateMachineContext()`,
  );
  return updatedContext;
});
