import React from 'react';
import { PrimaryButtonLarge } from '@fingermarkglobal/cringer.components';
import { CustomModalHeader } from './CustomModalHeader';

export const LoginWelcomeModal = ({ onConfirm, loyaltyUser, t }) => {
  const { name } = loyaltyUser || {};

  return (
    <div className="flex flex-col justify-center items-center">
      <CustomModalHeader title={t('loyaltyWelcomeModalTitle', { name })} />

      <PrimaryButtonLarge
        className="w-1/2 mx-auto my-12"
        data-test="button-primary"
        onClick={onConfirm}
      >
        {t('loyaltyWelcomeModalConfirm')}
      </PrimaryButtonLarge>
    </div>
  );
};
