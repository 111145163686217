import React from 'react';
import { PrimaryButtonLarge } from '../primary-button';
import { SecondaryButtonLarge } from '../secondary-button';

const Alert = ({
  'data-test': dataTest = 'alert',
  children = null,
  type = 'information',
  message = null,
  additionalMessage = null,
  detail = null,
  onRetryAction = null,
  onRetryText = 'Retry',
  onCancelAction = null,
  onCancelText = 'Cancel',
  className = '',
} = {}) => {
  const Message = () => {
    switch (type) {
      case 'danger':
        return (
          <div className="space-y-4 text-center text-alert">
            <div className="text-3xl sm:text-6xl sm:leading-none font-bold">{message}</div>
            {additionalMessage && (
              <div className="text-2xl sm:text-4xl font-bold my-4 md:mt-8  md:mb-8">
                {additionalMessage}
              </div>
            )}
            {detail && <div className="text-2xl sm:text-3xl text-alert">{detail}</div>}
          </div>
        );
      default:
        return (
          <div>
            <div className="text-3xl sm:text-6xl sm:leading-none font-bold text-center">
              {message}
            </div>
            {additionalMessage && (
              <div className="text-2xl sm:text-4xl font-bold my-4 md:mt-8  md:mb-8">
                {additionalMessage}
              </div>
            )}
            {detail && <div className="text-2xl sm:text-3xl">{detail}</div>}
          </div>
        );
    }
  };

  return (
    <div
      data-test={dataTest}
      className={`flex flex-col justify-center items-center space-y-8 ${className}`}
    >
      <Message />
      {(onRetryAction || onCancelAction || children) && (
        <div
          data-test="alert-actions"
          className="flex flex-col justify-center items-stretch space-y-4 alert-actions"
        >
          {onRetryAction && (
            <PrimaryButtonLarge className="home-primary-button" onClick={onRetryAction}>
              {onRetryText}
            </PrimaryButtonLarge>
          )}
          {onCancelAction && (
            <SecondaryButtonLarge className="home-primary-button" onClick={onCancelAction}>
              {onCancelText}
            </SecondaryButtonLarge>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export { Alert };
