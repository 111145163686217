import React from 'react';
import { CancelOrderButton } from '../../../../stateful/cancel-order-button';
import { AccessibilityButton } from '../../../../stateful/accessibility';
import { PrimaryButtonLarge, PrimaryButton } from '../../../primary-button';
import { SecondaryButtonLarge } from '../../../secondary-button';
import { BasketCount as GenericBasketCount } from '../../../../stateless/basket-count';

const FooterWrapper = ({
  nextText = 'Next',
  nextAction = null,
  isNextActionOnBottom = false,
  isBasketCountOnBottom = false,
  isQuantityControllerOnBottom = false,
  allowNextAction = true,
  nextActionClass = '',
  className = '',
  spacer = null,
  count = 0,
  enableCancelOrder = false,
  enableCustomCancelOrder = false,
  cancelOrderText = 'Cancel',
  showAccessibilityButton = false,
  showBasketCount = true,
  onBasketClick = () => {},
  total = null,
  OverwriteCartIcon = null,
  CustomSecondaryButton = SecondaryButtonLarge,
  CustomNextActionButton = PrimaryButtonLarge,
  CustomPreviousActionButton = PrimaryButtonLarge,
  BasketCount = GenericBasketCount,
  couponText = 'Enter offer',
  couponAction = () => {},
  isPreviousActionEnabled = false,
  allowPreviousAction = true,
  previousText = 'Back',
  previousAction = null,
  isAnimationTargetEnabled = false,
  shouldShowCoupon = false,
  isFooterStyleVariationEnabled = false,
  hasUnavailableItems = false,
  isDefaultButtonPaddingEnabled = true,
  termsAndConditionsButtonText = '',
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
  skipAction = null,
  t = () => {},
} = {}) => {
  const footerStyleVariation =
    isFooterStyleVariationEnabled &&
    `
    ${!!spacer || shouldShowCoupon ? 'is-footer-with-coupon' : 'is-not-footer-with-coupon'}`;
  const largeButtons = isDefaultButtonPaddingEnabled ? `md:h-24 md:px-12` : `md:h-24`;

  return (
    <div
      data-test="footer"
      className={`flex justify-center md:justify-between items-center p-2 md:px-8 md:py-8 bg-accessory md:space-x-8 footer bg-bottom footer-main-div ${footerStyleVariation} ${className}`}
    >
      {/* we are creating this component as invisible just to make the cart animation work, as the animation needs the the cart-icon id */}
      {isAnimationTargetEnabled && (
        <div id="cart-icon" className="absolute left-0 right-0 invisible w-24 ml-auto mr-auto" />
      )}
      {showAccessibilityButton && (
        <AccessibilityButton className="hidden w-24 h-24 p-2 shadow-lg md:block" />
      )}
      <div
        data-test="footer-inputs"
        className="flex items-center justify-end md:h-24 footer-inputs "
      >
        {previousAction && isPreviousActionEnabled && (
          <CustomPreviousActionButton
            data-test="previous-action-button"
            disabled={!allowPreviousAction}
            onClick={previousAction}
            className={`${largeButtons} previous-action-button footer-action-button `}
          >
            {previousText}
          </CustomPreviousActionButton>
        )}

        {(enableCancelOrder || enableCustomCancelOrder) && (
          <CancelOrderButton
            data-test="cancel-action-button"
            className={`${largeButtons} cancel-action-button footer-action-button ${previousAction &&
              isPreviousActionEnabled &&
              'hidden'}`}
            Button={CustomSecondaryButton}
          >
            {cancelOrderText}
          </CancelOrderButton>
        )}

        {shouldShowCoupon && (
          <CustomSecondaryButton
            data-test="coupon-action-button"
            className={`ml-4 font-bold text-3xl h-full coupon-action-button footer-action-button  ${largeButtons}`}
            onClick={couponAction}
          >
            {couponText}
          </CustomSecondaryButton>
        )}
        {showTermsAndConditionsButton && (
          <CustomNextActionButton
            className={`ml-4 next-action-button ${nextActionClass} footer-action-button ${largeButtons}`}
            onClick={() => setShowTermsAndConditionsModal(true)}
            data-test="terms-and-conditions-button"
          >
            {termsAndConditionsButtonText}
          </CustomNextActionButton>
        )}
        {skipAction && (
          <CustomSecondaryButton
            onClick={skipAction}
            className={`ml-4 next-action-button ${nextActionClass} footer-action-button ${largeButtons}`}
            data-test="skip-action-button"
          >
            {t('customerDetailsFormSecondaryAction')}
          </CustomSecondaryButton>
        )}
        {!isQuantityControllerOnBottom && spacer}
        {!!nextAction && !isNextActionOnBottom && (
          <CustomNextActionButton
            className={`ml-4 next-action-button ${nextActionClass} footer-action-button ${largeButtons}`}
            disabled={!allowNextAction}
            onClick={nextAction}
            data-test="next-action-button"
          >
            {nextText}
          </CustomNextActionButton>
        )}
        {showBasketCount && !isBasketCountOnBottom && !hasUnavailableItems && (
          <div className="flex footer-basket-wrapper">
            <BasketCount
              className="md:ml-8"
              count={count}
              onClick={onBasketClick}
              OverwriteIcon={OverwriteCartIcon}
            />
            <div
              data-test="footer-cart-subtotal"
              className="self-center text-xs font-bold xs:text-base md:text-4xl md:ml-8 footer-total"
            >
              {total}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { FooterWrapper };
