import { atom, DefaultValue, selector } from 'recoil';
import { trim } from '../utilities/trim';
import { destruct } from '../utilities/destruct';

import { evaluate } from '../utilities/evaluate';
import { stack } from '../utilities/stack';

const cartStateAtom = atom({
  key: 'cartStateAtom',
  default: [],
});

const secondaryCartStateAtom = atom({
  key: 'secondaryCartStateAtom',
  default: [],
});

const reorderCartStateAtom = atom({
  key: 'reorderCartStateAtom',
  default: [],
});

const formattedCartState = selector({
  key: 'formattedCartState',
  get: ({ get }) => {
    const cart = get(cartStateAtom);

    const destructered = cart.map(destruct);
    const formatted = destructered.map(trim);

    logger.debug('cart - formattedCartState - formatted cart:', {
      cart: formatted,
    });
    return formatted;
  },
});

const reorderCartStateSelector = selector({
  key: 'reorderCartStateSelector',
  get: ({ get }) => get(reorderCartStateAtom),
  set: ({ set }, value) => {
    if (value === null) {
      set(reorderCartStateAtom, null);
      return;
    }
    formatCart({ value, set, atom: reorderCartStateAtom, key: 'reorderCartStateSelector' });
  },
});

const secondaryCartStateSelector = selector({
  key: 'secondaryCartStateSelector',
  get: ({ get }) => get(secondaryCartStateAtom),
  set: ({ set }, value) => {
    formatCart({ value, set, atom: secondaryCartStateAtom, key: 'secondaryCartStateSelector' });
  },
});

// We're using `selector` rather than `effects_UNSTABLE` to evaluate
// the cart states because of performance issues. See: https://fingermarkglobal.atlassian.net/browse/S2-2393
const cartStateSelector = selector({
  key: 'cartStateSelector',
  get: ({ get }) => get(cartStateAtom),
  set: ({ set }, value) => {
    formatCart({ value, set, atom: cartStateAtom, key: 'cartStateSelector' });
  },
});

const formatCart = ({ value, set, atom, key }) => {
  const safeValue = value instanceof DefaultValue ? [] : value;

  const cart = safeValue
    .filter(product => product.count.current > 0)
    .map(product => evaluate({ product }))
    .reduce(stack, []);

  // Check if cart has a single item with isBag === true. If so, remove it.
  if (cart?.length === 1 && cart[0]?.isBag === true) {
    console.debug(`cart - ${key} - Clearing bag from cart`);
    set(atom, []);
  } else {
    // NOTE: This is intentionally a `console` and not a `logger`
    // - WE NEVER WANT TO SEND THIS TO APEX
    console.debug(`cart - ${key} - cart:`, { cart });
    set(atom, cart);
  }
};

export {
  cartStateSelector as cartState,
  secondaryCartStateSelector as secondaryCartState,
  reorderCartStateSelector as reorderCartState,
  formattedCartState,
};
