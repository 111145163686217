import { useCallback } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useCart } from '@fingermarkglobal/cart';
import { setTransactions as saveTransactions } from '@fingermarkglobal/storage';

import {
  customerState,
  beaconState,
  orderTotalState,
  sessionState,
  transactionsState,
  formattedPaymentState,
} from '@fingermarkglobal/atoms';

const REFUND_PREFIX = 'Refund_';

const useTransactions = () => {
  const { cart } = useCart();

  const [transactions, setTransactions] = useRecoilState(transactionsState);

  const total = useRecoilValue(orderTotalState);
  const beacon = useRecoilValue(beaconState);
  const session = useRecoilValue(sessionState);
  const payment = useRecoilValue(formattedPaymentState);
  const customer = useRecoilValue(customerState);

  const removeCurrentSessionFilter = useCallback(
    (transaction, formatted) => transaction?.session?.id !== formatted?.session?.id,
    [],
  );

  const updateTransaction = useCallback(
    ({ transactionToUpdate }) => {
      try {
        const formattedTransaction = transactionToUpdate;

        setTransactions(transactions => {
          const formattedTransactions = [
            ...transactions.filter(transaction =>
              removeCurrentSessionFilter(transaction, formattedTransaction),
            ),
            formattedTransaction,
          ];
          saveTransactions('restaurant/transactions', formattedTransactions);
          return formattedTransactions;
        });
      } catch (err) {
        logger.error(
          'cringer.components - updateTransaction - error to update a transaction into the local storage:',
          err,
        );
      }
    },
    [setTransactions, removeCurrentSessionFilter],
  );

  const addTransaction = useCallback(
    ({
      status,
      message,
      receiptId,
      orderStatus,
      useExistingCart = false,
      currentTransaction = {},
      refundInformation = {},
    }) => {
      const {
        cart: existingCart,
        message: existingMessage,
        session: existingSession,
        total: existingTotal,
      } = currentTransaction;

      const formattedCart = useExistingCart
        ? JSON.parse(JSON.stringify(existingCart))
        : JSON.parse(JSON.stringify(cart));

      try {
        const formattedTransaction = {
          status,
          message: '',
          session: {},
          payment: {},
          cart: {},
          total: '',
          receiptId: '',
        };

        if (useExistingCart) {
          formattedTransaction.message = existingMessage;
          formattedTransaction.session = {
            ...existingSession,
            id: `${REFUND_PREFIX}${existingSession.id}`,
            customer,
            beaconNumber: !!beacon ? parseInt(beacon) : null,
            status: orderStatus || session?.status,
          };
          formattedTransaction.payment = refundInformation;
          formattedTransaction.cart = formattedCart;
          formattedTransaction.total = existingTotal;
          formattedTransaction.receiptId = receiptId;
        } else {
          formattedTransaction.message = message;
          formattedTransaction.session = {
            ...session,
            customer,
            beaconNumber: !!beacon ? parseInt(beacon) : null,
            status: orderStatus || session?.status,
          };
          formattedTransaction.payment = payment;
          formattedTransaction.cart = formattedCart;
          formattedTransaction.total = total;
          formattedTransaction.receiptId = receiptId;
        }

        setTransactions(transactions => {
          const formattedTransactions = [
            ...transactions.filter(transaction =>
              removeCurrentSessionFilter(transaction, formattedTransaction),
            ),
            formattedTransaction,
          ];

          saveTransactions('restaurant/transactions', formattedTransactions);
          return formattedTransactions;
        });
      } catch (err) {
        logger.error(
          'cringer.components - addTransaction - error to add a transaction into the local storage:',
          err,
        );
      }
    },
    [cart, payment, session, total, setTransactions, customer, beacon, removeCurrentSessionFilter],
  );

  return {
    addTransaction,
    transactions,
    updateTransaction,
  };
};

export { useTransactions };
