import {
  lock as localLock,
  unlock as localUnlock,
  reboot as localReboot,
  screenshot as localScreenshot,
  reload as localReload,
  syncMenu as localSyncMenu,
} from './utilities/local-handlers';

import {
  lock as chromeLock,
  unlock as chromeUnlock,
  reboot as chromeReboot,
  screenshot as chromeScreenshot,
  reload as chromeReload,
  syncMenu as chromeSyncMenu,
} from './utilities/chrome-handlers';

import { useHistory } from 'react-router-dom';
import { systemState, lockMessageState, syncMenuState } from '@fingermarkglobal/atoms';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useRootViewContext } from '../../../views/generic/root/provider';

const createHandlers = ({
  type = null,
  port = null,
  history = null,
  setLock = null,
  setLockMessageRecoilState = null,
  isSyncMenuStateCalled = null,
  setIsSyncMenuStateCalled = null,
} = {}) => {
  switch (type) {
    case 'local':
      return {
        reboot: localReboot(),
        screenshot: localScreenshot(),
        lock: localLock({ history, setLock, setLockMessageRecoilState }),
        unlock: localUnlock({ history, setLock }),
        reload: localReload(),
        syncMenu: localSyncMenu({ isSyncMenuStateCalled, setIsSyncMenuStateCalled }),
      };
    case 'chrome':
      return {
        screenshot: chromeScreenshot(),
        reboot: chromeReboot({ port }),
        lock: chromeLock({ history, setLock, setLockMessageRecoilState }),
        unlock: chromeUnlock({ history, setLock }),
        reload: chromeReload(),
        syncMenu: chromeSyncMenu({ isSyncMenuStateCalled, setIsSyncMenuStateCalled }),
      };
    default:
      throw new Error(`Invalid 'type' passed to 'createHandlers'`);
  }
};

const useSystem = () => {
  const history = useHistory();
  const { lockMachineState, lockMachineSend: setLock, settings } = useRootViewContext();
  const [lockMessageRecoilState, setLockMessageRecoilState] = useRecoilState(lockMessageState);
  const [isSyncMenuStateCalled, setIsSyncMenuStateCalled] = useRecoilState(syncMenuState);

  const { type, port } = useRecoilValue(systemState);

  // Probably don't need to destructure this much...
  // - Just trying to be explicit...
  const { lock, unlock, reboot, screenshot, reload, syncMenu } = createHandlers({
    type,
    port,
    history,
    setLock,
    setLockMessageRecoilState,
    settings,
    isSyncMenuStateCalled,
    setIsSyncMenuStateCalled,
  });

  return {
    type,
    port,
    lock,
    unlock,
    reboot,
    screenshot,
    lockMachineState,
    lockMessageRecoilState,
    setLockMessageRecoilState,
    reload,
    syncMenu,
  };
};

export { useSystem };
