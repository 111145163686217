import React from 'react';

import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
  Footer,
} from '@fingermarkglobal/cringer.components';

import CartIconImage from '../../assets/images/cart-icon.svg';

import { Decoration } from '../../components/stateless/decoration';
import { Header } from '../../components/stateless/header';

const CustomDefaultTemplate = ({
  children,
  footerEnabled = true,
  headerEnabled = true,
  timeoutEnabled = true,
  templateOverride = false,
  accessibilityActionEnabled = true,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    accessibilityEnabled,
    isTimeoutEnabled,
    timeoutActionOverride,
    isOnline,
    isCouponEnabled,
    hasUnavailableItems,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const showFooterAccessibilityButton = accessibilityActionEnabled && footerEnabled;
  const showStaticAccessibilityButton = accessibilityActionEnabled;

  const isAccessibilityButtonEnabled =
    accessibilityActionEnabled || showFooterAccessibilityButton || showStaticAccessibilityButton;

  return (
    <div className="flex flex-col justify-between h-screen">
      <Decoration showLogo={accessibilityEnabled && isAccessibilityButtonEnabled} />
      <AccessibilityWrapper
        className={`flex flex-col ${!accessibilityEnabled && 'accessibility-static'}`}
        isAccessibilityButtonEnabled={isAccessibilityButtonEnabled}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && (
            <Header
              {...header}
              hasUnavailableItems={hasUnavailableItems}
              isCouponEnabled={isCouponEnabled}
            />
          )}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              showAccessibilityButton={showFooterAccessibilityButton}
              OverwriteCartIcon={CartIconImage}
              hasUnavailableItems={hasUnavailableItems}
            />
          )}

          {showStaticAccessibilityButton && (
            <AccessibilityButton className="absolute bottom-0 left-0 z-40 block w-24 h-24 p-2 my-8 ml-8 shadow-lg" />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
