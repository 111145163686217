import { useRecoilState, useResetRecoilState } from 'recoil';
import { customerState } from '@fingermarkglobal/atoms';

const useCustomer = () => {
  const [customer, setCustomerState] = useRecoilState(customerState);
  const resetCustomer = useResetRecoilState(customerState);
  const setCustomer = ({
    firstName,
    lastName,
    email = null,
    phoneNumber = null,
    receiveMarketingEmail = false,
  }) => {
    setCustomerState({
      firstName,
      lastName,
      email: email ? email.toLowerCase() : process.env.POI_APP_CUSTOMER_EMAIL_DEFAULT,
      phoneNumber,
      receiveMarketingEmail,
    });
  };

  return { customer, setCustomer, resetCustomer };
};

export { useCustomer };
