import React from 'react';

const ModalHeader = ({ title = '', className = '', total = null, formatPrice = () => {} } = {}) => {
  return (
    <div
      data-test="modal-header"
      className={`flex items-start justify-between p-3 md:p-8 border-b border-solid border-gray-300 rounded-t modal-text ${className}`}
    >
      <h3 className="text-2xl md:text-5xl font-bold font-header modal-header">{title}</h3>
      {total && <p className="text-2xl md:text-5xl font-bold">{formatPrice({ price: total })}</p>}
    </div>
  );
};

export { ModalHeader };
