import { FallbackLogger } from '@fingermarkglobal/logger';

const packageJson = require(' ../../../package.json');
const Sentry = require('@sentry/react');

/* global globalThis */
globalThis.logger = globalThis.logger || new FallbackLogger();

const version = packageJson.version;

export const initializeSentry = () => {
  try {
    if (!process.env.POI_APP_SENTRY_ORG || !process.env.POI_APP_SENTRY_PROJECT) {
      logger.error('Required env vars are not defined to initialize Sentry');
      return;
    }

    Sentry.init({
      dsn: `https://${process.env.POI_APP_SENTRY_ORG}.ingest.us.sentry.io/${process.env.POI_APP_SENTRY_PROJECT}`,
      release: version,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampler: ({ name, parentSampled }) => {
        if (name.startsWith('healthcheck')) {
          return 0;
        }

        if (name.startsWith('[auth]')) {
          return 1;
        }

        if (name.startsWith('comment')) {
          return 0.01;
        }

        if (typeof parentSampled === 'boolean') {
          return parentSampled;
        }

        return 0.5;
      },
      tracesSampleRate: parseFloat(
        process.env.POI_APP_SENTRY_ENVIRONMENT === 'development' ? 0.8 : 0.2,
      ),
      environment: process.env.POI_APP_SENTRY_ENVIRONMENT,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    logger.debug('Sentry initialized successfully');
  } catch (error) {
    logger.error('Failed to initialize Sentry:', error.message);
  }
};

export const setUpSentryTags = settings => {
  Sentry.setTag('serial', settings?.serial);
  Sentry.setTag('organisation', settings?.settings?.properties?.organisation);
  Sentry.setTag('storeId', settings?.settings?.properties?.storeId);
};
