import { useLocation } from 'react-router-dom';
import { useMetadata } from '../../../../hooks/generic/metadata';

const useAccessibility = ({ isAccessibilityButtonEnabled = false } = {}) => {
  const location = useLocation();
  const { metadata, setMetadata } = useMetadata();

  const { accessibilityEnabled = false } = metadata || {};

  const isHalf =
    isAccessibilityButtonEnabled !== null
      ? !!(isAccessibilityButtonEnabled && accessibilityEnabled)
      : accessibilityEnabled;
  // This gets exported from here as the accessibility components use this
  // as their 'logic' hook as well
  const wrapperClassName = isHalf ? 'h-half' : 'h-full';
  const allowAccessibilityLogo = isHalf && location.pathname !== '/home';

  const toggleAccessibility = () =>
    setMetadata(metadata => ({
      ...metadata,
      accessibilityEnabled: !accessibilityEnabled,
    }));

  return {
    toggleAccessibility,
    accessibilityEnabled,
    wrapperClassName,
    allowAccessibilityLogo,
  };
};

export { useAccessibility };
