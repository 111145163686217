import { useCallback, useEffect, useState, useRef } from 'react';
import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSettingsAtPath } from '@fingermarkglobal/utilities';
import { doLoyaltyOrderHistory, OrderHistoryMachineStates } from '@fingermarkglobal/fm-api';
import {
  formattedProductsState,
  loyaltyState,
  orderHistoryState,
  formattedOrderState,
  orderHistoryModalState,
  selectedOrderState,
} from '@fingermarkglobal/atoms';
import { mapOrderItems, useReorderCart, reorderCartState } from '@fingermarkglobal/cart';

const useLoyaltyOrderHistory = () => {
  const { t } = useLocaleSelection();
  const loyalty = useRecoilValue(loyaltyState);
  const [orderHistory, setOrderHistory] = useRecoilState(orderHistoryState);
  const [formattedProducts] = useRecoilState(formattedProductsState);
  //The modal order is the formatted state run with a setCart fo it can format it correctly for being added into a modal
  //E.g. grouping the same products
  const [formattedReorderCart, setFormattedReorderCart] = useRecoilState(reorderCartState);
  //The Formatted order is the order ready to be added into the cart
  const [formattedOrder, setFormattedOrder] = useRecoilState(formattedOrderState);
  const [showReorderModal, setShowReorderModal] = useRecoilState(orderHistoryModalState);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderState);

  const [orderHistoryMachineStatus, setOrderHistoryMachineStatus] = useState('');
  const [unmappedOrderItems, setUnmappedOrderItems] = useState(null);
  const [addingOrderToCart, setAddingOrderToCart] = useState(false);
  const [canGetOrder, setCanGetOrder] = useState(false);

  const getLoyaltyFullOrderRef = useRef(() => {});
  const { commitMany, formatProduct } = useReorderCart();

  const loyaltyEnabled = useSettingsAtPath({
    path: 'settings.modes.loyalty',
    defaultValue: false,
  });

  const organisationId = process.env.POI_APP_ORGANISATION_ID;
  const { token } = loyalty || {};
  const isLoggedIn = !!token;

  useEffect(() => {
    if (formattedOrder) {
      setFormattedReorderCart(formattedOrder);
    }
  }, [formattedOrder, setFormattedReorderCart]);

  useEffect(() => {
    if (
      formattedReorderCart &&
      unmappedOrderItems &&
      formattedReorderCart.length + unmappedOrderItems.length === 0
    ) {
      setShowReorderModal(false);
    }
  }, [formattedReorderCart, unmappedOrderItems, setShowReorderModal]);

  const handleOrderHistoryResponse = useCallback(
    machine => {
      const { value, context: { data } = {} } = machine;

      if (value === OrderHistoryMachineStates.FetchingOrderSummaries) {
        return setOrderHistoryMachineStatus(value);
      }
      if (
        value !== OrderHistoryMachineStates.FullOrderSuccess &&
        value !== OrderHistoryMachineStates.OrderSummarySuccess
      ) {
        setFormattedOrder(null);
        setUnmappedOrderItems(null);
        setFormattedReorderCart(null);
        return;
      }

      if (value === OrderHistoryMachineStates.OrderSummarySuccess) {
        setOrderHistory(data);
      } else if (value === OrderHistoryMachineStates.FullOrderSuccess) {
        const orderHistoryItems = data.cart.items;

        const itemIds = orderHistoryItems.map(item => item.id);
        const menuProducts = formattedProducts.filter(product => itemIds.includes(product.id));

        const { mappedItems, unmappedItems } = mapOrderItems(orderHistoryItems, menuProducts);

        const decoratedUnmappedItems = unmappedItems.map(item => ({
          ...item,
          removeAll: function() {
            setUnmappedOrderItems(prevUnmapped =>
              prevUnmapped.filter(unmappedItem => unmappedItem.uid !== item.uid),
            );
          },
        }));

        setUnmappedOrderItems(decoratedUnmappedItems);

        const productCart = [];
        for (const product of mappedItems) {
          productCart.push(formatProduct({ product: product }));
        }

        setFormattedOrder(productCart);
      }
    },
    [
      formattedProducts,
      setOrderHistory,
      setOrderHistoryMachineStatus,
      formatProduct,
      setFormattedReorderCart,
      setFormattedOrder,
    ],
  );

  useEffect(() => {
    if (!loyalty) {
      return;
    }

    const { token, customerId } = loyalty;
    if (
      !token ||
      orderHistoryMachineStatus === OrderHistoryMachineStates.FetchingOrderSummaries ||
      orderHistoryMachineStatus === OrderHistoryMachineStates.FetchingFullOrder ||
      orderHistoryMachineStatus === OrderHistoryMachineStates.OrderSummarySuccess ||
      !customerId
    ) {
      return;
    }

    const result = doLoyaltyOrderHistory({
      token,
      organisationId,
      transition: handleOrderHistoryResponse,
    });
    getLoyaltyFullOrderRef.current = result.getLoyaltyFullOrder;
    setCanGetOrder(true);
  }, [
    loyalty,
    handleOrderHistoryResponse,
    orderHistoryMachineStatus,
    organisationId,
    getLoyaltyFullOrderRef,
  ]);

  const addToCart = useCallback(
    async order => {
      if (!order) {
        return;
      }
      commitMany({ productsCart: order.filter(product => product.isAvailable) })
        .then(() => {
          setAddingOrderToCart(false);
          setShowReorderModal(false);
        })
        .catch(error => {
          console.error(error);
        });
    },
    [commitMany, setShowReorderModal, setAddingOrderToCart],
  );

  const onAddToOrder = order => {
    setShowReorderModal(true);
    setSelectedOrder(order);
    getLoyaltyFullOrderRef.current(order.sessionId);
  };

  const onConfirm = () => {
    setAddingOrderToCart(true);
    addToCart(formattedReorderCart);
  };

  const onCancel = () => {
    setShowReorderModal(false);
  };

  return {
    selectedOrder,
    formattedReorderCart,
    unmappedOrderItems,
    addingOrderToCart,
    loyaltyEnabled,
    isLoggedIn,
    orderHistory,
    showReorderModal,
    canGetOrder,
    onAddToOrder,
    onConfirm,
    onCancel,
    t,
  };
};

export { useLoyaltyOrderHistory };
