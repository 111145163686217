import { CancelRequestBuilder } from '../../../builders';
import { PaymentContext, TransType } from '../../../types';
import { destructCancelTransaction } from '../../../utils/destructerers/cancel';
import { requestTransaction } from './request-transaction';

export const requestCancelTransaction = async (context: PaymentContext) => {
  const response = await requestTransaction({
    RequestBuilder: CancelRequestBuilder,
    context,
    transactionType: TransType.Cancel,
  });

  return destructCancelTransaction(response, context);
};
