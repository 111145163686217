import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyQrLogInMachine } from './machines/loyalty-qr-login';
import { LoyaltyLoginPayload } from '@fingermarkglobal/types';
import { loyaltyQrLogin } from './requests/loyalty-qr-login';

interface Params {
  payload: null | LoyaltyLoginPayload;
  transition: null | ((machine: any) => void);
  abortSignal: AbortSignal;
}

const doLoyaltyQrLogIn = ({ payload = null, transition = null, abortSignal }: Params) => {
  const machine = createLoyaltyQrLogInMachine({ payload }).withConfig({
    services: { loyaltyQrLogin: ({ payload }) => loyaltyQrLogin({ payload, abortSignal }) },
  });

  const { instance: loyaltyQrLoginMachine } = interpret({
    name: 'loyaltyQrLoginMachine',
    machine,
    transition,
  });

  return { loyaltyQrLoginMachine };
};

export { doLoyaltyQrLogIn };
