import React from 'react';
import {
  Modal,
  PrimaryButtonLarge,
  SecondaryButtonLarge,
} from '@fingermarkglobal/cringer.components';

export const CustomSignOutModal = ({ modal, modalAccessibilityClass, t }) => {
  return (
    <Modal
      open={modal.open}
      ModalHeader={CustomModalHeader}
      className={modalAccessibilityClass}
      title={t('loyaltySignoutTitle')}
    >
      <div className="flex flex-col items-center py-16">
        <div className="mb-6">
          <h5 className="text-center text-5xl">{t('loyaltySignoutInformation')}</h5>
          <h5 className="text-center text-5xl font-normal mb-4">{t('loyaltySignoutQuestion')}</h5>
        </div>
        <PrimaryButtonLarge className="w-1/2 mb-6" onClick={modal.onCancel}>
          {t('loyaltySignoutCancel')}
        </PrimaryButtonLarge>
        <SecondaryButtonLarge className="w-1/2" onClick={modal.onSignOut}>
          {t('loyaltySignoutConfirm')}
        </SecondaryButtonLarge>
      </div>
    </Modal>
  );
};

const CustomModalHeader = ({ title }) => {
  return (
    <h3 className="text-2xl md:text-5xl font-bold font-header modal-header text-center pt-6">
      {title}
    </h3>
  );
};
