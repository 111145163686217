import { useCallback, useState, useRef, useEffect } from 'react';

class ModalTimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export const useModalPromise = options => {
  let timeoutSeconds = 10;
  if (options && options.timeoutSeconds !== null) {
    timeoutSeconds = options.timeoutSeconds;
  }
  const [modal, setModal] = useState({ open: false });

  const modalTimeoutId = useRef();
  useEffect(() => () => clearTimeout(modalTimeoutId.current), []);

  const showModal = useCallback(
    async cb => {
      let result;
      try {
        result = await new Promise((resolve, reject) => {
          clearTimeout(modalTimeoutId.current);
          modalTimeoutId.current = setTimeout(
            () => reject(new ModalTimeoutError(`Modal timed out after ${timeoutSeconds} seconds`)),
            timeoutSeconds * 1000,
          );
          const modalPayload = cb(resolve, reject);
          setModal({
            open: true,
            ...modalPayload,
          });
        });
      } catch (e) {
        if (e instanceof ModalTimeoutError) {
          setModal({ open: false });
        }
        throw e;
      }
      setModal({ open: false });
      return result;
    },
    [setModal, timeoutSeconds],
  );

  return { modal, showModal };
};
