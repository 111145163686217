import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import { check } from '@fingermarkglobal/validation';
import { LoyaltyLoginPayload } from '@fingermarkglobal/types';
import ky from 'ky';

const loyaltyLogin = async ({ payload }: { payload: LoyaltyLoginPayload }) => {
  logger.info('fm-api - loyaltyLogin - started');

  try {
    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/login`;
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT)
      throw new Error(
        `fm-api - loyaltyLogin - failed to get loyalty login endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.`,
      );

    const invalid = check({
      name: 'Loyalty-login',
      parameters: { payload },
    });

    if (invalid)
      throw new Error(`fm-api - loyaltyLogin - failed to validate parameters [${invalid}]`);

    const response = await ky
      .post(endpoint, {
        json: payload,
        timeout: 30 * 1000,
      })
      .json();

    if (!response)
      throw new Error(`fm-api - loyaltyLogin - request failed. Response {${response}}`);

    logger.info('fm-api - loyaltyLogin - finished', response);

    return response;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    logger.error('fm-api - loyaltyLogin - error:', err);

    throw err;
  }
};

export { loyaltyLogin };
