import { createMachine, assign } from 'xstate';
import { loyaltyOrderHistory } from '../requests/loyalty-order-history';
import { getLoyaltyFullOrder } from '../requests/loyalty-full-order';

const populateMessage = assign({
  data: (_, payload: any) => {
    const message = payload?.message;
    const error = message?.message;
    const data = payload?.data;

    if (data) {
      logger.debug(`api - loyaltyOrderHistory - FSM data recieved:`, data);
      return data;
    }

    if (error) {
      logger.error(`api - loyaltyOrderHistory - FSM error recieved:`, error);
      return error;
    }

    logger.error(`api - loyaltyOrderHistory - FSM message recieved:`, message);
    return message;
  },
});

const OrderHistoryMachineStates = {
  FetchingOrderSummaries: 'fetchingOrderSummaries',
  FetchingFullOrder: 'fetchingFullOrder',
  OrderSummarySuccess: 'orderSummarySuccess',
  FullOrderSuccess: 'fullOrderSuccess',
};

const createLoyaltyOrderHistoryMachine = ({
  token = '',
  organisationId = '',
  sessionId = '',
  lastTransition = '', // This is used so we can see what state success was called from
} = {}) =>
  createMachine<{
    token: string;
    organisationId: string;
    sessionId: string;
    lastTransition: string;
  }>(
    {
      id: 'loyaltyOrderHistory',
      initial: OrderHistoryMachineStates.FetchingOrderSummaries,
      context: {
        token,
        organisationId,
        sessionId,
        lastTransition,
      },
      states: {
        fetchingOrderSummaries: {
          invoke: {
            src: ({ token, organisationId }) => loyaltyOrderHistory({ token, organisationId }),
            onDone: {
              target: OrderHistoryMachineStates.OrderSummarySuccess,
              actions: ['populateMessage'],
            },
            onError: {
              target: 'error',
              actions: ['populateMessage'],
            },
          },
        },
        orderSummarySuccess: {
          on: {
            IDLE: OrderHistoryMachineStates.FetchingOrderSummaries,
            GET_ORDER: {
              target: OrderHistoryMachineStates.FetchingFullOrder,
              actions: assign({ sessionId: (_, event) => event.sessionId }),
            }, // Adding event to go the fetchOrderHistory state
          },
        },
        fetchingFullOrder: {
          invoke: {
            src: ({ organisationId, sessionId }) => getLoyaltyFullOrder(organisationId, sessionId),
            onDone: {
              target: OrderHistoryMachineStates.FullOrderSuccess,
              actions: ['populateMessage'],
            },
            onError: {
              target: 'error',
              actions: ['populateMessage'],
            },
          },
        },
        fullOrderSuccess: {
          on: {
            GET_ORDER: {
              target: OrderHistoryMachineStates.FetchingFullOrder,
              actions: assign({ sessionId: (_, event) => event.sessionId }),
            }, // Adding event to go the fetchOrderHistory state
          },
        },
        error: { type: 'final' },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

export { createLoyaltyOrderHistoryMachine, OrderHistoryMachineStates };
