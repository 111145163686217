import { request, getJwtToken } from '@fingermarkglobal/request';
import { checkRequestDetails } from '@fingermarkglobal/types';
import { check } from '@fingermarkglobal/validation';
import { buildCheckShiftEndpoint, buildRequestOptions } from '../../shared';

const checkShift = async (
  payload: checkRequestDetails,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {
  try {
    logger.info('[fm-api][check] - started');
    
    const invalid = check({
      name: 'fiscalManagement',
      parameters: { payload },
    });
    if (invalid) throw new Error(`[fm-api][check] - failed to validate parameters [${invalid}]`);

    const endpoint = buildCheckShiftEndpoint({ip, provider});

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][check] error generating jwt token`);
    
    const options = buildRequestOptions({
      endpoint,
      payload,
      token,
      timeout,
    });

    const response: unknown = await request.post(endpoint, options).json();

    if (!response) throw new Error(`[fm-api][check] request failed with response {${response}}`);

    return response;
  } catch (err) {
    logger.error('[fm-api][check] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][check] - finished');
  }
};

export { checkShift };
