import { atom } from 'recoil';

const sessionState = atom({
  key: 'sessionState',
  default: null,
  effects: [
    ({ onSet }) => {
      onSet(session => {
        if (!session?.id && !['/reset', '/home'].includes(window.location.pathname)) {
          console.error(new Error('Session is cleared outside of the reset route'));
        }
      });
    },
  ],
});

export { sessionState };
