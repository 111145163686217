import { request, getJwtToken } from '@fingermarkglobal/request';
import { buildGetOrderEndpoint } from '../../shared/endpoint.builder';
import { buildGetRequestOptions } from '../../shared';

interface GetOrderProps {
  organisationId: string;
  sessionId: string;
}

const logPrefix = '[fm-api][GET /order]';

const getOrder = async ({ organisationId, sessionId }: GetOrderProps, timeout: number = 30) => {
  logger.info(`${logPrefix} - started`);

  try {
    const endpoint = buildGetOrderEndpoint({});

    const token = await getJwtToken();
    if (!token) throw new Error(`${logPrefix} error generating jwt token`);

    const options = buildGetRequestOptions({
      endpoint,
      timeout,
      token,
      query: { organisationId, sessionId },
    });

    const response = await request.get(endpoint, options);

    if (!response.ok) {
      throw new Error(`${logPrefix} - failed with response {${response}}`);
    }

    console.info(`${logPrefix} - finished`, response);

    return response.json();
  } catch (err) {
    logger.error(`${logPrefix} error:`, err);
    throw err;
  } finally {
    logger.info(`${logPrefix} - finished`);
  }
};

export { getOrder };
