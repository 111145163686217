import React from 'react';

import { RestaurantCategoryView } from '@fingermarkglobal/cringer.components';

import { CustomGalleryCard } from '../../components/stateless/gallery-card';

const CustomRestaurantCategoryView = () => (
  <RestaurantCategoryView GalleryCardComponent={CustomGalleryCard} />
);

export { CustomRestaurantCategoryView };
