/* eslint max-lines: 0 */
import { some, isNumber, sortBy, isEmpty } from 'lodash';
import {
  parse,
  getYear,
  getMonth,
  getDate,
  getDay,
  isSameDay,
  isWithinInterval,
  compareAsc,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { translateProductName } from './translation';

export const reduceCustomisationDefaults = (result, current) => {
  const { min, max, products } = current;
  const newProducts = products.filter(product => product.default);
  return { ...result, [current.id]: { products: newProducts, min, max } };
};
// Should there be a consistent format for these types of things?
// The `customisation` format seems more fullproof, but I dont want to
// abstract early in case this isnt true for all product types in future

export const reduceComboDefaults = (result, current) => {
  const { id, products } = current;

  const newProducts = products.filter(product => product.default);
  return { ...result, [id]: newProducts };
};

export const getUpsellCategoryById = (upsells, categoryId, filterId = null) => {
  const [upsell] = upsells.filter(upsell => {
    //Check filter size is being passed and exists in the upsells object
    //This enables different upsells for different sizes (in combos)
    if (filterId && upsell.filterId) {
      return `${categoryId}` === `${upsell.id}` && `${filterId}` === `${upsell.filterId}`;
    }

    return `${categoryId}` === `${upsell.id}`;
  });
  return upsell;
};

export const getProductById = ({ products, id }) => {
  return products?.find(product => `${product.id}` === `${id}`);
};

export const getOptionCustomisationsByUid = ({ product, uid }) => {
  const { options = [] } = product || {};
  const result = [];
  options.forEach(option => {
    const { products = [] } = option;

    const productCustomisation = products?.find(optionProduct => {
      const { uid: optionProductUid = '' } = optionProduct;
      return optionProductUid === uid;
    });
    if (productCustomisation) result.push(...productCustomisation.customisations);
  });
  return result;
};

const isValid = value => {
  return value && value.length;
};

const generateValidPayload = (input = [], value) => {
  if (isValid(input)) return [...input];
  return [value];
};

const withinTime = ({ toHour, fromHour, current, fromString, toString }) => {
  // NOTE only check if we have valid to and from hours
  // Seconds fallback to zero if not provided
  if (isNumber(toHour) && isNumber(fromHour)) {
    // This only works in the scope of a 24 hour day
    // i.e 8:00 - 14:30 works
    // 14:30 - 8:00 does not
    return isWithinInterval(current, {
      start: parse(fromString, 'HH:mm', current),
      end: parse(toString, 'HH:mm', current),
    });
  } else return true;
};

const filterByVisibilityFlag = entity => {
  const { isVisible } = entity;
  // Let through if not explicitly false
  const empty = isVisible === null || isVisible === undefined;
  return empty ? true : isVisible;
};

const isAvailabilityValid = value => {
  return value && value.length && value.every(item => !isEmpty(item));
};

const filterByTimeRestrictions = settings => entity => {
  const { availability } = entity;
  const { settings: kioskSettings, flags } = settings || {};
  const { timezone } = kioskSettings || {};
  const { kiosksYumAuDaypartingEnabled: isAvailabilityEnabled } = flags;

  // For all organizations except YUM AU, the "kiosksYumAuDayparting" feature is enabled.
  // This check ensures the feature is bypassed if availability is invalid or if it's explicitly disabled for YUM AU.
  if (!isAvailabilityValid(availability) || !isAvailabilityEnabled) {
    return true;
  }

  return availability.some(availabilityIterator => {
    const { to, from, year, month, day: date, weekday } = availabilityIterator;

    const { hour: fromHour, minute: fromMinute } = from || {};
    const { hour: toHour, minute: toMinute } = to || {};

    const fromString = `${(fromHour ?? 0).toString().padStart(2, '0')}:${(fromMinute || 0)
      .toString()
      .padStart(2, '0')}`;
    const toString = `${(toHour ?? 0).toString().padStart(2, '0')}:${(toMinute || 0)
      .toString()
      .padStart(2, '0')}`;

    const current = utcToZonedTime(new Date(), timezone);

    const dayMap = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const allDates = [];

    const validYears = generateValidPayload(year, getYear(current));
    const validMonths = generateValidPayload(month, getMonth(current));
    const validDates = generateValidPayload(date, getDate(current));
    const validWeekday = generateValidPayload(weekday, dayMap[getDay(current)]);

    validYears.forEach(yearIterator => {
      validMonths.forEach(monthIterator => {
        validDates.forEach(dateIterator => {
          const newDate = new Date(yearIterator, monthIterator, dateIterator);

          allDates.push(newDate);
        });
      });
    });

    const isWithinDates = allDates.map(item => {
      // Cannot use isEqual given different timestamp on current
      return isSameDay(item, current);
    });

    const isWithinWeek = validWeekday.map(item => {
      return dayMap.indexOf(item) === getDay(current);
    });

    const compare = compareAsc(
      parse(fromString, 'HH:mm', current),
      parse(toString, 'HH:mm', current),
    );

    // Handles time comparisons when intervals span past midnight.
    // If `fromString` is greater than `toString` (indicating a wrap around midnight):
    // - Check if the current time falls within the interval from `fromString` to 23:59.
    // - Additionally, check if the current time falls within the interval from 00:00 to `toString`.
    if (compare === 1) {
      const fromInterval = isWithinInterval(current, {
        start: parse(fromString, 'HH:mm', current),
        end: parse('23:59', 'HH:mm', current),
      });
      const toInterval = isWithinInterval(current, {
        start: parse('00:00', 'HH:mm', current),
        end: parse(toString, 'HH:mm', current),
      });
      return some(isWithinDates) && some(isWithinWeek) && (fromInterval || toInterval);
    } else {
      const isWithinTime = withinTime({ fromHour, toHour, fromString, toString, current });
      return some(isWithinDates) && some(isWithinWeek) && isWithinTime;
    }
  });
};

const getHydratedProductsByPromoId = ({ productsWithPromoId, hydratedProducts }) => {
  const result = hydratedProducts.filter(product =>
    productsWithPromoId.some(
      productWithPromoId => productWithPromoId.promoId === product.providerData.PromoId,
    ),
  );
  const sortedResult = sortBy(result, [
    product => productsWithPromoId.findIndex(p => p.promoId === product.providerData.PromoId),
  ]);
  return sortedResult;
};

const getHydratedProductsFromCategory = ({
  category,
  offersAndRewards,
  id,
  hydratedProducts,
} = {}) => {
  if (id === 'offers') {
    return getHydratedProductsByPromoId({
      productsWithPromoId: offersAndRewards.promos,
      hydratedProducts,
    });
  }
  if (id === 'rewards') {
    return getHydratedProductsByPromoId({
      productsWithPromoId: offersAndRewards.promosWithPoints,
      hydratedProducts,
    });
  }
  const { products: categoryProductIds = [] } = category || {};
  //filter at the end to return only products that are available
  //in case a product from a category can't be shown (day/time restrictions)
  //this prevents empty items in the array
  return categoryProductIds
    ?.map(categoryProductId => {
      return hydratedProducts?.find(hydratedProduct => {
        const { productId } = hydratedProduct || {};
        return categoryProductId === productId;
      });
    })
    .filter(product => product != null);
};

const updatePricesByTimeRestrictions = ({ product, settings }) => {
  const productToUpdate = { ...product };
  const { timezone } = settings || {};
  const { priceChanges = null } = productToUpdate;
  const current = utcToZonedTime(new Date(), timezone);
  let updatePrice = false;
  let surcharge;

  if (!priceChanges) return productToUpdate;

  priceChanges.forEach(priceChange => {
    const { from, to, price: surchargePrice } = priceChange;
    const { hour: fromHour, minute: fromMinute } = from || {};
    const { hour: toHour, minute: toMinute } = to || {};
    const fromString = `${fromHour}:${fromMinute || 0}`;
    const toString = `${toHour}:${toMinute || 0}`;

    const compare = compareAsc(
      parse(fromString, 'HH:mm', current),
      parse(toString, 'HH:mm', current),
    );

    if (compare === 1) {
      const fromInterval = isWithinInterval(current, {
        start: parse(fromString, 'HH:mm', current),
        end: parse('23:59', 'HH:mm', current),
      });

      const toInterval = isWithinInterval(current, {
        start: parse('00:00', 'HH:mm', current),
        end: parse(toString, 'HH:mm', current),
      });

      if (fromInterval || toInterval) {
        updatePrice = true;
        surcharge = surchargePrice;
      }
    } else {
      const withinInterval = isWithinInterval(current, {
        start: parse(fromString, 'HH:mm', current),
        end: parse(toString, 'HH:mm', current),
      });

      if (withinInterval) {
        updatePrice = true;
        surcharge = surchargePrice;
      }
    }
  });

  if (productToUpdate.type === 'combo') {
    const filters = productToUpdate.combo.filters.map(product => {
      return updatePricesByTimeRestrictions({ product, settings });
    });
    const options = productToUpdate.options.map(product => {
      return updatePricesByTimeRestrictions({ product, settings });
    });
    productToUpdate.combo = { ...productToUpdate.combo, filters };
    productToUpdate.options = options;
  }

  const updatedProduct = {
    ...productToUpdate,
    price: updatePrice ? surcharge : productToUpdate.price,
  };
  return updatedProduct;
};

const filterImageUrlFallback = product => {
  /**
   * Ensures products have a valid imageUrl. Falls back to originalImageUrl if imageUrl is empty, null, or undefined.
   * Addresses cases where Firebase fails to trigger, causing missing images in some menus.
   */
  const formattedImageUrl =
    !product.imageUrl || product.imageUrl === null || product.imageUrl === ''
      ? product.originalImageUrl
      : product.imageUrl;
  return {
    ...product,
    imageUrl: formattedImageUrl,
  };
};

export {
  filterByVisibilityFlag,
  filterByTimeRestrictions,
  getHydratedProductsFromCategory,
  translateProductName,
  updatePricesByTimeRestrictions,
  filterImageUrlFallback,
};
