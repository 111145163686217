import { FoodDescription, HeadersT, PaymentConfig, RequestT, SaleMessage, TransType } from '../types';

import { getCurrentFormattedDate, parseCheckNumber } from '../utils';
import { parseTransactionAmout } from '../utils/parse-transaction-amount';
import { RequestBuilder } from './RequestBuilder';
export class SaleRequestBuilder extends RequestBuilder {
  constructor({
    amount,
    config,
    idempotencyKey,
    referenceNumber,
  }: {
    amount: number;
    config: PaymentConfig;
    idempotencyKey: string;
    referenceNumber: string;
  }) {
    super({ method: 'POST', amount, config, idempotencyKey, referenceNumber });
  }

  setBody(): void {
    const { settings } = this.config;
    const {
      responseChannelType,
      messageType,
      responsePayloadFormat,
      partialAuthFlag,
      merchantCurrencyCode,
      isLocal,
      taxIndicator = "1",
      taxType = "TX"
    } = settings;
    
    const currencyCode =
      merchantCurrencyCode && merchantCurrencyCode.trim() !== '' ? merchantCurrencyCode : 'USD';

    const { identifiers } = this.getIdentifiersFromPairingResponse();

    const saleMessage: SaleMessage = {
      responseChannelType,
      messageType,
      messageId: this.getIntegratorId(),
      responseChannel: {
        responsePayloadFormat,
      },
      message: {
        referenceNumber: this.referenceNumber,
        transType: TransType.Sale,
        transAmount: parseTransactionAmout(this.amount),
        transactionDateTime: getCurrentFormattedDate(isLocal),
        identifiers,
        partialAuthFlag,
        cashierId: 'Cashier1', //TODO: Move to Poral this field
        currency: { merchantCurrencyCode: currencyCode },
        compliance: {
          uniqueDeviceId: this.terminalSerialNumber,
          customerCode: this.referenceNumber
        },
        industry: {
          data: {
            chargeDescription: FoodDescription.foodAndBev,
            checkNumber: parseCheckNumber(this.referenceNumber),
            foodDescription: FoodDescription.foodAndBev,
            employeeNumber1: 999,
            foodAmount: parseTransactionAmout(this.amount),
            beverageAmount: '0.00',
            tip1: '0.00'
          }
        }
      },
    };
    this.body = saleMessage;
  }

  getBody(): SaleMessage {
    return this.body;
  }
}
