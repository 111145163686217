import { FallbackLogger } from '@fingermarkglobal/logger';

window.logger = window.logger || new FallbackLogger();

export {
  useCart,
  useStage,
  useSecondaryCart,
  useReorderCart,
  useScale,
  formattedCartState,
  reorderCartState,
  mapOrderItems,
} from './src';
