import React from 'react';
import { BasketCount } from '../basket-count';
import { CategoriesHeader } from '../../stateful/categories-header';
import { LoyaltyBanner } from '../../stateful/loyalty-banner';
import { SecondaryButton } from '../../stateless/secondary-button';
import { usePrice } from '../../../hooks/generic/utilities/price';

const Header = ({
  title = null,
  total = null,
  className = '',
  count = 0,
  showBasketCount = true,
  showCategoriesHeader = false,
  onBasketClick = () => {},
  previousAction = null,
  menuAction = null,
  menuText = '',
  allowPreviousAction = true,
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  const { formatPrice } = usePrice();

  return (
    <div data-test="header" className={`flex flex-col py-12 bg-header ${className}`}>
      {showCategoriesHeader && <CategoriesHeader />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      <div className="flex justify-between mb-8">
        <h1 className="px-8 text-6xl font-bold font-header">{title}</h1>
      </div>
      <div className="flex justify-between">
        <div className="top-0 items-start space-x-4">
          {previousAction && (
            <SecondaryButton
              disabled={!allowPreviousAction}
              onClick={menuAction}
              className="mr-6"
              data-test="menu-button"
            >
              {menuText}
            </SecondaryButton>
          )}
        </div>
        <div className="flex space-x-8">
          {showBasketCount && <BasketCount count={count} onClick={onBasketClick} />}
          {showSubtotal && (
            <p className="text-6xl font-bold" data-test="header-cart-subtotal">
              {formatPrice({ price: total })}
            </p>
          )}
          {isCouponEnabled && showCouponButton && (
            <SecondaryButton
              className="ml-8 text-4xl font-bold"
              onClick={couponAction}
              data-test="enter-offer-button"
            >
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
