import React from 'react';
import { useMiniCart } from './hook';
import { CartProductLine } from '../../../components/stateless/cart-product-line';
import { PrimaryButton } from '../../../components/stateless/primary-button';
import { SecondaryButton } from '../../../components/stateless/secondary-button';

const MiniCart = ({
  showCart = false,
  origin = '',
  onSelectCustomisationProduct = () => () => {},
  shouldSkipCustomerDetailsPath = false,
  showComboFilterName = true,
}) => {
  const {
    t,
    count,
    cart,
    onCartUpdateHandler,
    onEditProductHandler,
    cartHandler,
    showCartPreview,
    total,
    nextAction,
    formatPrice,
  } = useMiniCart({ origin, onSelectCustomisationProduct, shouldSkipCustomerDetailsPath });

  if (!showCart) return null;

  const classes =
    'absolute top-3 left-0 right-0 transition-transform ease-in-out duration-700 z-0 transform';
  const classList = classes.concat(' ', showCartPreview ? '-translate-y-full' : 'translate-y-0');

  return (
    <div className={classList} data-test="slide-cart">
      <div className="relative">
        <button
          data-test="button-slide-cart"
          type="button"
          className="absolute top-0 left-0 right-0 block w-64 py-8 mx-auto mt-1 text-2xl transform -translate-y-full bg-gray-100 border-2 border-b-0 border-black rounded-t-lg outline-none"
          onClick={cartHandler}
        >
          <div className="relative">
            <div
              data-test="slide-cart-count"
              className="absolute top-0 right-0 w-12 h-12 pt-2 -mt-12 -mr-4 text-3xl text-white rounded-full bg-primary"
            >
              {count}
            </div>
            {t('cart')}
          </div>
        </button>
        <div className="col-span-3 p-6 mb-1 overflow-auto bg-gray-100 border-t-2 border-black overscroll-contain">
          <div className="flex">
            <div className="w-full overflow-auto h-80">
              {cart.map((product, i) => (
                <CartProductLine
                  key={`cart-product-${i}`}
                  product={product}
                  t={t}
                  onUpdateHandler={onCartUpdateHandler}
                  onEditProductHandler={onEditProductHandler}
                  showCustomisations={false}
                  showComboFilterName={showComboFilterName}
                />
              ))}
            </div>

            <div className="flex flex-col items-center justify-center m-4 mt-6 ml-4 space-y-5 text-4xl">
              <div className="text-4xl uppercase">{t('miniCartTotal')}</div>
              <div className="flex items-center justify-center h-16 text-4xl bg-white rounded-lg w-72">
                {formatPrice({ price: total })}
              </div>
              <PrimaryButton onClick={cartHandler} className="w-72">
                {t('miniCartCloseAction')}
              </PrimaryButton>
              <SecondaryButton onClick={nextAction} className="w-72">
                {t('miniCartCheckoutAction')}
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MiniCart };
