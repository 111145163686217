import React from 'react';
import { Loading } from '../loading';
import { ModalFooter as DefaultModalFooter } from '../modal-footer';
import { ModalHeader as DefaultModalHeader } from '../modal-header';

// Nicked from https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/react/modals/regular
const Modal = ({
  'data-test': dataTest = 'modal',
  className = 'inset-0',
  open = false,
  title = null,
  action = null,
  actionText = null,
  allowAction = true,
  children = null,
  ModalHeader = DefaultModalHeader,
  ModalFooter = DefaultModalFooter,
  closeModal = null,
  closeText = null,
  spacer = null,
  isLoading = false,
  loadingTitle = ' ',
  loadingMessage = ' ',
  formatPrice = () => {},
} = {}) => {
  if (!open) return null;

  const hasActions = !!action || !!closeModal;

  return (
    <div
      data-test={dataTest}
      className={`absolute flex flex-col items-center py-4 px-0 md:p-8 justify-center w-full h-full bg-gray-600 bg-opacity-70 z-50 ${className}`}
    >
      {isLoading && (
        <div
          data-test="container-loading-modal"
          className="flex flex-col w-5/6 max-h-full mx-auto bg-white border-0 rounded-lg shadow-lg custom-modal bg-modal"
        >
          <div className="flex items-center justify-center">
            <Loading title={loadingTitle} message={loadingMessage} />
          </div>
        </div>
      )}
      <div
        data-test="container-content-modal"
        className={`flex flex-col w-11/12 md:w-5/6 border-0 mx-auto rounded-lg shadow-lg bg-white max-h-full custom-modal bg-modal ${
          isLoading ? 'hidden' : ''
        }`}
      >
        {title && <ModalHeader title={title} formatPrice={formatPrice} />}
        {children}
        {hasActions && (
          <ModalFooter
            action={action}
            actionText={actionText}
            allowAction={allowAction}
            closeModal={closeModal}
            closeText={closeText}
            spacer={spacer}
          />
        )}
      </div>
    </div>
  );
};

export { Modal };
