// Define an enum for event keys
export const EVENT_KEYS = {
  DINE_MODE: 'DINE_MODE',
  PRODUCT_REMOVED: 'PRODUCT_REMOVED',
  PRODUCT_ADDED: 'PRODUCT_ADDED',
  PRODUCT_VIEWED: 'PRODUCT_VIEWED',
  UPSELL_ADDED: 'UPSELL_ADDED',
  UPSIZE_SELECTED: 'UPSIZE_SELECTED',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  BEGIN_CHECKOUT: 'BEGIN_CHECKOUT',
  VIEW_ITEM_LIST: 'VIEW_ITEM_LIST',
  UPSELL_DISMISSED: 'UPSELL_DISMISSED',
  ORDER_VIEWED: 'ORDER_VIEWED',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
  ORDER_UPDATED: 'ORDER_UPDATED',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  EDIT_CART_ITEM: 'EDIT_CART_ITEM',
  SELECT_ITEM: 'SELECT_ITEM',
  SHOW_EMBEDDED_ERROR: 'SHOW_EMBEDDED_ERROR',
};

export const EVENT_TAGS = {
  YUM_GLOBAL: {
    [EVENT_KEYS.DINE_MODE]: 'selectOrderType',
    [EVENT_KEYS.PRODUCT_REMOVED]: 'remove_from_cart',
    [EVENT_KEYS.PRODUCT_ADDED]: 'add_to_cart',
    [EVENT_KEYS.PRODUCT_VIEWED]: 'view_item',
    [EVENT_KEYS.UPSELL_ADDED]: 'add_to_cart',
    [EVENT_KEYS.UPSIZE_SELECTED]: 'upsizeSelected',
    [EVENT_KEYS.PAYMENT_COMPLETED]: 'purchase',
    [EVENT_KEYS.BEGIN_CHECKOUT]: 'begin_checkout',
    [EVENT_KEYS.VIEW_ITEM_LIST]: 'view_item_list',
    [EVENT_KEYS.EDIT_CART_ITEM]: 'editCartItem',
    [EVENT_KEYS.SHOW_EMBEDDED_ERROR]: 'embedded_error_message',
    [EVENT_KEYS.SELECT_ITEM]: 'select_item',
    [EVENT_KEYS.ORDER_VIEWED]: 'view_cart',
  },
  DEFAULT: {
    [EVENT_KEYS.DINE_MODE]: 'Dine Mode Selected',
    [EVENT_KEYS.PRODUCT_REMOVED]: 'Product Removed',
    [EVENT_KEYS.PRODUCT_ADDED]: 'Product Added',
    [EVENT_KEYS.PRODUCT_VIEWED]: 'Product Viewed',
    [EVENT_KEYS.UPSELL_ADDED]: 'Upsell Added',
    [EVENT_KEYS.UPSELL_DISMISSED]: 'Upsell Dismissed',
    [EVENT_KEYS.ORDER_VIEWED]: 'Order Viewed',
    [EVENT_KEYS.PAYMENT_STARTED]: 'Payment Started',
    [EVENT_KEYS.ORDER_UPDATED]: 'Order Updated',
    [EVENT_KEYS.ORDER_COMPLETED]: 'Order Completed',
    [EVENT_KEYS.UPSIZE_SELECTED]: 'Upsize Selected',
    [EVENT_KEYS.PAYMENT_COMPLETED]: 'Payment Completed',
    [EVENT_KEYS.BEGIN_CHECKOUT]: 'Begin Checkout',
    [EVENT_KEYS.VIEW_ITEM_LIST]: 'View Item List',
    [EVENT_KEYS.EDIT_CART_ITEM]: 'Cart Item Edit',
    [EVENT_KEYS.SELECT_ITEM]: 'Select Item',
    [EVENT_KEYS.SHOW_EMBEDDED_ERROR]: 'Show Embedded Error',
  },
};

export const getEventTag = (eventKey, useYumGlobalTag = false) => {
  // If YUM_GLOBAL should be used and the eventKey exists in YUM_GLOBAL
  // Otherwise, fallback to DEFAULT
  if (useYumGlobalTag && EVENT_TAGS.YUM_GLOBAL[eventKey]) {
    return EVENT_TAGS.YUM_GLOBAL[eventKey];
  }

  return EVENT_TAGS.DEFAULT[eventKey];
};
