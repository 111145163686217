import Dinero from 'dinero.js';
import { nanoid } from 'nanoid';
import { arrayToString } from '../../../utilities/generic';

const currencyFormat = process.env.POI_APP_CURRENCY_FORMAT || '$0,0.00';
const currencySymbol = process.env.POI_APP_CURRENCY_SYMBOL || null;
const currencyPosition = process.env.POI_APP_CURRENCY_POSITION || 'START';
const currencySeparator = process.env.POI_APP_CURRENCY_SEPARATOR || null;

const formatPrice = (price, currency) => {
  if (!price) return '';
  // string value will be `price not found` so return that to display
  if (typeof price === 'string') return price;

  const amount = Math.round(price * 100);
  const options = { amount };
  const formattedPrice = Dinero(currency ? { ...options, currency } : options).toFormat(
    currencyFormat,
  );

  if (!currencySymbol) return formattedPrice;

  const customFormatPrice = currencySeparator
    ? formattedPrice.replace(/,/g, currencySeparator)
    : formattedPrice;

  return currencyPosition === 'START'
    ? `${currencySymbol}${customFormatPrice}`
    : `${customFormatPrice}${currencySymbol}`;
};

const formatEnergyInfo = (energyInformation = '', count = 1) => {
  // Energy information is a string that contains number and measuring unit (kj or j)
  const energyInfo = energyInformation.match(/[kjKJ]+|[0-9]+/g);
  if (energyInfo.length === 2) {
    let joules = energyInfo[0];
    const unit = energyInfo[1].toLowerCase();

    // Convert to joules
    if (unit === 'kj') joules *= 1000;

    const totalJoules = joules * count; // Multiply by product count

    if (totalJoules > 1000) return `${Math.ceil(totalJoules / 1000)}KJ`;
    return `${totalJoules}J`;
  }
  // missing energyInformation
  return '';
};

const formatProduct = product => {
  const {
    id,
    min = 0,
    max = 0,
    total = 0,
    options = [],
    quantity = 1,
    products = [],
    uid = nanoid(),
    type = 'simple',
    customisations = [],
    price = 'Price not found...',
    name = { text: 'Name not found...' },
    imageUrl = 'Image not found...', // TODO: Add fallback image url
    description = { text: 'Description not found...' },
    energyInformation = 'No energy information available',
    allergens = 'No allergen information available',
    posItemId = 0,
  } = product || {};

  const allergensString = typeof allergens === 'string' ? allergens : arrayToString(allergens);
  const formattedPrice = formatPrice({ price });
  const formattedTotal = formatPrice({ price: total });

  const formattedOptions = options.map(formatProduct);
  const formattedCustomisations = customisations.map(formatProduct);

  return {
    id,
    uid,
    min,
    max,
    type,
    name,
    quantity,
    products,
    description,
    image: imageUrl,
    energyInformation,
    total: formattedTotal,
    price: formattedPrice,
    options: formattedOptions,
    allergens: allergensString,
    customisations: formattedCustomisations,
    posItemId,
  };
};

/*
Flatten array of arrays containing products, down to usable objects, eg:
[[product1, product1], [product2], [product3, product3, product3]]
[{quantity:2}, {quantity:1}, {quantity:3}]
*/
const flattenProducts = productArray => {
  const [product] = productArray;

  const quantity = productArray.length;
  const total = product.price * quantity;

  return { ...product, total, quantity };
};

export { formatProduct, flattenProducts, formatPrice, formatEnergyInfo };
