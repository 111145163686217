import { useRecoilState } from 'recoil';
import { originState } from '@fingermarkglobal/atoms';

/**
 * This is used to control from which part of the
 * app the call for product editing.
 * After editing, it should go back to the page who called it
 */
const useOrigin = () => {
  const [origin, setOrigin] = useRecoilState(originState);

  return {
    origin,
    setOrigin,
  };
};

export { useOrigin };
