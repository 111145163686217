export const getCurrentFormattedDate = (isLocal: boolean): string => {
    // NZ time
    if (isLocal) {
    const now = new Date();
    const nzOffset = 13 * 60 * 60 * 1000; // UTC+13
    return new Date(now.getTime() + nzOffset).toISOString();
  } else {
    return new Date().toISOString();
  }
};
