import React from 'react';
import ArrowLeft from 'heroicons/solid/arrow-left.svg';

const IconButton = ({
  className = '',
  onClick,
  disabled = false,
  children,
  large = false,
  Icon = ArrowLeft,
  iconReverse = false,
  'data-test': dataTest = 'icon-button',
}) => {
  const dynamicClassName = large
    ? 'py-0 px-2 sm:py-6 sm:px-8 text-xl sm:text-6xl'
    : 'py-0 px-2 sm:py-4 sm:px-6 text-xl sm:text-3xl';

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`uppercase text-black font-bold focus:outline-none ${dynamicClassName} ${className}`}
      data-test={dataTest}
    >
      <div className="flex items-center justify-center space-x-1 sm:items-start sm:space-x-2 icon-button md:items-center">
        {!iconReverse && <Icon className="w-7 sm:w-8 mb-icon-button" />}
        <div>{children}</div>
        {iconReverse && <Icon className="w-7 sm:w-8 mb-icon-button" />}
      </div>
    </button>
  );
};

export { IconButton };
