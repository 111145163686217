import { atom, selectorFamily } from 'recoil';
import { sessionState } from '../session';
import { formattedSettingsState } from '../system';

const loyaltyState = atom({
  key: 'loyaltyState',
  default: null,
});

const orderHistoryState = atom({
  key: 'orderHistoryState',
  default: [],
});

const formattedOrderState = atom({
  key: 'formattedOrderState',
  default: null,
});

const orderHistoryModalState = atom({
  key: 'orderHistoryModalState',
  default: false,
});

const selectedOrderState = atom({
  key: 'selectedOrderState',
  default: null,
});

const selectedOfferState = atom({
  key: 'selectedOfferState',
  default: {
    offerName: null,
    offerId: null,
    offerType: null,
  },
});

const formattedLoyaltyPayloadState = selectorFamily({
  key: 'formattedLoyaltyPayloadState',
  get: () => ({ get }) => {
    const { id: sessionId } = get(sessionState) || {};
    const {
      kiosk: { organisation: organisationId, serial: kioskSerialKey, storeId },
    } = get(formattedSettingsState) || {};
    return {
      sessionId,
      organisationId,
      kioskSerialKey,
      storeId,
    };
  },
});

export {
  formattedLoyaltyPayloadState,
  loyaltyState,
  orderHistoryState,
  orderHistoryModalState,
  formattedOrderState,
  selectedOrderState,
  selectedOfferState,
};
