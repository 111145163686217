import React from 'react';
import { PrimaryButton } from '../../../primary-button';
import { SecondaryButton } from '../../../secondary-button';
import { BasketCount as GenericBasketCount } from '../../../../stateless/basket-count';

const AdditionalFooter = ({
  nextText = 'Next',
  nextAction = null,
  isNextActionOnBottom = false,
  isBasketCountOnBottom = false,
  isQuantityControllerOnBottom = false,
  isPriceOnEnd = false,
  allowNextAction = true,
  nextActionClass = '',
  className = '',
  spacer = null,
  count = 0,
  showBasketCount = true,
  onBasketClick = () => {},
  total = null,
  OverwriteCartIcon = null,
  BasketCount = GenericBasketCount,
  basketText = null,
  previousAction = null,
  isPreviousActionOnBottom = false,
  previousText = '',
  isViewOrderDisabled = false,
} = {}) => {
  return (
    <div
      data-test="additional-footer"
      className={`flex py-8 bg-footer w-full z-0 justify-between ${className}`}
    >
      <div
        data-test="additional-footer-inputs"
        className={`flex mx-4 justify-between w-full ${className}`}
      >
        {showBasketCount && isBasketCountOnBottom && (
          <div className="flex">
            <BasketCount
              className=""
              count={count}
              onClick={onBasketClick}
              OverwriteIcon={OverwriteCartIcon}
              text={basketText}
              isViewOrderDisabled={isViewOrderDisabled}
            />
            {!isPriceOnEnd && <div className="self-center ml-8 text-4xl font-bold">{total}</div>}
          </div>
        )}
        <div
          className={`flex flex-col items-end space-x-4 ${
            showBasketCount && isBasketCountOnBottom ? `w-3/4` : `w-full`
          }`}
        >
          <div className="flex items-center">
            {isQuantityControllerOnBottom && spacer}
            {previousAction && isPreviousActionOnBottom && (
              <SecondaryButton
                data-test="previous-action-button"
                onClick={previousAction}
                className={`ml-6 previous-action-button ${nextActionClass}`}
              >
                {previousText}
              </SecondaryButton>
            )}
            {nextAction && isNextActionOnBottom && (
              <PrimaryButton
                data-test="next-action-button"
                disabled={!allowNextAction}
                onClick={nextAction}
                className={`ml-6 next-action-button ${nextActionClass}`}
              >
                {nextText}
              </PrimaryButton>
            )}
            {isPriceOnEnd && (
              <div
                data-test="additional-footer-price"
                className="self-center ml-8 text-4xl font-bold"
              >
                {total}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AdditionalFooter };
