import { request, getJwtToken } from '@fingermarkglobal/request';
import { PlaceOrderPayload, KioskSettingsOrdering, KioskSession } from '@fingermarkglobal/types';
import { check, validate } from '@fingermarkglobal/validation';
import { buildOrderPlaceEndpoint, buildRequestOptions } from '../../shared';

const RECEIPT_TYPE_EMAIL = 'email';

const placeOrder = async (
  payload: PlaceOrderPayload,
  timeout: number = 30,
  ip: string = '',
  provider: string = '',
) => {
  try {
    logger.info('[fm-api][order/place] - started');
    validate({ name: 'doPlaceOrder', parameters: { payload } });

    const invalid = check({
      name: 'Place-order',
      parameters: { payload },
    });
    if (invalid) throw new Error(`[fm-api][order/place] - failed to validate parameters [${invalid}]`);
    
    const { customPlaceOrderEndpoint: customEndpoint } = payload?.settings?.labs || {};
    const endpoint = buildOrderPlaceEndpoint({ customEndpoint, ip, provider });

    const token = await getJwtToken();
    if (!token) throw new Error(`[fm-api][order/place] error generating jwt token`);

    const options = buildRequestOptions({
      endpoint,
      token,
      timeout,
      payload,
    });

    logger.info('[fm-api][order/place] - request', payload);
    const response = await request.post(endpoint, options).json();

    //enforce that the local provider response has kiosk settings
    //https://fingermarkglobal.atlassian.net/browse/SONICBR1-1380
    //TODO: Proper fix would need a change in the bridge server code to have the kiosk object inside settings
    if(provider === 'local') {
      response.settings = {...response.settings, kiosk: payload.settings.kiosk };
    } 

    logger.info('[fm-api][order/place] - response', response);

    if (!response) throw new Error(`[fm-api][order/place] - failed with response {${response}}`);

    await handleReceiptEmail(response);

    return response;
  } catch (err) {
    logger.error('[fm-api][order/place] - error:', err);
    throw err;
  } finally {
    logger.info('[fm-api][order/place] - finished');
  }
};

const handleReceiptEmail = async (response: KioskSettingsOrdering) => {
  const { receiptId, settings, session } = response;
  const { kiosk: kioskSettings = {} } = settings || {};

  const sendReceiptEndpoint = process.env.POI_APP_HAWKE_SEND_RECEIPT_FUNCTION;
  const { receiptType } = kioskSettings;

  if (sendReceiptEndpoint && receiptType == RECEIPT_TYPE_EMAIL) {
    try {
      if (!validateEmailRecipient(session)) {
        logger.warn(`[fm-api][order/place] - email not sent based on not valid recipient for receiptId: ${receiptId}`);
      } else {
        logger.info(`[fm-api][order/place] - sending email with receiptId ${receiptId}`);

        await request.get(`${sendReceiptEndpoint}?receiptId=${receiptId}`);

        logger.info(`[fm-api][order/place] - email sent successfully with receiptId ${receiptId}`);
      }
    } catch (err) {
      logger.error('[fm-api][order/place] - error on sending email');
    }
  }
};

const validateEmailRecipient = (session: KioskSession): boolean => {
  const { customer } = session;
  const customerEmail: string = customer?.email || '';

  const emailValidationRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return !!(
    typeof customerEmail === 'string' && 
    customerEmail.length > 0 && 
    !customerEmail.includes('fingermarkglobal') && 
    emailValidationRegex.test(customerEmail)
  );
};

export { placeOrder, validateEmailRecipient, handleReceiptEmail };
