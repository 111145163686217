import React from 'react';
import { PrimaryButtonLarge } from '@fingermarkglobal/cringer.components';
import { NotAMemberSection } from './NotAMemberSection';

export const MemberSignInModal = ({
  t,
  onLoginClick,
  onSkipClick,
  sessionType,
  notAMemberAction,
}) => {
  return (
    <div className="flex flex-col items-center py-16">
      <div className="w-1/2 mb-32">
        <h5 className="text-center text-5xl font-normal">Earn and enjoy rewards...</h5>
        <h5 className="text-center text-5xl font-normal mb-4">marketing message</h5>
        <PrimaryButtonLarge
          className="w-full uppercase"
          data-test="button-primary"
          onClick={onLoginClick}
        >
          {t('loyaltyMemberSignInButtonText')}
        </PrimaryButtonLarge>
      </div>
      <div className="w-1/2 mb-24">
        <h5 className="text-center text-5xl font-normal mb-4">Or</h5>
        <PrimaryButtonLarge
          className="w-full uppercase"
          data-test="button-primary"
          onClick={onSkipClick}
        >
          {t('loyaltyGuestOrderButtonText')}
        </PrimaryButtonLarge>
      </div>
      <NotAMemberSection
        t={t}
        notAMemberAction={() => {
          if (sessionType === 'DINE_IN') {
            notAMemberAction({ sessionType: 'DINE_IN' });
          } else {
            notAMemberAction({ sessionType: 'TAKEAWAY' });
          }
        }}
      />
    </div>
  );
};
