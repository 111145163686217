const commit = ({ createUpdater, setCart, product, format, cart, staged, reformat } = {}) => {
  const update = createUpdater({ set: setCart });

  if (product) {
    const commitable = format({ product, update, isRootProduct: true });

    commitable.posItemId = product.posItemId;
    return setCart(cartItems => [...cartItems, commitable]);
  }

  /**
   * Having an uid in the staged item means that it was reformatted
   * from the cart and we should remove the old version from the cart
   * to add the new object. We also have to make sure the products will
   * keep the same order.
   */
  const editingCartItemIndex = cart.findIndex(item => item.uid === staged.uid);

  if (editingCartItemIndex >= 0) {
    const editingCartItem = reformat({ product: staged, update, isRootProduct: true });
    const newCart = [...cart];
    newCart[editingCartItemIndex] = editingCartItem;
    return setCart([...newCart]);
  }

  setCart(cartItems => [...cartItems, reformat({ product: staged, update, isRootProduct: true })]);
};

const getValidatedProductItem = (
  validatedItems,
  comparator = () => {},
  nestedComparator = undefined,
) => {
  let validatedItem = validatedItems?.find(comparator);
  if (nestedComparator) validatedItem = validatedItem?.customisations?.find(nestedComparator);
  return validatedItem;
};

/**
 * This is used to commit multiple products at once
 * Currently this is used and formatted in a way to work with reorders
 * You must provide products that have already been formatted to go into cart (see formatProduct)
 */
const commitMany = async ({ setCart, productsCart } = {}) => {
  const newCartItems = setCart(cartItems => [...cartItems, ...productsCart]);
  return newCartItems;
};

/**
 * This is to quickly format a product whether it is of type stage or commit
 * This is also only used with commit many so is targeted to work with it
 */
const formatProduct = ({ createUpdater, setCart, format, reformat, product }) => {
  const update = createUpdater({ set: setCart });

  switch (product.type) {
    case 'stage':
      const stagedFormat = format({ product: product.mappedProduct, isRootProduct: true });
      const stageReformat = reformat({ product: stagedFormat, update, isRootProduct: true });
      return stageReformat;
    case 'commit':
      return format({ product: product.mappedProduct, update, isRootProduct: true });
    default:
      break;
  }
};

const getIsAvailable = (
  validatedItems,
  defaultValue = true,
  comparator = () => {},
  nestedComparator = undefined,
) => {
  const validatedItem = getValidatedProductItem(validatedItems, comparator, nestedComparator);
  return validatedItem?.isAvailable ?? defaultValue;
};

const setValidatedValues = ({
  setCart = () => {},
  validatedOrderPayload = {},
  cart = [],
  formatValidatedValues = () => {},
  showOriginalPriceForCombo = false,
} = {}) => {
  const validatedCart = validatedOrderPayload?.cart || {};
  const validatedItems = validatedCart?.items || [];

  const updateCustomisations = (item, validatedCustomisations = []) => {
    if (!item || !item.customisations) return [];
    if (!validatedCustomisations.length) return item.customisations;

    return item.customisations?.map(customisation => ({
      ...customisation,
      products: customisation?.products?.map(product => {
        const validatedProduct = getValidatedProductItem(
          validatedCustomisations,
          validatedCustomisation =>
            validatedCustomisation?.productId === product?.productId?.toString(),
        );

        // Compare the category ID because, if the same customised product is in different modifier groups and
        // has different prices (e.g., extra cheese), it may result in an incorrect price
        const updatedProduct =
          validatedProduct &&
          Number(validatedProduct?.categoryId) === Number(customisation?.categoryId)
            ? {
                ...product,
                price: {
                  ...product.price,
                  current: validatedProduct?.price,
                },
                isAvailable:
                  validatedProduct?.isAvailable !== undefined
                    ? validatedProduct.isAvailable
                    : product.isAvailable,
              }
            : product;

        return updatedProduct;
      }),
    }));
  };

  const updateOptions = (item, validatedComboProducts = [], comboProductPrice) => {
    if (!item || !item.options) return [];
    if (!validatedComboProducts.length) return item.options;
    return item.options?.map(option => ({
      ...option,
      products: option?.products?.map(product => {
        const validatedProductItem = validatedComboProducts?.find(
          validatedProduct => validatedProduct?.id?.toString() === product?.id?.toString(),
        );

        let unit;

        // "This is to fix the pricing issue where the combo option doesn’t have a price,
        // and the combo product price is calculated as the user selects combo options.
        if (showOriginalPriceForCombo && comboProductPrice === 0) {
          unit =
            validatedProductItem?.price !== undefined
              ? validatedProductItem.originalPrice
              : product?.price?.current;
        } else {
          unit =
            validatedProductItem?.price !== undefined
              ? validatedProductItem.price
              : product?.price?.unit;
        }

        return {
          ...product,
          ...(product.type !== 'customise' && {
            price: {
              ...product.price,
              unit,
            },
          }),
          isAvailable: getIsAvailable(
            validatedComboProducts,
            product?.isAvailable,
            validatedComboProduct => validatedComboProduct?.id === product?.id?.toString(),
          ),
          customisations: product?.customisations?.map(customisation => ({
            ...customisation,
            products: customisation?.products?.map(productItem => {
              const validatedProductItem = getValidatedProductItem(
                validatedComboProducts,
                validatedComboProduct => validatedComboProduct?.id === product?.id?.toString(),
                validatedCustomisation =>
                  validatedCustomisation?.productId === productItem?.productId?.toString(),
              );
              const updatedItem = validatedProductItem
                ? {
                    ...productItem,
                    price: {
                      ...productItem.price,
                      current: validatedProductItem?.price,
                    },
                    isAvailable: validatedProductItem?.isAvailable,
                  }
                : productItem;
              return updatedItem;
            }),
          })),
        };
      }),
    }));
  };

  const formatCartItem = (item, validatedItems) => {
    if (!item || !validatedItems) return item;

    const validatedItem = validatedItems?.find(validatedItem => validatedItem?.uid === item?.uid);
    if (!validatedItem) return item;

    const { price: validatedPrice, isAvailable, product } = validatedItem;
    const unitPrice = product?.price || item?.price?.unit;
    const updatedCustomisations = updateCustomisations(item, product?.customisations);
    const updatedOptions = updateOptions(item, product?.comboProducts, product?.price);

    return formatValidatedValues({
      item,
      price: validatedPrice,
      isAvailable,
      unitPrice,
      customisations: updatedCustomisations,
      options: updatedOptions,
    });
  };

  const formattedCart = Array.isArray(cart)
    ? cart?.map(item => formatCartItem(item, validatedItems))
    : [];

  setCart(formattedCart);
};
export { commit, commitMany, formatProduct, setValidatedValues };
