import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const INTERVAL = 60 * 1000;

const checkRebootTimes = ({ settings, callback }) => {
  const { timezone } = settings || {};
  const currentTime = format(utcToZonedTime(new Date(), timezone), 'HH:mm');
  const rebootTimes = settings?.settings?.interval?.restart?.times || [];

  rebootTimes.forEach(time => {
    if (time === currentTime) callback(true);
  });
};

const setup = ({ settings, callback }) => {
  const run = () => {
    try {
      checkRebootTimes({ settings, callback });
    } catch (err) {
      logger.error('components - reboot - error in setup:', err);
    }
  };

  setInterval(run, INTERVAL);
};

const resolveReboots = ({ setShouldReboot }) => ({ settings }, next) => {
  setup({ settings, callback: setShouldReboot });

  next(null, { settings });
};

export { resolveReboots };
