import axios from 'axios';
import { HTTPClient } from '@fingermarkglobal/protocols';
import { getElavonAuthToken } from './get-elavon-auth-token';
import { executeWithTimeoutCheck } from '../../../utils';

const KIOSK_TIMEOUT = 125000;

export const requestTransaction = async ({
  RequestBuilder,
  context,
  transactionType,
  timeout = KIOSK_TIMEOUT,
}) => {
  const { amount, config, idempotencyKey, referenceNumber } = context;
  const { port } = config;

  try {
    logger.info(`[Elavon] Building ${transactionType} transaction`);

    // Sale, Inquiry, Cancel builders
    const requestBuilder = new RequestBuilder({
      amount,
      config,
      idempotencyKey: idempotencyKey.get(),
      referenceNumber,
    });

    // Hawke token
    const bearerToken = await getElavonAuthToken();
    requestBuilder.setAuthorizationHeader(bearerToken);
    logger.info(`[Elavon] Authorization header set for ${transactionType} transaction`);

    // Get the actual request
    const request = requestBuilder.getRequest();

    let result = { data: '' };
    let response = '';

    if (global.useAxios) {
      logger.info(`[Elavon] Sending ${transactionType} transaction with axios`, { ...request, timeout });
      result = await executeWithTimeoutCheck(() => axios({ ...request, timeout }));
    } else {
      logger.info(`[Elavon] Sending ${transactionType} transaction with HTTPClient`, {
        request,
        port,
        timeout,
      });
      const http = new HTTPClient({ port, config: { timeout } });
      result = await executeWithTimeoutCheck(() => http.send({ payload: request }));
    }
    response = result?.data;

    logger.info(`[Elavon] ${transactionType} transaction response received`, response);

    return response;
  } catch (error) {

    let inquiryFailedMessage;
    if(context.inquiryRetryCount >= 3) {
      inquiryFailedMessage = "Ooops! We couldn't reach out the payment provider to check the payment status. If you've already made the payment, please do not try again and head to the counter to get help."
    }

    const status = error?.response?.status || error.code || 0;
    const errorMessage = inquiryFailedMessage || error?.message || 'Unknown error';

    logger.error(`[Elavon] Error during ${transactionType} transaction`, error);

    throw {
      message: errorMessage,
      status: status,
      error,
    };
  }
};
