import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { localeState } from '@fingermarkglobal/atoms';
import { useTranslation } from 'react-i18next';
import { useSettings } from '@fingermarkglobal/utilities';

const useLocaleSelection = ({ allowedLanguages = [] } = {}) => {
  const { settings } = useSettings();
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useRecoilState(localeState);

  const { languages: languageObject } = settings?.settings || {};

  const languageKeys = Object.keys(languageObject);

  const languages = languageKeys
    .filter(language => language != 'default')
    .filter(language =>
      allowedLanguages.length === 0 ? true : allowedLanguages.includes(language),
    )
    .map(language => {
      const active = i18n?.language?.toLowerCase() === language.toLowerCase();

      const key = language.toLowerCase();
      const label = languageObject[language]?.translation?.languageLabel || language.toUpperCase();

      return {
        key,
        label,
        active,
      };
    });

  const onChangeLanguage = useCallback(
    key => () => {
      setCurrentLanguage(key);
      i18n.changeLanguage(key);
    },
    [i18n, setCurrentLanguage],
  );

  return { t, i18n, languages, currentLanguage, onChangeLanguage };
};

export { useLocaleSelection };
