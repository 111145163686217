// trim cuts off the extra infromation
// from format to be be data for the orders
const round = price => Math.round(price * 100) / 100;

const trim = product => {
  const { productId, price, count, uid, posItemId, isAvailable, parentCategory = '' } = product;

  return {
    price: round(price.current),
    id: `${productId}`,
    quantity: count.current,
    product: trimProduct(product),
    uid,
    posItemId,
    isAvailable,
    parentCategory,
  };
};

const trimProduct = product => {
  const {
    productId,
    type,
    price,
    name,
    providerData = {},
    customisations = [],
    options = [],
    sku = '',
    id,
    isAvailable,
    fees,
  } = product;

  const { text, translations } = name;
  const { unit } = price;

  return {
    type,
    price: round(unit),
    name: text,
    id: `${productId}`,
    providerData,
    comboProducts: options.map(trimOption),
    customisations: customisations.map(trimCustomisation),
    sku: `${sku}`,
    nameTranslations: translations,
    filterId: type === 'combo' ? `${id}` : undefined,
    isAvailable,
    fees,
  };
};

const trimCustomisation = customisation => {
  const {
    productId,
    categoryId,
    name,
    price,
    count,
    providerData,
    default: initial,
    isAvailable,
  } = customisation;
  const { text, translations } = name;

  return {
    price: round(price.current),
    categoryId: `${categoryId}`,
    name: text,
    providerData,
    productId: `${productId}`,
    quantity: count.current,
    defaultQuantity: count.initial,
    default: initial,
    nameTranslations: translations,
    isAvailable,
  };
};

const trimOption = option => {
  const {
    id,
    name,
    price,
    customisations = [],
    providerData,
    type,
    isVisible,
    isAvailable,
    fees,
  } = option;
  const { text, translations } = name;

  return {
    price: round(price.current),
    originalPrice: price.unit,
    id: `${id}`,
    name: text,
    isVisible,
    providerData,
    type,
    customisations: customisations.map(trimCustomisation),
    nameTranslations: translations,
    isAvailable,
    fees,
  };
};

export { trim };
