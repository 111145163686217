import { assign, Machine } from 'xstate';

const populateMessage = assign({
  message: (context, { message }) => {
    const { message: error } = message || {};
    if (error) return error;
    return message;
  },
});

const loadingService = ({ id = 'loadingMachine', initial = 'loading' } = {}) => {
  const loadingMachine = Machine(
    {
      id,
      initial,
      context: {
        message: null,
      },
      states: {
        idle: {
          on: {
            START: 'loading',
          },
        },
        loading: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['populateMessage'],
            },
            FAILURE: {
              target: 'failure',
              actions: ['populateMessage'],
            },
          },
        },
        success: {
          type: 'final',
        },
        failure: {
          on: {
            RESTART: 'loading',
            RESET: 'idle',
          },
        },
      },
    },
    {
      actions: {
        populateMessage,
      },
    },
  );

  return { loadingMachine };
};

export { loadingService };
