
export const inquiryNeeded = [
    { code: 3, friendlymessage: 'Simplify cannot get connected to Fusebox', message: "*SLR COMMUNICATIONS ERROR. Simplify cannot get connected to Fusebox, or PCI Reboot time < 5 minutes" },
    { code: 7, friendlymessage: 'Simplify cannot get connected to Fusebox', message: "*SLR COMMUNICATIONS ERROR. Simplify cannot get connected to Fusebox, or PCI Reboot time < 5 minutes" },
    { code: 88, friendlymessage: 'Host timeout', message: "*SLR SWITCH TIMEOUT. Host timeout" },
    { code: 259, friendlymessage: 'Error condition from invalid input', message: "*SLR Reset. Error condition from invalid input" },
    { code: 265, friendlymessage: 'Declined due to MAC error', message: "*MAC DECLINED. Declined due to MAC error" },
    { code: 253, friendlymessage: 'Processing error', message: "*ICC EMV PROCESSING ERROR. Inquiry" },
    { code: 254, friendlymessage: 'Canceled transaction', message: "*ICC EMV CANCELED TRANS. Inquiry" },
    { code: 255, friendlymessage: 'Card error', message: "*ICC EMV CARD ERROR. Inquiry" },
    { code: 260, friendlymessage: 'EMV undefined status', message: "*ICC EMV UNDEFINED STATUS. Inquiry" }
];

export const inquiryNoNeeded = [
    { code: -7,  friendlymessage: 'No matching records found for the account number', message: "*SLR NO MATCHING RECORDS. An inquiry message (Tran Type 22) was received by Simplify, but Simplify could not find the corresponding Account Number." },
    { code: -16, friendlymessage: 'Cashback amount exceeds the allowed limit', message: "*SLR FINAL $ TOO LRG. Amount of Cash back is over limit" },
    { code: 30, friendlymessage: 'System is busy, processing another request', message: "*SLR BUSY. Simplify is processing another request." },
    { code: 41, friendlymessage: 'Invalid account number', message: "*SLR BAD ACCT NUMBER. Account number failed MOD 10 validation" },
    { code: 47, friendlymessage: 'Invalid card or missing account/track data from chip', message: "*SLR BAD CARD TYPE. Card not valid or no account/track data from EMV chip" },
    { code: 49, friendlymessage: 'Invalid expiration date on the card', message: "*SLR BAD EXPIRATION. Invalid expiration date" },
    { code: 60, friendlymessage: 'Request format is invalid. Contact support for help', message: "*SLR INVALID FORMAT. Request message format is invalid. / *SLR CALL HELP DESK. Voltage error invalidating account data or error in On-Guard process." },
    { code: 81, friendlymessage: 'Customer rejected the surcharge amount', message: "*SLR SURCHARGE REFUSED. Customer rejected surcharge amount." },
    { code: 173, friendlymessage: 'Transaction not allowed in offline mode', message: "*SLR TRAN NOT ALLOWED. Offline not allowed" },
    { code: 174, friendlymessage: 'Account is not eligible for tokenization', message: "*SLR ACCOUNT NOT TOKEN ELIGIBLE. The account number entered as a result of a Token Request is not Token eligible" },
    { code: 208, friendlymessage: 'Transaction cancelled by the user', message: "*SLR CANCEL KEY PRESSED. Either the Cancel key was pressed on the PIN Pad or timeout on PIN entry" },
    { code: 264, friendlymessage: 'Transaction declined', message: "*ICC EMV DECLINED. Decline - No Inquiry needed" },
    { code: 258, friendlymessage: 'Card is still present', message: "*ICC CARD STILL PRESENT. Informational - No Inquiry needed" }
];