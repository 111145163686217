import React from 'react';
import { PrimaryButton } from '../primary-button';
import { SecondaryButton } from '../secondary-button';

const ModalFooter = ({
  action = null,
  actionText = null,
  allowAction = true,
  closeModal = null,
  closeText = null,
  allowClose = true,
  className = '',
  buttonsClassName = '',
  spacer = null,
} = {}) => {
  return (
    <div
      data-test="modal-footer"
      className={`flex flex-wrap md:flex-nowrap p-3 md:p-8 border-t border-solid border-gray-300 rounded-b space-x-4 modal-footer ${className} ${
        spacer ? 'items-center justify-between' : 'items-center justify-end'
      }`}
    >
      {closeModal && (
        <SecondaryButton
          className={`footer-action-button modal-footer-button min-w-24 ${buttonsClassName}`}
          onClick={closeModal}
          disabled={!allowClose}
        >
          {closeText}
        </SecondaryButton>
      )}
      {spacer}
      {action && (
        <PrimaryButton
          className={`footer-action-button modal-footer-button min-w-24 ${buttonsClassName}`}
          onClick={action}
          disabled={!allowAction}
        >
          {actionText}
        </PrimaryButton>
      )}
    </div>
  );
};

export { ModalFooter };
