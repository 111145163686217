import { request, hawkeEndpoint, getJwtToken } from '@fingermarkglobal/request';
import { AuthTokenResponse } from '../../../types';
import { buildGetRequestOptions } from '../../../utils';

export const getElavonAuthToken = async (): Promise<string> => {
  try {
    const timeoutInSeconds = 65;
    const endpoint = `${hawkeEndpoint}/elavon/credentials/token`;
    const token = await getJwtToken();

    if (!token) {
      const errorMessage = '[Elavon] Failed to generate Hawke JWT token';
      logger.error(errorMessage);
      throw new Error(errorMessage);
    }

    logger.info('[Elavon] Requesting Elavon auth token');
    const options = buildGetRequestOptions({
      endpoint,
      token,
      timeoutInSeconds,
    });
    logger.info('[Elavon] Sending auth token request with options', options);
    const response = await request.get(endpoint, options).json<AuthTokenResponse>();

    if (response?.accessToken) {
      logger.info('[Elavon] Successfully received auth token');
    } else {
      logger.error('[Elavon] No access token received from auth response', response);
      return;
    }

    return response.accessToken;
  } catch (error) {
    const msg = '[Elavon] Error during auth token request';
    logger.error(msg, error);
    const errorMessage = `${msg}: ${error}`;
    throw new Error(errorMessage);
  }
};