import React from 'react';
import { Trans } from 'react-i18next';
import { ProductCustomisationsReadonly } from '../product-customisations-readonly';
import { signaledFormatPrice } from '@fingermarkglobal/utilities';
import { getProductNameAccToLang } from '../../../utilities/misc';
import { useLocaleSelection } from '../../../hooks/generic/locale-selection';

const Products = ({
  products = [],
  t = () => {},
  formatPrice = () => {},
  showAddonAvailability,
  hideUnavailable = false,
  hideNoPrefixWhenDefaultNotSelected = false,
} = {}) => {
  const { currentLanguage } = useLocaleSelection();
  return products.map(product => {
    const { id, name, selected, customisations, price, title, isAvailable } = product;
    const { unit } = price;
    const optionPrice = unit ? ` ${signaledFormatPrice({ formatPrice, price: unit })}` : '';

    const productLanguageText = getProductNameAccToLang(name, currentLanguage);

    return (
      selected && (
        <div key={`product-${id}`}>
          {showAddonAvailability && !isAvailable ? (
            <div className="flex justify-between text-2xl font-bold text-gray-400 md:text-3xl">
              <p data-test="product-option-name">
                {title || productLanguageText || name.text} {optionPrice}
              </p>
              <p className="text-red-600" data-test="product-option-name-unavailable">
                <Trans i18nKey="cartProductUnavailableItem" />
              </p>
            </div>
          ) : (
            <p className="text-2xl font-bold md:text-3xl" data-test="product-option-name">
              {title || productLanguageText || name.text} {optionPrice}
            </p>
          )}
          <ProductCustomisationsReadonly
            customisations={customisations}
            t={t}
            className="pl-4 text-2xl md:text-3xl"
            formatPrice={formatPrice}
            showAddonAvailability={showAddonAvailability}
            hideUnavailable={hideUnavailable}
            hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
          />
        </div>
      )
    );
  });
};

const ProductOption = ({
  options = [],
  t = () => {},
  formatPrice = () => {},
  showAddonAvailability,
  hideUnavailable = false,
  hideNoPrefixWhenDefaultNotSelected = false,
} = {}) =>
  options
    .map(option => {
      const { id, products, hidden = false, isVisible = true } = option;

      if (hidden || !isVisible) return null;

      return (
        <div key={`option-${id}`}>
          <Products
            products={products}
            t={t}
            formatPrice={formatPrice}
            showAddonAvailability={showAddonAvailability}
            hideUnavailable={hideUnavailable}
            hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
          />
        </div>
      );
    })
    .filter(option => option); // filter `null`s

const ProductOptionsReadonly = ({
  options = [],
  t = () => {},
  className = '',
  formatPrice = () => {},
  showAddonAvailability = false,
  hideUnavailable = false,
  hideNoPrefixWhenDefaultNotSelected = false,
} = {}) => {
  return (
    <div data-test="product-options-readonly" className={className}>
      <ProductOption
        options={options}
        t={t}
        formatPrice={formatPrice}
        showAddonAvailability={showAddonAvailability}
        hideUnavailable={hideUnavailable}
        hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
      />
    </div>
  );
};

export { ProductOptionsReadonly };
