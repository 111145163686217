import { assign } from 'xstate';

const createPayload = assign((context, event) => {
  return event;
});

const handleRefund = assign((context, event) => ({ ...context, ...event.data }));

/**
 * This will only return failed state as Verifone doesn't allow refunds
 */
const executeRefund = () =>
  new Promise((resolve, reject) => {
    const data = {
      response: {},
      result: 'notallowed',
      errorCondition: 'This pinpad does not allow refunds',
    };

    reject(data);
  });

export { createPayload, handleRefund, executeRefund };
