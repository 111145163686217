import { useState } from 'react';
import { useAccessibility } from '../../accessibility/hook';
import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';
import { usePrice } from '../../../../hooks/generic/utilities/price';
import { useCancelOrder } from '../../../../hooks/restaurant/order/useCancelOrder';

const useCancelOrderButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useLocaleSelection();
  const { formatPrice } = usePrice();
  const { cancelOrder } = useCancelOrder();

  const { accessibilityEnabled } = useAccessibility();

  const modalAccessibilityClass = accessibilityEnabled
    ? 'h-half bottom-0 left-0 right-0'
    : 'inset-0';

  const open = () => setShowModal(true);
  const close = () => setShowModal(false);
  return {
    t,
    confirm: cancelOrder,
    close,
    showModal,
    open,
    modalAccessibilityClass,
    formatPrice,
  };
};

export { useCancelOrderButton };
