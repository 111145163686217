import { TransType } from '../../../types';
import { responseCodeHandler } from '../../response-code-handler';

export const destructInquiryTransaction = (response = null, context: any) => {
  if (!response) {
    throw new Error('Response object is required');
  }

  logger.info(`[Elavon] Destructuring inquiry transaction`);

  const { message } = response || {};
  const { identifiers = {}, response: resp = {}, card = {} } = message || {};
  const { transId = '' } = identifiers;
  const { authorizationCode: authCode = '', responseCode = '', responseText = '', rawData = '' } = resp;
  const { maskedPAN: cardNumber = '', cardType = '' } = card;
  const { status, friendlymessage } = responseCodeHandler(responseCode, TransType.Inquiry);
  const { amount } = context;

  const info: any = {
    paymentType: 'CARD',
    transactionId: transId,
    rawResponse: rawData,
    authCode,
    cardNumber,
    cardType,
    amount,
  };

  if (responseCode) {
    info.result = responseCode;
  }

  const result = {
    paymentType: 'CARD',
    status,
    info,
    message: responseText || friendlymessage,
  };

  logger.info('[Elavon] Destructuring inquiry result', result);
  return result;
};
