import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { compact, isNumber } from 'lodash';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useAnalytics } from '@fingermarkglobal/jitsu';
import { formattedSettingsState, currentCategoryState } from '@fingermarkglobal/atoms';

import { useOffersAndRewardsMenu, useRestaurantMenu } from '../../../../hooks/restaurant/menu';
import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';

import { translateProductName } from '../../../../utilities/product/translation';
import {
  filterByTimeRestrictions,
  getHydratedProductsFromCategory,
} from '../../../../utilities/product';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useCategoriesHeader = ({ offersAndRewardsCategories = [] } = {}) => {
  const history = useHistory();
  const sliderContainer = useRef(null);

  const flags = useFlags();
  const { id } = useParams();
  const routeMatch = useRouteMatch();

  const { categories, settings, products } = useRestaurantMenu();
  const { i18n } = useLocaleSelection();

  const [childJumpCount, setChildJumpCount] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(id);

  const [userSelected, setUserSelected] = useState(false);
  const kioskSettings = useRecoilValue(formattedSettingsState);
  const setCurrentCategory = useSetRecoilState(currentCategoryState);

  const { offersAndRewards } = useOffersAndRewardsMenu();
  const { setViewItemListTrack } = useAnalytics();

  const { current: container } = sliderContainer || {};
  const { clientWidth: containerWidth = 0, firstChild: child, childElementCount: childCount = 0 } =
    container || {};
  const { clientWidth: childWidth = 1 } = child || {};
  const { language } = i18n || {};

  const translateInline = translateProductName(language);

  const childJumpRange = Math.floor(containerWidth / childWidth);
  const childJumpMaxCount = Math.ceil(childCount / childJumpRange);

  const hasChildLeftJump = childJumpCount > 0;
  const hasChildRightJump = childJumpCount + 1 < childJumpMaxCount;

  const scrollRight = () => {
    setUserSelected(true);
    const nextChildJumpCount = Math.min(childJumpCount + 1, childJumpMaxCount - 1);
    setChildJumpCount(nextChildJumpCount);
    container.scroll({
      left: nextChildJumpCount * childJumpRange * childWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    setUserSelected(true);
    const nextChildJumpCount = Math.max(childJumpCount - 1, 0);
    setChildJumpCount(nextChildJumpCount);
    container.scroll({
      left: nextChildJumpCount * childJumpRange * childWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (id) {
      const parsed = Number(id);
      if (isNumber(parsed)) {
        setSelectedCategoryId(parsed);
      } else {
        setSelectedCategoryId(id);
      }
    }
  }, [id]);

  // scroll the slider to the selected category
  useEffect(() => {
    const container = sliderContainer.current;
    if (!container || userSelected) return;

    const selectedItemIndex = formattedCategories.findIndex(category => category.isSelected);
    if (selectedItemIndex !== -1) {
      const containerWidth = container.clientWidth;
      const itemWidth = container.scrollWidth / formattedCategories.length;
      const selectedItemOffset = selectedItemIndex * itemWidth;
      const containerScrollLeft = container.scrollLeft;
      const itemOffsetRelativeToContainer = selectedItemOffset - containerScrollLeft;

      if (
        itemOffsetRelativeToContainer < 0 ||
        itemOffsetRelativeToContainer + itemWidth > containerWidth
      ) {
        const scrollPosition = Math.min(selectedItemOffset, container.scrollWidth - containerWidth);
        container.scroll({
          left: scrollPosition,
          behavior: 'smooth',
        });
        setChildJumpCount(Math.floor(selectedItemOffset / (childJumpRange * childWidth)));
      }
    }
  }, [selectedCategoryId, formattedCategories, userSelected, childJumpRange, childWidth]);

  const categoriesWithOffersAndRewards = compact(offersAndRewardsCategories).map(item => ({
    ...item,
    isSelected: item.path === routeMatch.path,
    onClick: () => {
      setSelectedCategoryId(item.id);
      history.push(item.path);
    },
  }));

  const formattedCategories = categories
    ?.filter(filterByTimeRestrictions({ settings, flags }))
    .filter(category => {
      const { parent } = category;
      // Filter item which is 0 (false)
      return (
        parent.filter(item => {
          return item === 0;
        }).length > 0
      );
    })
    .map(category => {
      const { id, name = {}, imageUrl = '', children } = category;
      return {
        imageUrl,
        title: translateInline(name),
        isSelected: `${id}` === `${selectedCategoryId}`,
        onClick: () => {
          setSelectedCategoryId(id);
          setCurrentCategory(name?.text);

          const productsFromCategory = getHydratedProductsFromCategory({
            category,
            id,
            hydratedProducts: products,
            offersAndRewards,
          });

          setViewItemListTrack({
            deviceSettings: kioskSettings.kiosk,
            products: productsFromCategory,
            overrides: {
              item_id: id,
              item_list_name: name?.text,
            },
          });

          return children?.length > 0
            ? history.push(`/categories/${id}`)
            : history.push(`/category/${id}`);
        },
      };
    });

  return {
    formattedCategories: [...categoriesWithOffersAndRewards, ...formattedCategories],
    sliderContainer,
    hasChildLeftJump,
    hasChildRightJump,
    selectedCategoryId,
    userSelected,
    scrollRight,
    scrollLeft,
  };
};

export { useCategoriesHeader };
