/* eslint-disable max-lines */
import { useCallback, useEffect, useState } from 'react';
import { addDays, format, isWithinInterval, setHours, setMinutes, subDays } from 'date-fns';
import { settingsState } from '@fingermarkglobal/atoms';
import { useSettings } from '@fingermarkglobal/utilities';
import { useSystem } from '../../generic/system';
import { utcToZonedTime } from 'date-fns-tz';

let checkScheduleLockInterval = null;

const useRestaurantLock = () => {
  const [kioskClosed, setKioskClosed] = useState(false);
  const [scheduleLock, setScheduleLock] = useState(false);
  const [scheduleUnlock, setScheduleUnlock] = useState(false);

  const { settings: rootSettings } = useSettings(settingsState);
  const {
    lock,
    unlock,
    lockMachineState,
    lockMessageRecoilState,
    setLockMessageRecoilState,
  } = useSystem();

  const { settings, timezone } = rootSettings || {};
  const { site } = settings || {};
  const { isKioskClosed = false, workingHours = [] } = site || {};
  const INTERVAL = 60 * 1000;

  const autoLock = useCallback(message => lock({ state: 'AUTO_LOCK', message }), [lock]);
  const manualLock = useCallback(message => lock({ state: 'MANUAL_LOCK', message }), [lock]);
  const autoUnlock = useCallback(() => unlock({ state: 'AUTO_UNLOCK' }), [unlock]);
  const manualUnlock = useCallback(() => unlock({ state: 'MANUAL_UNLOCK', redirect: false }), [
    unlock,
  ]);

  const formatRangeDates = useCallback(({ workingHours, baseDate } = {}) => {
    const todaysSchedule = workingHours?.find(item => {
      const { weekday } = item;
      return weekday === format(baseDate, 'EEE').toLocaleLowerCase();
    });

    const { to, from } = todaysSchedule || {};
    const { hour: hourFrom = 0, minute: minuteFrom = 0 } = from || {};
    const { hour: hourTo = 23, minute: minuteTo = 59 } = to || {};

    // if the "to" date is lower than the "from" one we should consider the "to" time in future
    // i.e: `{ hourFrom: 10, hourTo: 1 }` we should consider from current day 10am to next day 1am.
    const formattedFromInMinutes = hourFrom * 60 + minuteFrom;
    const formattedToInMinutes = hourTo * 60 + minuteTo;
    const formattedBaseDateTo =
      formattedToInMinutes < formattedFromInMinutes ? addDays(baseDate, 1) : baseDate;

    const fromDate = setMinutes(setHours(baseDate, hourFrom), minuteFrom);
    const toDate = setMinutes(setHours(formattedBaseDateTo, hourTo), minuteTo);

    return { fromDate, toDate };
  }, []);

  const isWithinWorkingHours = useCallback(() => {
    const currentDate = new Date();

    const previousDayBaseDate = subDays(utcToZonedTime(currentDate, timezone), 1);
    const { fromDate: previousDayFromDate, toDate: previousDayToDate } = formatRangeDates({
      workingHours,
      baseDate: previousDayBaseDate,
    });

    const currentDayBaseDate = utcToZonedTime(currentDate, timezone);

    const { fromDate: currentDayFromDate, toDate: currentDayToDate } = formatRangeDates({
      workingHours,
      baseDate: currentDayBaseDate,
    });

    // check if current date is within the working hours range defined on both previous and current day
    // why? previous weekday times can overflow to the current weekday
    const withinInterval =
      isWithinInterval(currentDayBaseDate, {
        start: previousDayFromDate,
        end: previousDayToDate,
      }) ||
      isWithinInterval(currentDayBaseDate, {
        start: currentDayFromDate,
        end: currentDayToDate,
      });

    return withinInterval;
  }, [formatRangeDates, timezone, workingHours]);

  const checkScheduleLock = useCallback(() => {
    setKioskClosed(isKioskClosed || false);

    const withinInterval = isWithinWorkingHours();

    setScheduleLock(!withinInterval);
    setScheduleUnlock(withinInterval);
  }, [isWithinWorkingHours, isKioskClosed]);

  useEffect(() => {
    if (!checkScheduleLockInterval) {
      checkScheduleLockInterval = setInterval(() => {
        try {
          logger.debug('Components - useRestaurantLock - starting checkScheduleLock');

          checkScheduleLock();
        } catch (err) {
          logger.error('Components - useRestaurantLock - checkScheduleLock - error:', err);
        }
      }, INTERVAL);
    }

    return () => {
      clearInterval(checkScheduleLockInterval);
      checkScheduleLockInterval = null;
    };
  }, [INTERVAL, checkScheduleLock]);

  return {
    isWithinWorkingHours,
    kioskClosed,
    scheduleLock,
    autoLock,
    manualLock,
    autoUnlock,
    manualUnlock,
    lockMachineState,
    scheduleUnlock,
    lockMessageRecoilState,
    setLockMessageRecoilState,
    checkScheduleLock,
  };
};
export { useRestaurantLock };
