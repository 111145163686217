import { PrimaryButton } from '../../stateless/primary-button';
import { orderBy } from 'lodash';
import React from 'react';

function formatSimpleDate(dateString) {
  const options = { month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
}

function formatItemName({ name, quantity }) {
  return `${name} (x${quantity})`;
}

export const DefaultOrderHistoryCard = ({ t, order, canGetOrder, onAddToOrder }) => {
  const items = orderBy(order.items, 'price', 'desc');
  return (
    <>
      <div className="flex flex-col items-start justify-between shadow-lg rounded-sm p-6 w-full h-full focus:outline-none font-card">
        <h3 className="text-3xl font-bold">{formatSimpleDate(order.timestamp)}</h3>

        {items.slice(0, 2).map((item, index) => (
          <p key={index} className="font-sans text-xl font-normal">
            {formatItemName({ name: item.name, quantity: item.quantity })}
          </p>
        ))}

        {items.length === 3 && (
          <p className="font-sans text-xl font-normal">
            {formatItemName({ name: items[2].name, quantity: items[2].quantity })}
          </p>
        )}

        {items.length > 3 && (
          <div className="flex justify-between w-full items-center">
            <p className="font-sans text-xl font-normal">
              {formatItemName({ name: items[2].name, quantity: items[2].quantity })}
            </p>
            <p className="font-sans text-xl font-normal">...</p>
          </div>
        )}

        {/* TODO FIX THIS TO HAVE ITS OWN BUTTON STYLE */}
        <div className="flex items-center justify-center w-full mt-4">
          <PrimaryButton
            className="px-6 py-5"
            onClick={() => onAddToOrder(order)}
            disabled={!canGetOrder}
          >
            {t('loyaltyReorderViewOrder')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
