const shouldDisableAdd = ({
  categoryMax,
  categoryAutoBalance,
  itemMax,
  currentItemQuantity,
  categoryTotal,
  isBag,
  isOffer,
} = {}) => {
  if (isBag) {
    return true;
  }
  if (isOffer) return true;
  const isMaxItem = itemMax !== -1 && currentItemQuantity >= itemMax;

  if (categoryAutoBalance) return isMaxItem;

  const isMaxCategory = categoryMax && categoryMax !== -1 && categoryTotal >= categoryMax;

  return isMaxCategory || isMaxItem;
};

const shouldDisableRemove = ({ currentItemQuantity, itemMin, isRootProduct, name, isBag } = {}) => {
  if (isBag) {
    return true;
  }

  const formattedMin = isRootProduct ? 1 : itemMin;

  const isMinItem = currentItemQuantity <= formattedMin;

  return isMinItem;
};

const evaluateDisabledActions = ({
  categoryAutoBalance,
  currentItemQuantity,
  itemMin,
  itemMax,
  isRootProduct,
  categoryMax,
  categoryTotal,
  isBag,
  isOffer,
} = {}) => {
  return {
    removeDisabled: shouldDisableRemove({
      currentItemQuantity,
      isRootProduct,
      itemMin,
      isBag,
    }),
    addDisabled: shouldDisableAdd({
      categoryMax,
      categoryAutoBalance,
      itemMax,
      currentItemQuantity,
      categoryTotal,
      isBag,
      isOffer,
    }),
  };
};

export { evaluateDisabledActions };
