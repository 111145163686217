import { TransType } from '../../../types';
import { responseCodeHandler } from '../../response-code-handler';

export const destructSaleTransaction = (response = null, context: any) => {
  if (!response) {
    throw new Error('Response object is required');
  }

  logger.info(`[Elavon] Destructuring sale transaction`);

  const { message } = response || {};
  const { identifiers = {}, transAmount = '0.00', card = {}, response: resp = {} } = message || {};

  const { transId = '' } = identifiers;
  const { authorizationCode: authCode = '', responseText = '', responseCode = '' } = resp;
  const { maskedPAN: cardNumber = '', cardType = '' } = card;

  const { status, friendlymessage } = responseCodeHandler(responseCode, TransType.Sale);
  const { amount } = context;
  // TODO: Check if we need to perform any conversions here ?
  // const amountInCents = Math.round(parseFloat(transAmount) * 100);
  const info = {
    paymentType: 'CARD',
    transactionId: transId,
    amount,
    paymentReceipt: '',
    cardNumber,
    cardType,
    rawResponse: JSON.stringify(response),
    authCode,
    result: responseText || responseCode,
  };

  const result = {
    paymentType: 'CARD',
    amount: transAmount,
    status,
    info,
    message: responseText || friendlymessage,
  };
  logger.info(`[Elavon] Destructuring sale result`, result);
  return result;
};
