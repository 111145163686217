import React from 'react';
import Plus from 'heroicons/solid/plus.svg';
import Minus from 'heroicons/solid/minus.svg';

const QuantityControllerButtons = ({
  product,
  className = '',
  disableModifyUpsellOnCart = false,
  onQtyUpdate = () => {},
}) => {
  const {
    add = () => {},
    remove = () => {},
    count = {},
    addDisabled = false,
    removeDisabled = false,
    isAvailable = true,
    isUpsell = false,
  } = product || {};

  const disabledButtonForUpsell = disableModifyUpsellOnCart && isUpsell;

  return (
    <div
      data-test="quantity-controller-buttons"
      className={`flex space-x-1 md:space-x-3 items-center mr-0 xs:mr-2 md:mr-5 ${className}`}
    >
      <button
        data-test="button-product-quantity-remove"
        className="flex items-center justify-center p-1 border border-black rounded-full xs:p-2 disabled:opacity-50 focus:outline-none"
        onClick={e => {
          remove(e);
          onQtyUpdate(-1);
        }}
        disabled={!isAvailable || removeDisabled || disabledButtonForUpsell}
      >
        <Minus className="w-4 h-4 md:w-8 md:h-8" />
      </button>
      <div
        data-test="product-quantity-count"
        className="w-2 py-2 text-xl text-center xs:text-3xl xs:w-6 md:w-12"
      >
        {count.current}
      </div>
      <button
        data-test="button-product-quantity-add"
        className="flex items-center justify-center p-1 border border-black rounded-full xs:p-2 disabled:opacity-50 focus:outline-none"
        onClick={e => {
          add(e);
          onQtyUpdate(1);
        }}
        disabled={!isAvailable || addDisabled || disabledButtonForUpsell}
      >
        <Plus className="w-4 h-4 md:w-8 md:h-8" />
      </button>
    </div>
  );
};

export { QuantityControllerButtons };
