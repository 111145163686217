import { pingHealthService, checkOnlineStatus, reportKioskVersion } from '@fingermarkglobal/health';

const HEALTH_LOG_TAG = '[Health] ';

const setupPingHealthService = ({ serial, settings }) => {
  const INTERVAL = process.env.POI_APP_HEALTH_TASKS_INTERVAL || 120;
  setInterval(async () => await pingHealthService({ serial, settings }), INTERVAL * 1000);
};

const setupCheckOnlineStatus = ({ setOnlineStatus, serial }) => {
  const INTERVAL = process.env.POI_APP_CHECK_ONLINE_STATUS_INTERVAL || 5;
  const MAX_NUMBER_OF_ATTEMPTS = process.env.POI_APP_CHECK_ONLINE_STATUS_MAX_ATTEMPTS || 10;

  const run = async () => {
    const isOnline = await checkOnlineStatus();

    setOnlineStatus(currentOnlineStatus => {
      const isInlineTimedOut = !isOnline
        ? currentOnlineStatus?.numberOfAttempts >= MAX_NUMBER_OF_ATTEMPTS
        : false;
      const numberOfAttempts = !isOnline ? currentOnlineStatus?.numberOfAttempts + 1 : 0;

      if (
        isOnline !== currentOnlineStatus.isOnline ||
        isInlineTimedOut !== currentOnlineStatus.isInlineTimedOut ||
        numberOfAttempts !== currentOnlineStatus.numberOfAttempts
      ) {
        // first offline detected from an online status, log error
        if (!isOnline && numberOfAttempts <= 1) {
          logger.error(`${HEALTH_LOG_TAG} - ${serial} - is offline`);
        }

        if (isOnline) {
          logger.info(`${HEALTH_LOG_TAG} - ${serial} - is online`);
        }

        return {
          isOnline,
          isInlineTimedOut,
          numberOfAttempts,
        };
      }

      // skip update if nothing changed (e.g "yep, still online ...")
      return currentOnlineStatus;
    });

    setTimeout(run, INTERVAL * 1000);
  };

  setTimeout(run, INTERVAL * 1000);
};

const setupReportKioskVersion = ({ serial, settings }) =>
  reportKioskVersion({ serial, settings }).catch(err =>
    logger.error(
      `setupReportKioskVersion() - error:`,
      JSON.stringify(err, Object.getOwnPropertyNames(err)),
    ),
  );

const resolveMonitoring = ({ serial, setOnlineStatus }) => ({ settings }, next) => {
  setupPingHealthService({ settings, serial });
  setupCheckOnlineStatus({ setOnlineStatus, serial });
  setupReportKioskVersion({ serial, settings });
  next(null, { serial, settings });
};

export { resolveMonitoring };
