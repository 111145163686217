import { PaymentContext, TransType } from '../../../types';
import { responseCodeHandler } from '../../response-code-handler';

export const destructCancelTransaction = (response = null, context: any) => {
  if (!response) {
    throw new Error('Response object is required');
  }

  logger.info(`[Elavon] Destructuring cancel transaction`);

  const { message } = response || {};
  const { response: resp = {}, identifiers = {} } = message || {};
  const { transId = '' } = identifiers;
  const { responseCode = '', rawData = '', responseText = '' } = resp;
  const { status, friendlymessage } = responseCodeHandler(responseCode, TransType.Cancel);
  const { amount } = context;

  const info: any = {
    transactionId: transId,
    paymentType: 'CARD',
    rawResponse: rawData,
    amount,
  };
  if (responseCode) {
    info.result = responseCode;
  }
  const result = {
    paymentType: 'CARD',
    status,
    info,
    message: responseText || friendlymessage,
  };

  logger.info('[Elavon] Destructuring cancel result', result);
  return result;
};