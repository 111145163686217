export const parseTransactionAmout = (amount: string | number) => {
    if (typeof amount === 'number') {
        amount = String(amount);
    }
    const amountArray = amount.split('.');
    const decimals = amountArray[1];
    if (!decimals) {
        amount = `${amountArray[0]}.00`
    } else if (decimals.length <= 1) {
        amount = `${amountArray[0]}.${decimals}0`;
    }
    return amount;
}