import { Transactions, Characters } from '../../utils/enums';
import {
  lrcBuilder,
  getMessageLengthBytes,
  getAmountBytes,
  getAppIdBytes,
  getEcrPrintingAreaBytes,
  getEcrRefNumberBytes,
  getTransactionBytes,
} from '../../utils/helpers';

const createPaymentRequest = ({ amount, ecrNumber }) => {
  const transaction = Transactions.PURCHASE;

  const message =
    getTransactionBytes(transaction) +
    getAmountBytes(amount) +
    getEcrPrintingAreaBytes(1) +
    getEcrRefNumberBytes(ecrNumber) +
    getAppIdBytes();

  const length = getMessageLengthBytes(message);
  const lrc = lrcBuilder(length + message);

  return Characters.SOH + length + message + lrc;
};

export { createPaymentRequest };
