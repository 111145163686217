import { useRecoilState } from 'recoil';
import { metadataState } from '@fingermarkglobal/atoms';

const useMetadata = () => {
  const [metadata, setMetadata] = useRecoilState(metadataState);

  return { metadata, setMetadata };
};

export { useMetadata };
