import React from 'react';

import { SecondaryButton } from '../../stateless/secondary-button';

const EMPTY_FUNC = () => {};

const DefaultMemberTitleComponent = ({ t, loyaltyUser: { points, name } }) => (
  <p data-test="loyalty-banner-member" className="loyalty-banner-text-wrapper-member">
    {t('loyaltyBannerMember', { totalPoints: points, name })}
  </p>
);

export const LoggedIn = ({
  loyaltyUser,
  MemberTitleComponent = DefaultMemberTitleComponent,
  hasOffersPageLink,
  onSignOut = EMPTY_FUNC,
  computedAction = EMPTY_FUNC,
  t,
}) => {
  return (
    <>
      <div data-test="loyalty-banner-logged-in" className="loyalty-banner">
        <div className="loyalty-banner-text-wrapper">
          <MemberTitleComponent t={t} loyaltyUser={loyaltyUser} />
        </div>
        <div className="loyalty-banner-button-wrapper">
          {hasOffersPageLink && (
            <SecondaryButton
              className="loyalty-banner-button-wrapper-offers"
              data-test="loyalty-banner-offers"
              onClick={() => computedAction('/loyalty-offers')}
            >
              {`${t('loyaltyBannerOffers')}`}
            </SecondaryButton>
          )}
          <SecondaryButton
            className="loyalty-banner-button-wrapper-logout"
            data-test="loyalty-banner-logout"
            onClick={onSignOut}
          >
            {`${t('loyaltyBannerLogout')}`}
          </SecondaryButton>
        </div>
      </div>
    </>
  );
};
