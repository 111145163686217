// https://stackoverflow.com/questions/46993591/react-why-label-not-firing-onchange-for-checkbox
export const preventEventBubble = event => {
  event.stopPropagation();
};

const formatLanguage = lang => {
  switch (lang) {
    case 'en-US':
    case 'en':
      return 'eng';
    case 'ar-AE':
      return 'uae';
    case 'vnm':
      return 'vnm';
    case 'rus':
      return 'rus';
    case 'kaz':
      return 'kaz';
    case 'tha':
      return 'tha';
    case 'fr-CA':
    case 'DN-FR':
      return 'fr-CA';
    default:
      return 'eng';
  }
};

export const getProductNameAccToLang = (name, currentLanguage) => {
  const productLanguageText = name?.translations?.find(
    value => value?.language === formatLanguage(currentLanguage),
  );
  return productLanguageText?.text;
};
