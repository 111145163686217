import { assign } from 'xstate';
import { PaymentContext } from '../../../types';
import merge from 'lodash.merge';

function getDebugElavonMachineFlag() {
  try {
    return process.env.POI_APP_DEBUG_ELAVON_MACHINE == 'true';
  } catch (error) {
    return false;
  }
}

export const log = (...args) => {
  if (getDebugElavonMachineFlag()) {
    console.log('[ELAVON-MACHINE]', ...args);
  }
};

export const logStateEntry = (stateName: string) => {
  return () => log(`Entering state: ${stateName}`);
};

export const assignAndLog = assignment => {
  return assign((context: PaymentContext, event: any) => {
    const mergedContext = merge({}, context, event?.data || {});
    const updatedContext = assignment(mergedContext, event);
    log(`Context updated via assign. New context:`, updatedContext);
    return updatedContext;
  });
};
