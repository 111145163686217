import React from 'react';

export const CustomModalHeader = ({ className, title }) => {
  return (
    <div
      data-test="modal-header"
      className={`flex items-start justify-center p-3 md:p-8 border-b border-solid border-gray-200 rounded-t modal-text ${className}`}
    >
      <h3 className="text-2xl md:text-5xl font-bold font-header modal-header">{title}</h3>
    </div>
  );
};
