import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { config, amount } = context || {};

  const rawResponse = event?.data || {};

  const transactionId = rawResponse.retrievalReferenceNumber?.value;
  const emvTagsIssuer = rawResponse.emvTagsIssuer.value.split('|');
  const [cardType, , aid] = emvTagsIssuer;

  const info = {
    transactionId,
    cardNumber: rawResponse.primaryAccountNumber?.value,
    cardType,
    amount: parseFloat(amount),
    authCode: rawResponse.transactionAuthCode?.value,
    paymentType: 'CARD',
    merchantId: rawResponse.cardAcceptorIdCode?.value,
    aid,
    terminalId: rawResponse.cardAcceptorTerminalId?.value,
    response: rawResponse || {},
  };

  const response = {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    info,
    message: '',
  };

  return response;
});

export { handlePayment };
