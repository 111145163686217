import { HTTPError, TimeoutError } from '@fingermarkglobal/request';
import ky from 'ky';
import { getOrderSummaries } from '../../order/requests/get-order-summaries';

interface LoyaltyOrderHistoryProps {
  token: string;
  organisationId?: string;
}

type LoyaltyServiceResponse = { items: Array<{ sessionId: string; createdAt: string }> };

const loyaltyOrderHistory = async ({ token, organisationId }: LoyaltyOrderHistoryProps) => {
  console.info('fm-api - loyaltyOrderHistory - started');

  try {
    const endpoint = `${process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT}/loyalty/transaction`;
    if (!process.env.POI_APP_LOYALTY_SERVICE_ENDPOINT) {
      throw new Error(
        'fm-api - loyaltyOrderHistory - failed to get loyalty logout endpoint in the environment file with the key POI_APP_LOYALTY_SERVICE_ENDPOINT.',
      );
    }

    const response = await ky.get(endpoint, {
      timeout: 30 * 1000,
      headers: { 'x-authorization-loyalty': `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error('fm-api - loyaltyOrderHistory - request failed. No response received');
    }

    const json: LoyaltyServiceResponse = await response.json();

    const sessionIds = json.items.map(x => x.sessionId);

    if (sessionIds.length === 0) {
      return [];
    }

    // Now get a summary for each of the requests
    const orderSummaries = await getOrderSummaries({ organisationId, sessionIds });
    console.info('fm-api - loyaltyOrderHistory - finished', response);
    return orderSummaries;
  } catch (err) {
    if (err instanceof HTTPError || err instanceof TimeoutError) throw err;

    console.error('fm-api - loyaltyOrderHistory - error:', err);
    throw err;
  }
};

export { loyaltyOrderHistory };
