import Pusher from 'pusher-js';
import {
  handleReboot,
  handleScreenshot,
  handleLock,
  handleUnlock,
  handleReload,
  handleSyncMenu,
} from './handlers';

const pusher = new Pusher(process.env.POI_APP_PUSHER_ID, {
  cluster: 'ap1',
});

const resolveCommands = ({
  lock,
  unlock,
  reboot,
  screenshot,
  serial,
  reload,
  syncMenu,
}) => next => {
  try {
    const channel = pusher.subscribe(`${serial}`);

    const callback = payload => {
      // Client event for receiving callback data
      channel.trigger(`client-${serial}`, payload);
    };

    // All channel events and handlers
    const events = [
      { name: 'REBOOT', handler: handleReboot({ handler: reboot, callback }) },
      { name: 'SCREENSHOT', handler: handleScreenshot({ handler: screenshot, callback }) },
      { name: 'LOCK', handler: handleLock({ handler: lock, callback }) },
      { name: 'UNLOCK', handler: handleUnlock({ handler: unlock, callback }) },
      { name: 'RELOAD', handler: handleReload({ handler: reload, callback }) },
      { name: 'SYNC_MENU', handler: handleSyncMenu({ handler: syncMenu, callback }) },
    ];

    events.forEach(e => channel.bind(e.name, e.handler));

    next(null);
  } catch (error) {
    next(error);
  }
};

export { resolveCommands };
