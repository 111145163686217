import React from 'react';
import { AccessibilityWrapper, AccessibilityButton } from '../../components/stateful/accessibility';
import { TimeoutModal } from '../../components/stateful/timeout-modal';
import { Footer } from '../../components/stateless/footer';
import { Header } from '../../components/stateless/header';
import { useTemplate } from './hook';

const DefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  templateOverride = false,
  accessibilityEnabled = true,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    isTimeoutEnabled,
    timeoutActionOverride,
    showAccessibilityButton,
    isOnline,
    isCouponEnabled,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
    accessibilityActionEnabled: accessibilityEnabled,
    footerEnabled,
  });

  return (
    <AccessibilityWrapper isAccessibilityButtonEnabled={showAccessibilityButton}>
      <div className="flex flex-col w-screen h-full">
        {headerEnabled && <Header {...header} isCouponEnabled={isCouponEnabled} />}
        <div className="flex-grow overflow-auto">{children}</div>
        {footerEnabled && <Footer {...footer} isCouponEnabled={isCouponEnabled} />}
        {showAccessibilityButton && (
          <div className="flex flex-col items-start justify-center">
            <AccessibilityButton className="absolute bottom-0 block w-24 h-24 p-2 m-8 shadow-lg" />
          </div>
        )}
        {isTimeoutEnabled && isOnline && (
          <TimeoutModal
            onTimeout={timeoutActionOverride}
            modalAccessibilityClass={modalAccessibilityClass}
          />
        )}
      </div>
    </AccessibilityWrapper>
  );
};

export { DefaultTemplate };
