import { assign } from 'xstate';
import { buildMessage } from '../../../utilities/builders';

function buildParams({ tid, amount, ecrRcptNum, messNumber } = {}) {
  return {
    isRequestOrResponse: 'request',
    transactionName: 'void',
    tid,
    ecrRcptNumber: ecrRcptNum, // LEGACY, this field is for @fingermarkglobal/fab v0.0.2 support, needs to be removed after Chrome Shell update.
    voidRcptNum: ecrRcptNum, // Comes from the payment response
    messNumber, // Comes from the payment response
    amount, // Comes from the payment response
  };
}

const createPayload = assign((context, event) => {
  logger.info('marshal - createPayload - refund transaction started...');

  const { amount, ecrRcptNum, messNumber, config } = event || {};
  const { settings } = config || {};
  const { terminalId } = settings || {};

  logger.debug(`marshal - createPayload - event is ${JSON.stringify(event)}`);
  logger.debug(`marshal - createPayload - context is ${JSON.stringify(context)}`);
  logger.debug(`marshal - createPayload - config is ${JSON.stringify(config)}`);

  const params = buildParams({ tid: terminalId, amount, ecrRcptNum, messNumber });

  logger.debug(`marshal - createPayload - params are ${JSON.stringify(params)}`);

  const buffer = buildMessage(params);

  logger.debug(`marshal - createPayload - payload is [${buffer}]`);

  return {
    config,
    buffer,
  };
});

export { createPayload };
