import React from 'react';

import SolidArrowCircleRight from 'heroicons/solid/arrow-circle-right.svg';
import SolidArrowCircleLeft from 'heroicons/solid/arrow-circle-left.svg';
import MinimalArrowCircleRight from 'heroicons/solid/arrow-right.svg';
import MinimalArrowCircleLeft from 'heroicons/solid/arrow-left.svg';

import { useCategoriesHeader } from './hook';

import { LoadableImage } from '../loadable-image';

const CategoriesHeader = ({
  className = '',
  isItemRounded = true,
  isSliderEnabled = true,
  isIconMinimal = false,
  maxCategories = 9,
  offersAndRewardsCategories = [],
} = {}) => {
  const {
    formattedCategories = [],
    sliderContainer,
    hasChildLeftJump,
    hasChildRightJump,
    scrollRight,
    scrollLeft,
  } = useCategoriesHeader({ offersAndRewardsCategories });

  const wrapperStyles = `flex ${
    isSliderEnabled ? `flex-nowrap overflow-x-scroll hide-scroll-bar` : `flex-wrap bg-black`
  }`;

  const categoryItemStyles = `p-2 md:py-4 md:px-8 flex flex-col items-center ${
    isSliderEnabled ? `space-y-0 md:space-y-4` : `w-1/3`
  }`;

  const categoryImageStyles = `overflow-hidden ${
    isSliderEnabled
      ? `h-16 w-16 md:h-32 md:w-32 overflow-hidden border border-primary`
      : `h-16 w-16`
  } ${isItemRounded && 'rounded-full'}`;

  const categoryIconStyles =
    'categories-header-arrow-icon min-w-24 max-w-40 min-h-24 max-h-40 text-primary hidden md:block';

  const categoryIconStylesLeft = `${(hasChildLeftJump ? '' : 'invisible ') + categoryIconStyles}`;
  const categoryIconStylesRight = `${(hasChildRightJump ? '' : 'invisible ') + categoryIconStyles}`;

  return (
    <div
      data-test="categories-header"
      className="flex items-center justify-center w-full space-x-0 md:justify-between md:space-x-4 categories-header"
    >
      {isSliderEnabled && isIconMinimal ? (
        <MinimalArrowCircleLeft
          className={categoryIconStylesLeft}
          onClick={scrollLeft}
          data-test="scroll-left-button"
        />
      ) : (
        <SolidArrowCircleLeft
          className={categoryIconStylesLeft}
          onClick={scrollLeft}
          data-test="scroll-left-button"
        />
      )}

      <div className={`${wrapperStyles} categories-header-row ${className}`} ref={sliderContainer}>
        {formattedCategories
          .map(({ title, imageUrl, isSelected, onClick }, index) => (
            <div
              key={index}
              data-test="category-item"
              aria-selected={isSelected}
              className={`categories-header-item ${categoryItemStyles}`}
            >
              <div
                data-test="category-image"
                className={`categories-header-item-image ${categoryImageStyles}`}
                onClick={onClick}
              >
                <LoadableImage alt={title} src={imageUrl} />
              </div>
              <div
                className={`text-sm font-bold text-center md:text-2xl categories-header-item-text ${isSelected &&
                  `border-primary border-b-4 border-transparent`}`}
                aria-selected={isSelected}
              >
                {title}
              </div>
            </div>
          ))
          .slice(...(isSliderEnabled ? [] : [0, maxCategories]))}
      </div>

      {isSliderEnabled && isIconMinimal ? (
        <MinimalArrowCircleRight
          data-test="scroll-right-button"
          className={categoryIconStylesRight}
          onClick={scrollRight}
        />
      ) : (
        <SolidArrowCircleRight
          data-test="scroll-right-button"
          className={categoryIconStylesRight}
          onClick={scrollRight}
        />
      )}
    </div>
  );
};

export { CategoriesHeader };
