import React from 'react';

import { useAccessibility } from './hook';

import WheelchairIcon from '../../../assets/images/icons/accessibility/wheelchair.svg';
import StandingIcon from '../../../assets/images/icons/accessibility/standing.svg';

const AccessibilityWrapper = ({
  children,
  className = '',
  LogoImage = null,
  isAccessibilityButtonEnabled = false,
  style = {},
}) => {
  const { wrapperClassName, allowAccessibilityLogo } = useAccessibility({
    isAccessibilityButtonEnabled,
  });

  return (
    <>
      {allowAccessibilityLogo && LogoImage && (
        <div className="flex flex-col items-center mt-48">
          <LogoImage data-test="logo" className="w-64" />
        </div>
      )}
      <div
        id="accessibility"
        className={`w-full bottom-0 absolute overflow-y-hidden ${className} ${wrapperClassName}`}
        data-test="accessibility-wrapper"
        style={style}
      >
        {children}
      </div>
    </>
  );
};

const AccessibilityButton = ({ className }) => {
  const { toggleAccessibility, accessibilityEnabled } = useAccessibility();

  const accessibilityIconClass = 'w-full h-full fill-current text-accessibility';

  return (
    <button
      data-test="accessibility-button"
      className={`focus:outline-none bg-accessibility ${className}`}
      onClick={toggleAccessibility}
    >
      {accessibilityEnabled ? (
        <StandingIcon data-test="standing-icon" className={accessibilityIconClass} />
      ) : (
        <WheelchairIcon data-test="wheelchair-icon" className={accessibilityIconClass} />
      )}
    </button>
  );
};

export { AccessibilityWrapper, AccessibilityButton };
