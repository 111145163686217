import { validate } from '@fingermarkglobal/validation';
import { evaluateCount } from './utilities/count';
import { evaluateDisabledActions } from './utilities/disable';

// Evaluate the current cart / product / tree
// and update counts, price, etc
const evaluate = ({ product, categoryMax, categoryAutoBalance, categoryTotal } = {}) => {
  validate({ name: 'Cart Evaluate', paramater: { product } });
  const {
    options,
    customisations,
    count,
    isRootProduct = false,
    price,
    validated,
    isBag = false,
    isOffer,
  } = product;
  const { max, min } = count;

  const formattedCustomisations = customisations.map(category => {
    const { products, count, autoBalance } = category;
    const { max, min } = count;
    const totalCount = products.reduce((result, current) => result + current.count.current, 0);
    const evaluated = products.map(product =>
      evaluate({
        product,
        categoryMax: max,
        categoryAutoBalance: autoBalance,
        categoryTotal: totalCount,
      }),
    );
    const evaluatedTotalCount = evaluated.reduce(
      (result, current) => result + current.count.current,
      0,
    );
    const totalPrice = evaluated.reduce(
      (result, current) => (current.isAvailable ? result + current.price.current : result),
      0,
    );
    const ready = evaluatedTotalCount >= min && (evaluatedTotalCount <= max || max === -1);

    return {
      ...category,
      count: { ...count, current: evaluatedTotalCount },
      price: { current: totalPrice },
      ready,
      products: evaluated,
    };
  });

  const formattedOptions = options.map(option => {
    const { products, count } = option;
    const evaluated = products.map(product => evaluate({ product }));
    const totalCount = evaluated.reduce((result, current) => result + current.count.current, 0);
    const totalPrice = evaluated.reduce(
      (result, current) => (current.isAvailable ? result + current.price.current : result),
      0,
    );
    const ready = evaluated.filter?.(item => item.selected).every(product => product.ready);
    return {
      ...option,
      count: { ...count, current: totalCount },
      price: { current: totalPrice },
      ready,
      products: evaluated,
    };
  });

  const evaluatedCount = evaluateCount({ categoryMax, categoryAutoBalance, categoryTotal, count });

  const customisationsTotalPrice = formattedCustomisations.reduce(
    (acc, curr) => acc + curr.price.current,
    0,
  );
  const optionsTotalPrice = formattedOptions.reduce((acc, curr) => acc + curr.price.current, 0);

  const priceCalc = Math.max(evaluatedCount.current - evaluatedCount.initial, 0) * price.unit;

  const currentPrice = validated
    ? price.current
    : priceCalc + evaluatedCount.current * (optionsTotalPrice + customisationsTotalPrice);

  const updatedPrice = {
    ...price,
    current: currentPrice,
  };

  const customisationsReady = formattedCustomisations.every(category => category.ready);
  const optionsReady = formattedOptions.every(option => option.ready);

  const evaludatedDisabledActions = evaluateDisabledActions({
    categoryAutoBalance,
    categoryMax,
    currentItemQuantity: evaluatedCount?.current,
    isRootProduct,
    itemMax: max,
    itemMin: min,
    categoryTotal,
    isBag,
    isOffer,
  });

  return {
    ...product,
    ...evaludatedDisabledActions,
    count: evaluatedCount,
    selected: evaluatedCount?.current > 0,
    ready: customisationsReady && optionsReady,
    customisations: formattedCustomisations,
    options: formattedOptions,
    price: updatedPrice,
    evaluated: true,
  };
};

export { evaluate };
