import { assign } from 'xstate';

const handlePayment = assign((context, event) => {
  const { request, config } = context;
  const { amount } = request;
  const response = event.data;

  const { chequeInfo, transactionId } = response;

  const info = {
    paymentType: 'CARD',
    paymentMethod: chequeInfo.method,
    transactionId,
    authCode: chequeInfo?.authorizationCode || '',
    cardNumber: chequeInfo?.cardMask || '',
    cardType: chequeInfo?.icc || 'qr',
    amount,
  };

  logger.info('kaspi-pay - payment transaction finished');

  return {
    transactionId,
    timestamp: new Date().toISOString(),
    result: 'SUCCESS',
    config,
    info,
    message: '',
    response,
  };
});

export { handlePayment };
