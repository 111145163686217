import { Machine } from 'xstate';

const lockService = ({ id = 'lockMachine', initial = 'default' } = {}) => {
  const lockMachine = Machine({
    id,
    initial,
    states: {
      default: {
        on: {
          MANUAL_LOCK: 'manualLocked',
          AUTO_LOCK: 'autoLocked',
          AUTO_UNLOCK: 'autoUnlocked',
          MANUAL_UNLOCK: 'manualUnlocked',
        },
      },
      autoUnlocked: {
        on: {
          MANUAL_LOCK: 'manualLocked',
          AUTO_LOCK: 'autoLocked',
        },
      },
      manualUnlocked: {
        on: {
          AUTO_UNLOCK: 'autoUnlocked',
          MANUAL_LOCK: 'manualLocked',
        },
      },
      autoLocked: {
        on: {
          MANUAL_UNLOCK: 'manualUnlocked',
        },
      },
      manualLocked: {
        on: {
          MANUAL_UNLOCK: 'manualUnlocked',
        },
      },
    },
  });

  return { lockMachine };
};

export { lockService };
