export const generateUniqueReferenceNumber = () => {
  const now = new Date();
  const minute = now.getMinutes().toString().padStart(2, '0');
  const millisecond = now.getMilliseconds().toString().padStart(3, '0');
  let referenceBase = minute + millisecond;
  const neededDigits = 8 - referenceBase.length;
  let randomDigits = '';
  for (let i = 0; i < neededDigits; i++) {
    randomDigits += Math.floor(Math.random() * 10);
  }
  const referenceNumber = referenceBase + randomDigits;
  return referenceNumber;
};
