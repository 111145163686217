import { InquiryRequestBuilder } from '../../../builders';
import { PaymentContext, TransType } from '../../../types';
import { destructInquiryTransaction } from '../../../utils/destructerers/inquiry';
import { requestTransaction } from './request-transaction';

export const requestInquiryTransaction = async (context: PaymentContext) => {
  const response = await requestTransaction({
    RequestBuilder: InquiryRequestBuilder,
    context,
    transactionType: TransType.Inquiry,
  });

  return destructInquiryTransaction(response, context);
};
