import { getCurrentFormattedDate } from '../utils';
import { RequestBuilder } from './RequestBuilder';
import { PaymentConfig, CancelMessage, TransType } from '../types';

export class CancelRequestBuilder extends RequestBuilder {
  constructor({
    amount,
    config,
    idempotencyKey,
    referenceNumber,
  }: {
    amount: number;
    config: PaymentConfig;
    idempotencyKey: string;
    referenceNumber: string;
  }) {
    super({
      method: 'POST',
      amount,
      config,
      idempotencyKey,
      referenceNumber,
    });
  }

  setBody(): void {
    const { settings } = this.config;
    const { isLocal, responseChannelType, messageType, responsePayloadFormat } = settings;

    const { identifiers } = this.getIdentifiersFromPairingResponse();

    const cancelMessage: CancelMessage = {
      responseChannelType,
      responseChannel: {
        responsePayloadFormat,
      },
      messageId: this.getIntegratorId(),
      messageType,
      message: {
        identifiers: {
          ...identifiers,
          deviceId: this.getDeviceId(),
          integratorId: this.getIntegratorId(),
        },
        referenceNumber: this.referenceNumber,
        transType: TransType.Cancel,
      },
      transactionDateTime: getCurrentFormattedDate(isLocal),
      cashierId: 'Cashier1',
    };

    this.body = cancelMessage;
  }

  getBody(): CancelMessage {
    return this.body;
  }
}
