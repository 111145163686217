import { interpret } from '@fingermarkglobal/utilities';
import { validate } from '@fingermarkglobal/validation';
import { restaurantMachine as machine } from '../machines/restaurant';

const resolveRestaurant = ({ config = null, transition = null } = {}) => {
  validate({ name: 'Resolve restaurant', paramaters: { config } });

  const { run } = interpret({
    name: 'restaurantMachine',
    machine,
    transition,
  });

  return run;
};

export { resolveRestaurant };
