import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useLoadableImage } from './hook';

import defaultImage from './images/default-image.png';

const Wrapper = ({ children, placeholder = null }) => {
  if (placeholder) return <div className="mb-6 sm:mb-0 sm:mr-6 md:mr-12">{children}</div>;
  return <>{children}</>;
};

const LoadableImage = ({
  src = null,
  className = '',
  placeholder = null,
  customDefaultImage = defaultImage,
  'data-test': dataTest = 'loadable-image',
  ...props
}) => {
  const imageSrc = src || customDefaultImage;

  const { hiddenImageClassName, onLoad, isLoading, handleFailure } = useLoadableImage({
    customDefaultImage,
  });

  const defaultSkeletonHeight = 0;
  const defaultSkeletonClassName = 'w-full pt-full';
  const skeletonHeight = placeholder ? placeholder.height : defaultSkeletonHeight;
  const skeletonWidth = placeholder ? placeholder.width : undefined;
  const skeletonClassName = placeholder ? '' : defaultSkeletonClassName;

  return (
    <>
      {isLoading && (
        <Wrapper placeholder={placeholder}>
          <SkeletonTheme>
            <Skeleton
              data-test="skeleton-loader"
              height={skeletonHeight}
              width={skeletonWidth}
              className={`${skeletonClassName}`}
            />
          </SkeletonTheme>
        </Wrapper>
      )}
      <img
        {...props}
        data-test={dataTest}
        key={imageSrc}
        src={imageSrc}
        onLoad={onLoad}
        onError={handleFailure}
        className={`${hiddenImageClassName} ${className}`}
      />
    </>
  );
};

export { LoadableImage };
