import { interpret } from '@fingermarkglobal/utilities';
import { createLoyaltyOrderHistoryMachine } from './machines/loyalty-order-history';

const doLoyaltyOrderHistory = ({ transition = null, token, organisationId, sessionId = '' }) => {
  const machine = createLoyaltyOrderHistoryMachine({
    token,
    organisationId,
    sessionId,
  });

  const interpretedMachine = interpret({
    name: 'loyaltyOrderHistory',
    machine,
    transition,
  });

  const getLoyaltyFullOrder = (sessionId: string) => {
    interpretedMachine.run({ event: { type: 'GET_ORDER', sessionId } });
  };

  return { loyaltyOrderHistory: interpretedMachine.instance, getLoyaltyFullOrder };
};

export { doLoyaltyOrderHistory };
