import { useLocaleSelection } from '../../../../hooks/generic/locale-selection';
import { loyaltyState } from '@fingermarkglobal/atoms';
import { useRecoilState } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import { useSettingsAtPath } from '@fingermarkglobal/utilities';
import { useAccessibility } from '../../accessibility/hook';
import { useModalPromise } from '../../../stateless/modal/useModalPromise';
import { useCancelOrder } from '../../../../hooks/restaurant/order/useCancelOrder';

const useLoyaltyBanner = () => {
  const { t } = useLocaleSelection();
  const history = useHistory();
  const currentRoute = history?.location?.pathname || '';
  const { state: locationState } = useLocation();
  const [loyaltyUser, setLoyaltyUser] = useRecoilState(loyaltyState);
  const { accessibilityEnabled } = useAccessibility();

  const { modal, showModal } = useModalPromise({ timeoutSeconds: 20 });
  const { cancelOrder } = useCancelOrder();

  const modalAccessibilityClass = accessibilityEnabled
    ? 'h-half bottom-0 left-0 right-0'
    : 'inset-0';

  const loyaltyEnabled = useSettingsAtPath({
    path: 'settings.modes.loyalty',
    defaultValue: false,
  });

  const openLoyaltyLoginPage = () => {
    history.push('/loyalty-login', { ...locationState, from: currentRoute });
  };

  const { token } = loyaltyUser || {};

  const isLoggedIn = !!token;

  return {
    modal,
    showModal,
    cancelOrder,
    modalAccessibilityClass,
    loyaltyEnabled,
    isLoggedIn,
    loyaltyUser,
    history,
    t,
    openLoyaltyLoginPage,
  };
};

export { useLoyaltyBanner };
