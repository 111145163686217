import { request, getJwtToken } from '@fingermarkglobal/request';
import { buildGetOrderSummariesEndpoint, buildGetRequestOptions } from '../../shared';

interface GetOrderSummariesProps {
  organisationId: string;
  sessionIds: string[];
}

const logPrefix = '[fm-api][GET /order/summaries]';

const getOrderSummaries = async (
  { organisationId, sessionIds }: GetOrderSummariesProps,
  timeout: number = 30,
) => {
  logger.info(`${logPrefix} - started`);

  try {
    const endpoint = buildGetOrderSummariesEndpoint({});

    const token = await getJwtToken();
    if (!token) throw new Error(`${logPrefix} error generating jwt token`);

    const options = buildGetRequestOptions({
      endpoint,
      timeout,
      token,
      query: {
        organisationId,
        sessionIds,
      },
    });

    const response = await request.get(endpoint, options);

    if (!response.ok) {
      throw new Error(`${logPrefix} - failed with response {${response}}`);
    }

    console.info(`${logPrefix} - finished`, response);

    return response.json();
  } catch (err) {
    logger.error(`${logPrefix} error:`, err);
    throw err;
  } finally {
    logger.info(`${logPrefix} - finished`);
  }
};

export { getOrderSummaries };
