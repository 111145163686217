import { atom, selectorFamily, selector } from 'recoil';
import { formattedCustomerState } from '../customer';
import { sessionState } from '../session';
import { beaconState } from '../beacon';
import { formattedSettingsState } from '../system';
import { formattedPaymentState } from '../payment';
import { selectedOfferState } from '../loyalty';

const orderCountState = atom({
  key: 'orderCountState',
  default: null,
});

const orderTotalState = atom({
  key: 'orderTotalState',
  default: 0,
});

const orderServerTotalState = atom({
  key: 'orderServerTotalState',
  default: 0,
});

const orderState = atom({
  key: 'orderState',
  default: null,
});

const totalValidatedState = atom({
  key: 'totalValidatedState',
  default: null,
});

const paperBagAtom = atom({
  key: 'paperBagAtom',
  default: false,
});

const paperBagState = selector({
  key: 'paperBagState',
  get: ({ get }) => get(paperBagAtom),
  set: ({ set }, value) => {
    set(paperBagAtom, value);
  },
});

const formattedOrderPayloadState = selectorFamily({
  key: 'formattedOrderPayloadState',
  // `items` from cart needs to be passed in via selectorFamily params
  // because React hooks are not accessible from Recoil selectors
  get: ({ items } = {}) => ({ get }) => {
    const session = get(sessionState);
    const settings = get(formattedSettingsState);
    const customer = get(formattedCustomerState);
    const beacon = get(beaconState);
    const payment = get(formattedPaymentState);
    const validatedOrderPayload = get(validatedOrderPayloadState);
    const confirmedOrderPayload = get(confirmedOrderPayloadState);
    const selectedOffer = get(selectedOfferState);
    const { cart } = validatedOrderPayload || {};
    const { total: validatedTotal, items: cartValidatedItems } = cart || {};

    const calculatedRawTotal = items.reduce((result, current) => result + current.price, 0);
    const total = validatedTotal || calculatedRawTotal || 0;

    const { status, type: mode, id: sessionId, cancelReason = '', ...meta } = session || {};
    const { session: validatedOrderSession = {} } = validatedOrderPayload || {};
    const kitchenId = session?.kitchenId || validatedOrderSession?.kitchenId;
    const { financialSummary, orderNumber } = confirmedOrderPayload || {};

    const formattedItems = validatedOrderPayload
      ? items.map(item => {
          const newItem = cartValidatedItems.find(validatedItems => validatedItems.id === item.id);

          return {
            ...newItem,
            ...item,
          };
        })
      : items;

    const payload = {
      session: {
        ...meta,
        sessionId,
        customer,
        beaconNumber: beacon,
        kitchenId,
      },
      cart: {
        mode,
        items: formattedItems,
        total,
        selectedOffer,
      },
      status,
      payment,
      settings,
      orderNumber,
      financialSummary,
      cancelReason,
    };

    if (!beacon) delete payload.session.beaconNumber;

    return payload;
  },
});

const validatedOrderPayloadState = atom({
  key: 'validatedOrderPayload',
  default: null,
});

const confirmedOrderPayloadState = atom({
  key: 'confirmedOrderPayloadState',
  default: null,
});

export {
  orderState,
  formattedOrderPayloadState,
  validatedOrderPayloadState,
  confirmedOrderPayloadState,
  orderTotalState,
  orderCountState,
  totalValidatedState,
  paperBagState,
  orderServerTotalState,
};
