import React from 'react';

const SecondaryButtonLarge = ({
  children,
  disabled = false,
  className = 'capitalize',
  'data-test': dataTest = 'button-secondary',
  onClick = () => {},
}) => {
  return (
    <SecondaryButton
      className={`py-6 px-8 min-w-24 focus:outline-none border-secondary-button bg-secondary-button text-secondary-button secondary-button-large font-button-large text-button-large ${className}`}
      disabled={disabled}
      data-test={dataTest}
      onClick={onClick}
    >
      {children}
    </SecondaryButton>
  );
};

const SecondaryButton = ({
  children,
  disabled = false,
  className = 'capitalize',
  'data-test': dataTest = 'button-secondary',
  onClick = () => {},
}) => {
  return (
    <button
      className={`py-4 px-6 text-button rounded-button border-button border-secondary-button bg-secondary-button text-secondary-button disabled:opacity-50 font-button custom-secondary-button secondary-button font-button-regular ${className}`}
      disabled={disabled}
      data-test={dataTest}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export { SecondaryButton, SecondaryButtonLarge };
