import React from 'react';
export const MemberTitleComponent = ({ t, loyaltyUser: { points, name } }) => (
  <p
    data-test="loyalty-banner-member"
    className="loyalty-banner-text-wrapper-member"
    dangerouslySetInnerHTML={{
      __html: t('loyaltyBannerMember', {
        totalPoints: `<span class='bg-white rounded-full mx-1 px-4 py-4 text-primary'>${points}</span>`,
        name,
      }),
    }}
  />
);
