import { useMemo, useState, useCallback } from 'react';

import defaultImage from '../images/default-image.png';

const loadingImage = process.env.POI_APP_LOADING_THUMBNAIL;

const useLoadableImage = ({ customDefaultImage }) => {
  const [isLoading, setIsLoading] = useState(true);

  const hiddenImageClassName = useMemo(() => {
    if (!isLoading) return '';
    return 'hidden';
  }, [isLoading]);

  const onLoad = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const handleFailure = e => {
    // https://reactjs.org/docs/legacy-event-pooling.html
    // Prevents React from resetting its properties:
    e.persist();

    e.target.onerror = () => {
      e.target.src = customDefaultImage || defaultImage;
    };

    e.target.src = loadingImage;

    setIsLoading(false);
  };

  return { hiddenImageClassName, onLoad, isLoading, handleFailure };
};

export { useLoadableImage };
