import React from 'react';
import { useTimeoutModal } from './hook';
import { Modal as FallbackModal } from '../../stateless/modal';

const TimeoutModal = ({ modalAccessibilityClass, onTimeout, Modal = FallbackModal }) => {
  const { t, showWarning, timeLeft, reset, formatPrice } = useTimeoutModal({ onTimeout });

  return (
    <Modal
      data-test="timeout-modal"
      className={modalAccessibilityClass}
      open={showWarning}
      title={t('timeoutModalTitle')}
      closeText={t('timeoutModalSecondaryAction')}
      closeModal={reset}
      formatPrice={formatPrice}
    >
      <p className="text-3xl p-8 modal-text modal-content">
        {t('timeoutModalContentMessage', { timeLeft })}
      </p>
    </Modal>
  );
};

export { TimeoutModal };
