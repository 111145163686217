class OrderMapperError extends Error {
  constructor(message) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Custom debugging information
    this.name = 'OrderMapperError';
  }
}
const mapCustomisation = (orderProduct, menuProduct) => {
  const mappedProduct = {
    ...menuProduct,
    parentCategory: orderProduct.parentCategory,
    sku: orderProduct.product.id,
    productId: orderProduct.product.id,
    name: {
      text: orderProduct.product.name,
      translations: orderProduct.product.nameTranslations,
    },
    categories: mapCategories(orderProduct.product, menuProduct, 'customise'),
  };

  return mappedProduct;
};

const mapCombo = (orderProduct, menuProduct) => {
  const filterId = orderProduct.product.filterId; // NOTE: Can we rely on this to be consistent?

  /**
   * Sometimes the id is comparing "1" and 1 so we have to make sure its comparing the same type
   * Combo selection is selecting size the user chose E.g. regular or large
   */
  const menuComboSelection = menuProduct.options.find(option => `${option.id}` === `${filterId}`);

  if (!menuComboSelection) {
    logger.warn('orderMapper - No menuComboSelection found');
    throw new OrderMapperError('No menuComboSelection found');
  }

  const menuComboOptions = menuComboSelection.options;
  const filterName = menuComboSelection.name;

  /**
   * Here we are going through each of the combo products and making sure they have the correct
   * customisations using the categories field as this is what the cart uses to apply the correct customisations to that product
   */

  // NOTE: I think we need to detect when there is a order combo product that doesn't exist on the menu which may mean looping over combo products instead?
  const mappedComboOptions = menuComboOptions.map((menuComboOption, menuComboOptionIndex) => {
    const products = menuComboOption.products.map(menuComboProduct => {
      const menuComboProductSelected =
        menuComboProduct.id === orderProduct.product.comboProducts[menuComboOptionIndex].id;

      if (menuComboProductSelected) {
        const orderComboProduct = orderProduct.product.comboProducts[menuComboOptionIndex];
        return {
          ...menuComboProduct,
          quantity: 1, // Have I got this right?
          categories: mapCategories(orderComboProduct, menuComboOption, 'combo'),
        };
      } else {
        return {
          ...menuComboProduct,
          quantity: 0,
        };
      }
    });

    /**
     * Inside each combo option is an array of products
     * which are the choices in what the user can choose from
     *
     * E.g. for drinks you can chose Pepsi, 7up etc
     *
     * The matched combo product looks through the orderProducts comboProducts and
     * finds what item matches the combo options modifierGroupId
     */

    /**
     * Here we have found the orderItem we want to add and now we are
     * altering the comboOption to the correct mapping for commit to take
     */
    const mappedOption = {
      ...menuComboOption,
      products,
    };

    return mappedOption;
  });

  //This structure was found when logging what a product from the menu looked like just before commitable was called
  return {
    ...menuProduct,
    id: menuComboSelection.id,
    price: menuComboSelection.price,
    priceChanges: menuComboSelection.priceChanges,
    options: mappedComboOptions,
    parentCategory: orderProduct.parentCategory,
    filterName: filterName,
  };
};

const mapCategories = (orderProduct, menuOption, type) => {
  let menuCategories = [];

  switch (type) {
    case 'combo':
      // WE NEED TO FIND THE RIGHT CATEGORIES FOR COMBO PRODUCTS
      menuCategories = findMenuCategories(orderProduct.id, menuOption);
      break;
    case 'customise':
      // IF ITS CUSTOMISE TYPE THEN CATEGORIES ALREADY EXISTS
      menuCategories = JSON.parse(JSON.stringify(menuOption.categories));
      break;
    default:
      menuCategories = [];
  }

  orderProduct.customisations.forEach(orderCustomisation => {
    const specificMenuCategory = menuCategories.find(
      category => category.id === orderCustomisation.categoryId,
    );

    if (!specificMenuCategory) {
      logger.warn('orderMapper - No specificMenuCategory found');
      throw new OrderMapperError('No specificMenuCategory found');
    }

    // NOTE: Could find the index here
    let matchedMenuCategoryProduct = specificMenuCategory.products.find(
      p => p.productId === orderCustomisation.productId,
    );

    if (matchedMenuCategoryProduct) {
      // Create a new object with the properties of matchedMenuCategoryProduct
      matchedMenuCategoryProduct = {
        ...matchedMenuCategoryProduct,
        quantity: orderCustomisation.quantity,
      };

      // Find the index of the original product in the specificMenuCategory products array
      const productIndex = specificMenuCategory.products.findIndex(
        p => p.productId === orderCustomisation.productId,
      );

      // Replace the original product with the modified product
      specificMenuCategory.products[productIndex] = matchedMenuCategoryProduct;
    } else {
      logger.warn('orderMapper - No matchedMenuCategoryProduct found');
      throw new OrderMapperError('No matchedMenuCategoryProduct found');
    }
  });

  return menuCategories;
};

const findMenuCategories = (productId, menuOption) => {
  const menuProduct = menuOption.products.find(product => product.id === productId);
  if (!menuProduct || !menuProduct.categories) {
    return [];
  }
  const clonedCategories = JSON.parse(JSON.stringify(menuProduct.categories));
  return clonedCategories;
};

const mapOrderItems = (orderItems, menuProducts) => {
  const mappedOrderItems = [];

  const unmappedOrderItems = [];

  for (const orderItem of orderItems) {
    const product = menuProducts.find(p => p.id === orderItem.id);
    if (!product) {
      unmappedOrderItems.push(orderItem);
      continue;
    }

    //There could be multiple of the same product
    try {
      for (let item = 0; item < orderItem.quantity; item++) {
        switch (product.type) {
          case 'simple':
            const updatedProduct = { ...product, parentCategory: orderItem.parentCategory };
            mappedOrderItems.push({ mappedProduct: updatedProduct, type: 'commit' });
            break;
          case 'customise':
            const mappedCustomisation = mapCustomisation(orderItem, product);
            mappedOrderItems.push({ mappedProduct: mappedCustomisation, type: 'stage' });
            break;
          case 'combo':
            const mappedCombo = mapCombo(orderItem, product);
            mappedOrderItems.push({ mappedProduct: mappedCombo, type: 'stage' });
            break;
          //composed and grouped products cannot be ordered so we dont need to handle mapping them
          case 'composed':
          case 'grouped':
            break;
          default:
            break;
        }
      }
    } catch (error) {
      if (error instanceof OrderMapperError) {
        unmappedOrderItems.push(orderItem);
      } else {
        throw error;
      }
    }
  }

  return { mappedItems: mappedOrderItems, unmappedItems: unmappedOrderItems };
};

export { mapOrderItems };
