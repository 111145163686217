import { assign } from 'xstate';
import { formatAmountInCents } from '@fingermarkglobal/utilities';

const createPayload = assign((context, { amount, config }) => {
  logger.info('verifone - payment transaction started...');

  const amountInCents = formatAmountInCents({ amount });

  const transactionId = Date.now() % 100000000;
  const request = {
    previousTransactionId: context?.previousTransaction?.id,
    transactionId,
    amount,
    amountInCents,
  };

  return {
    config,
    request,
  };
});

export { createPayload };
