import { assign } from 'xstate';

const destructError = assign((context, event) => {
  logger.info('kaspi-pay - error: ', event.data);

  return {
    ...context,
    message: event.data?.message,
    ...event.data,
  };
});

export { destructError };
