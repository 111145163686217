const buildRequest = ({ body, config, category, amount, transactionId }) => {
  const { settings } = config;
  const { poiId: POIID } = settings;

  const header = {
    MessageClass: 'Service',
    MessageCategory: category,
    MessageType: 'Request',
    POIID,
  };

  const request = {
    SaleToPOIRequest: {
      MessageHeader: header,
      ...body,
    },
  };

  return {
    body: request,
    amount,
    transactionId,
  };
};

export { buildRequest };
