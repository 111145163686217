import { interpret } from '@fingermarkglobal/utilities';
import { validate } from '@fingermarkglobal/validation';
import { paymentMachine as machine } from './machines/pay';

const pay = ({ amount = null, config = null, transition = null } = {}) => {
  validate({ name: 'Verifone Pay', paramaters: { amount, config } });

  const { instance: paymentMachine } = interpret({
    name: 'paymentMachine',
    machine,
    transition,
  });

  paymentMachine.send('INITIALISE', { amount, config });

  return { paymentMachine };
};

export { pay };
