import { FallbackLogger } from '@fingermarkglobal/logger';

declare global {
    interface Window {
            logger: any
    }
}

window.logger = window.logger || new FallbackLogger();

export { shortenUrl } from './lib/url-shorten';