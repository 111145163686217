import React from 'react';
import { SecondaryButton } from '../../stateless/secondary-button';

const DefaultGuestTitleComponent = ({ t }) => (
  <div className="flex flex-col">
    <p data-test="loyalty-banner-title" className="loyalty-banner-text-wrapper-title">
      {t('loyaltyBannerTitle')}
    </p>
    <p data-test="loyalty-banner-subtitle" className="loyalty-banner-text-wrapper-title">
      {t('loyaltyBannerSubtitle')}
    </p>
  </div>
);

export const LoggedOut = ({
  t,
  GuestTitleComponent = DefaultGuestTitleComponent,
  openLoyaltyLoginPage,
}) => (
  <div data-test="loyalty-banner-logged-out" className="loyalty-banner">
    <div className="loyalty-banner-text-wrapper">
      <GuestTitleComponent t={t} />
    </div>
    <div className="loyalty-banner-button-wrapper">
      <SecondaryButton
        className="loyalty-banner-button-wrapper-login"
        data-test="loyalty-banner-login"
        onClick={openLoyaltyLoginPage}
      >
        {`${t('loyaltyBannerLogin')}`}
      </SecondaryButton>
    </div>
  </div>
);
