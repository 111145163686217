const toCamelCase = inputString => {
  if (!inputString) return '';

  if (/^[a-z][a-zA-Z0-9]*$/.test(inputString)) {
    return inputString;
  }

  const words = inputString.toLowerCase().split(' ');

  if (words.length === 1) {
    return words[0];
  } else {
    const camelCaseWords = words.map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    );

    return camelCaseWords.join('');
  }
};

const arrayToString = arr =>
  arr.reduce((result, item) => {
    if (!result.length) return `${item}`;
    return `${result}, ${item}`;
  }, '');

const findAndReplaceByUID = (array, object) =>
  array.map(item => (item.uid === object.uid ? object : item));

const isThereSpecialCharacters = word => {
  if (word === '') return false;
  const regex = /^[A-Za-z0-9ƯĐƠĂÂÊÔก-๙ ]+$/;
  return !regex.test(word);
};

const getFirstName = firstName => {
  const maxNameCharsLimit = 13;

  if (firstName?.length > maxNameCharsLimit) return firstName.substring(0, 13);

  return firstName;
};

const getDisplayOrderNumber = ({
  beacon = null,
  customer = null,
  orderNumber = null,
  aloha = null,
  oracleMicros = null,
  showDotAfterInitials = false,
  showFullFirstName = false,
  shouldSkipCustomerDetailsPath,
}) => {
  if (beacon) return beacon;
  if (aloha?.lastNameOrderReference) return customer.lastName;
  if (oracleMicros) return orderNumber;
  if (customer && customer?.firstName && !shouldSkipCustomerDetailsPath)
    return showFullFirstName
      ? `${getFirstName(customer?.firstName)}. ${customer?.lastName?.substring(0, 1)}.`
      : `${customer?.firstName?.substring(0, 1)}${
          showDotAfterInitials ? '.' : ''
        }${customer?.lastName?.substring(0, 1)}${showDotAfterInitials ? '.' : ''}`;

  return orderNumber;
};

export {
  toCamelCase,
  arrayToString,
  findAndReplaceByUID,
  isThereSpecialCharacters,
  getFirstName,
  getDisplayOrderNumber,
};
