import React from 'react';

const EnvironmentBadge = ({ settings = {} } = {}) => {
  const env = process.env.POI_APP_ENV || 'unknown';

  if (env === 'prd' || env === 'canary') return null;

  const config = settings?.settings || {};
  const mocks = config?.mocks || {};
  const { order = false, payment = false, voucher = false } = mocks;
  const mocksEnabled =
    [order && 'O', payment && 'P', voucher && 'V'].filter(Boolean).join(' / ') || null;

  return (
    <div className="absolute z-50 w-screen h-64 overflow-hidden pointer-events-none">
      <div
        className={`absolute flex flex-col items-center justify-end h-48 -mr-28 pb-1 transform rotate-45 opacity-50 bg-primary ${
          mocksEnabled ? `w-80 -mt-14 top-1 -right-0.5` : `w-64 -mt-20 top-0 right-1`
        }`}
      >
        <div
          className="overflow-hidden text-6xl font-bold leading-none uppercase text-secondary"
          data-test="header-badge-env"
        >
          {env}
        </div>
        {mocksEnabled && (
          <div
            className="overflow-hidden text-4xl font-bold uppercase text-secondary"
            data-test="header-badge-env"
          >
            {mocksEnabled}
          </div>
        )}
      </div>
    </div>
  );
};

export { EnvironmentBadge };
