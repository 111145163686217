import React from 'react';
import { usePrice } from '../../../hooks/generic/utilities/price';
import { Loading } from '../../stateless/loading';
import { ModalFooter } from '../../stateless/modal-footer';
import { ModalHeader } from '../../stateless/modal-header';
import { OrderHistoryItemCard } from './OrderHistoryItemCard';
import { UnmappedOrderItemCard } from './UnmappedOrderItemCard';

const formatReorderModalDate = dateString => {
  const date = new Date(dateString);

  // adding leading 0 if day or month is single digit
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date
    .getFullYear()
    .toString()
    .slice(2);

  // formatting hour in 12-hour format (am/pm)
  let hour = date.getHours();
  let minutes = ('0' + date.getMinutes()).slice(-2);
  let ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // assigns 12 for 0 hour (12 am)

  const formattedDate = `${day}.${month}.${year} ${hour}:${minutes}${ampm}`;
  return formattedDate;
};

const DefaultOrderHistoryModal = ({
  t,
  order,
  modalOrder,
  unmappedOrderItems,
  addingOrderToCart,
  onConfirm,
  onCancel,
}) => {
  const { formatPrice } = usePrice();

  const hasAvailableItems = !!(modalOrder && modalOrder.find(product => product.isAvailable));
  const canAddToOrder = !addingOrderToCart && hasAvailableItems;

  const orderDate = order ? formatReorderModalDate(order.timestamp) : '';
  return (
    <>
      <ModalHeader title={t('loyaltyReorderTitle', { orderDate })} />
      {modalOrder || unmappedOrderItems ? (
        <div
          data-test="order-history-modal"
          id="order-history-modal"
          className="max-h-full p-2 space-y-8 overflow-y-auto md:p-8 overscroll-auto"
        >
          {modalOrder &&
            modalOrder.map((product, i) => (
              <OrderHistoryItemCard
                key={`cart-product-${i}`}
                product={product}
                t={t}
                removeText={t('cartRemoveAction')}
                formatPrice={formatPrice}
              />
            ))}

          {unmappedOrderItems &&
            unmappedOrderItems.map((item, i) => (
              <UnmappedOrderItemCard
                key={`unmapped-product-${i}`}
                item={item}
                t={t}
                removeText={t('cartRemoveAction')}
                formatPrice={formatPrice}
              />
            ))}
        </div>
      ) : (
        <div className="pt-12 pb-20">
          <Loading isFirstLoad />
        </div>
      )}

      <ModalFooter
        action={onConfirm}
        actionText={
          addingOrderToCart ? t('loyaltyReorderAddingToCart') : t('loyaltyReorderAddToCart')
        }
        allowAction={canAddToOrder}
        allowClose={modalOrder}
        closeModal={onCancel}
        closeText={t('loyaltyReorderCancel')}
      />
    </>
  );
};

export { DefaultOrderHistoryModal };
