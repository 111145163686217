import html2canvas from 'html2canvas';

const screenshot = () => () => {
  html2canvas(document.body, { logging: false });
};

const lock = ({ history = null, setLock = null, setLockMessageRecoilState = null } = {}) => async ({
  message = 'lockMessage',
  state = 'MANUAL_LOCK',
} = {}) => {
  try {
    setLockMessageRecoilState(message);
    await setLock(state);
    history.push('/lock');
  } catch (err) {
    logger.error(`cringer.components - lock - error: ${err}`);
  }
};

const unlock = ({ history = null, setLock = null } = {}) => async ({
  state = 'MANUAL_UNLOCK',
  redirect = true,
} = {}) => {
  try {
    await setLock(state);
    if (redirect) history.push('/home');
  } catch (err) {
    logger.error(`cringer.components - unlock - error: ${err}`);
  }
};

const reload = () => () => {
  window.location.href = '/';
};

const syncMenu = ({ isSyncMenuStateCalled = null, setIsSyncMenuStateCalled = null } = {}) => () => {
  try {
    if (isSyncMenuStateCalled) return;
    logger.info('cringer.components - syncMenu - pusher command received');

    return setIsSyncMenuStateCalled(true);
  } catch (err) {
    logger.error(`cringer.components - syncMenu - error: ${err}`);
  }
};

export { lock, unlock, screenshot, reload, syncMenu };
