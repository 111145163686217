import React from 'react';
import { useCancelOrderButton } from './hook';
import { Modal as FallbackModal } from '../../stateless/modal';
import { SecondaryButton } from '../../stateless/secondary-button';

const CancelOrderButton = ({
  Modal = FallbackModal,
  Button = SecondaryButton,
  className = '',
  children = null,
  'data-test': dataTest,
}) => {
  const {
    t,
    confirm,
    close,
    showModal,
    open,
    modalAccessibilityClass,
    formatPrice,
  } = useCancelOrderButton();

  return (
    <>
      <Button
        onClick={open}
        className={`focus:outline-none cancel-order-button ${className}`}
        data-test={dataTest}
      >
        {children}
      </Button>
      <Modal
        className={modalAccessibilityClass}
        open={showModal}
        title={t('cancelOrderButtonModalTitle')}
        action={close}
        closeModal={confirm}
        actionText={t('cancelOrderButtonModalPrimaryAction')}
        closeText={t('cancelOrderButtonModalSecondaryAction')}
        formatPrice={formatPrice}
      >
        <div className="p-2 xs:p-4 md:p-8 text-xl xs:text-2xl md:text-3xl cancel-order-modal-text">
          {t('cancelOrderButtonModalQuestion')}
        </div>
      </Modal>
    </>
  );
};

export { CancelOrderButton };
