import React from 'react';
import { useLoyaltyOrderHistory } from './hook';
import { DefaultOrderHistoryCard } from './DefaultOrderHistoryCard';
import { DefaultOrderHistoryModal } from './DefaultOrderHistoryModal';
import { Modal } from '../../stateless/modal';

const LoyaltyOrderHistory = ({
  OrderHistoryCard = DefaultOrderHistoryCard,
  OrderHistoryModal = DefaultOrderHistoryModal,
}) => {
  const {
    orderHistory,
    loyaltyEnabled,
    isLoggedIn,
    showReorderModal,
    selectedOrder,
    formattedReorderCart,
    unmappedOrderItems,
    addingOrderToCart,
    canGetOrder,
    onAddToOrder,
    onConfirm,
    onCancel,
    t,
  } = useLoyaltyOrderHistory();

  const orderedOrderHistory = [...orderHistory].sort(
    (a, b) => a.timestamp.localeCompare(b.timestamp) * -1,
  );

  if (!loyaltyEnabled || !isLoggedIn || orderHistory.length <= 0) return <></>;
  return (
    <>
      <div className="mx-8 mt-6">
        <h2 className="text-4xl font-bold">{t('loyaltyOrderHistoryTitle')}</h2>
        <div className="flex gap-4 overflow-x-scroll hide-scroll-bar p-2">
          {orderedOrderHistory.map((order, index) => (
            <div className="flex-none flex flex-col border w-1/3 my-4" key={index}>
              <OrderHistoryCard
                t={t}
                order={order}
                canGetOrder={canGetOrder}
                onAddToOrder={onAddToOrder}
              />
            </div>
          ))}
        </div>
      </div>
      <Modal open={showReorderModal}>
        <OrderHistoryModal
          t={t}
          order={selectedOrder}
          modalOrder={formattedReorderCart}
          unmappedOrderItems={unmappedOrderItems}
          addingOrderToCart={addingOrderToCart}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </Modal>
    </>
  );
};

export { LoyaltyOrderHistory };
