import React from 'react';
import { Trans } from 'react-i18next';

import { signaledFormatPrice } from '@fingermarkglobal/utilities';

import { getProductNameAccToLang } from '../../../utilities/misc';
import { useLocaleSelection } from '../../../hooks/generic/locale-selection';

const ItemAvailabilityWrapper = ({
  children,
  isAvailable,
  showAddonAvailability,
  formatAddonAvailability,
}) => {
  return !isAvailable && showAddonAvailability ? (
    <div
      data-test="item-availability-wrapper"
      className="flex items-center justify-between text-gray-400"
    >
      {children}
      <div className={`text-red-600 text-center ${formatAddonAvailability && `w-1/2`}`}>
        <Trans i18nKey="cartProductUnavailableAddon" />
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

const Checkbox = ({
  product,
  formatPrice = () => {},
  hideUnavailable = false,
  hideNoPrefixWhenDefaultNotSelected = false,
  showAddonAvailability,
  formatAddonAvailability = false,
}) => {
  const { name, selected, default: isInitial, price, title, isAvailable } = product;
  const { current: currentPrice, unit: unitPrice } = price;

  const { currentLanguage } = useLocaleSelection();
  const productLanguageText = getProductNameAccToLang(name, currentLanguage);

  if (hideUnavailable && !isAvailable) return null;

  if (isInitial && !selected && !hideNoPrefixWhenDefaultNotSelected)
    return (
      <div data-test="product-customisation-checkbox">
        <Trans
          i18nKey="productCustomisationsReadonlyProductRemoved"
          values={{ name: title || productLanguageText || name.text }}
        />
      </div>
    );

  if (!selected || isInitial) return null;

  return (
    <ItemAvailabilityWrapper
      isAvailable={isAvailable}
      showAddonAvailability={showAddonAvailability}
      formatAddonAvailability={formatAddonAvailability}
    >
      <div data-test="product-customisation-checkbox">
        {title || productLanguageText || name.text}
        {!!currentPrice && ` ${signaledFormatPrice({ formatPrice, price: unitPrice })}`}
      </div>
    </ItemAvailabilityWrapper>
  );
};

const ExtraPrice = ({ count, price, formatPrice = () => {} }) => {
  const { current: currentPrice, unit: unitPrice } = price || {};
  const { initial: initialCount } = count || {};

  return (
    !!currentPrice && (
      <span data-test="extra-price" className="capitalize">
        {!!initialCount && ` ${signaledFormatPrice({ formatPrice, price: currentPrice })}`}
        {!initialCount && (
          <Trans
            i18nKey="productCustomisationsReadOnlyEach"
            values={{ price: ` ${signaledFormatPrice({ formatPrice, price: unitPrice })}` }}
          />
        )}
      </span>
    )
  );
};

const Quantity = ({
  product,
  showDefaultQuantity = true,
  showAddonAvailability,
  formatPrice = () => {},
  hideUnavailable = false,
  formatAddonAvailability = false,
}) => {
  const { name = {}, count, price, default: isDefault, title, isAvailable } = product;
  const { current: currentCount, initial: initialCount } = count;

  const { currentLanguage } = useLocaleSelection();
  const productLanguageText = getProductNameAccToLang(name, currentLanguage);

  const Wrapper = ({ children }) => (
    <div
      data-test="product-customisation-quantity"
      className={!isAvailable && showAddonAvailability && formatAddonAvailability ? `w-1/2` : ''}
    >
      {children}
    </div>
  );

  if (hideUnavailable && !isAvailable) return null;

  // no quantity for no default product shouldn't display
  if (!isDefault && !currentCount) return null;

  // default product and same current quantity as default quantity shouldn't display (flag toggle)
  if (!showDefaultQuantity && isDefault && currentCount === initialCount) return null;

  // default product and no quantity should display `No {productName}`
  if (isDefault && currentCount < initialCount && currentCount === 0)
    return (
      <Wrapper>
        <Trans
          i18nKey="productCustomisationsReadonlyProductRemoved"
          values={{ name: title || productLanguageText || name.text }}
        />
      </Wrapper>
    );

  return (
    <ItemAvailabilityWrapper
      isAvailable={isAvailable}
      showAddonAvailability={showAddonAvailability}
      formatAddonAvailability={formatAddonAvailability}
    >
      <Wrapper>
        {`${currentCount}x`}&nbsp;{title || productLanguageText || name.text}
        <ExtraPrice count={count} price={price} formatPrice={formatPrice} />
      </Wrapper>
    </ItemAvailabilityWrapper>
  );
};

const Content = ({
  category,
  showAddonAvailability,
  formatPrice = () => {},
  hideUnavailable = false,
  formatAddonAvailability = false,
  hideNoPrefixWhenDefaultNotSelected = false,
}) => {
  const { type, products } = category;

  switch (type) {
    case 'checkbox':
    case 'radio':
      return products?.map(product => (
        <Checkbox
          key={product?.id}
          product={product}
          formatPrice={formatPrice}
          hideUnavailable={hideUnavailable}
          showAddonAvailability={showAddonAvailability}
          formatAddonAvailability={formatAddonAvailability}
          hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
        />
      ));
    case 'quantity':
      return products?.map(product => (
        <Quantity
          key={product?.id}
          product={product}
          formatPrice={formatPrice}
          showDefaultQuantity={false}
          showAddonAvailability={showAddonAvailability}
          hideUnavailable={hideUnavailable}
          formatAddonAvailability={formatAddonAvailability}
        />
      ));
    case 'extra':
    case 'selectOption':
    case 'boolean':
      return products?.map(product => (
        <Quantity
          key={product?.id}
          product={product}
          showDefaultQuantity={false}
          formatPrice={formatPrice}
          showAddonAvailability={showAddonAvailability}
          hideUnavailable={hideUnavailable}
        />
      ));
    default:
      return null;
  }
};

const CategorySeparator = ({
  category,
  showAddonAvailability,
  formatPrice = () => {},
  hideUnavailable = false,
  formatAddonAvailability = false,
  hideNoPrefixWhenDefaultNotSelected = false,
}) => {
  const { products } = category;

  // If category contains any products that aren't default (and have been added) show 'extras' tag
  const shouldShowExtras = products.some(product => !product.default && product.count.current > 0);

  return shouldShowExtras ? (
    <>
      <div className="">
        <Content
          category={category}
          key={category.id}
          showAddonAvailability={showAddonAvailability}
          formatPrice={formatPrice}
          hideUnavailable={hideUnavailable}
          formatAddonAvailability={formatAddonAvailability}
          hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
        />
      </div>
    </>
  ) : (
    <Content
      category={category}
      key={category.id}
      showAddonAvailability={showAddonAvailability}
      formatPrice={formatPrice}
      hideUnavailable={hideUnavailable}
      formatAddonAvailability={formatAddonAvailability}
      hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
    />
  );
};

const ProductCustomisationsReadonly = ({
  customisations = [],
  className = 'text-3xl',
  formatPrice = () => {},
  showAddonAvailability = false,
  hideUnavailable = false,
  formatAddonAvailability = false,
  hideNoPrefixWhenDefaultNotSelected = false,
} = {}) => {
  return (
    <div data-test="product-customisations-readonly" className={className}>
      {customisations.map((category, index) => (
        <CategorySeparator
          key={`category-seperator-${index}`}
          category={category}
          showAddonAvailability={showAddonAvailability}
          formatPrice={formatPrice}
          hideUnavailable={hideUnavailable}
          formatAddonAvailability={formatAddonAvailability}
          hideNoPrefixWhenDefaultNotSelected={hideNoPrefixWhenDefaultNotSelected}
        />
      ))}
    </div>
  );
};

export { ProductCustomisationsReadonly };
