import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTransactions } from '../../generic/transactions';
import { sessionState } from '@fingermarkglobal/atoms';

const useCancelOrder = () => {
  const history = useHistory();
  const { addTransaction } = useTransactions();

  const setSession = useSetRecoilState(sessionState);
  const session = useRecoilValue(sessionState);

  const cancelReason = 'CANCELLED_BY_CUSTOMER';

  const cancelOrder = useCallback(() => {
    setSession({
      ...session,
      cancelReason,
    });
    addTransaction({ status: 'ORDER_CANCELLED' });
  }, [addTransaction, setSession, session]);

  useEffect(() => {
    if (session && !!session?.cancelReason) {
      history.push('/reset');
    }
  }, [history, session]);

  return { cancelOrder };
};

export { useCancelOrder };
