const translateProductName = i18nLang => name => {
  const key = name?.text;
  const language = i18nLang !== 'en' ? i18nLang : 'eng';
  const translations = name?.translations || [];

  // No key just return empty string
  if (!key) return '';
  const current = translations?.find(
    translation => translation?.language?.toLowerCase() === language?.toLowerCase(),
  );
  // Fallback to english key
  return current?.text || key;
};

export { translateProductName };
