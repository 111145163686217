import { atom, selector } from 'recoil';

const paymentState = atom({
  key: 'paymentState',
  default: null,
});

const formattedPaymentState = selector({
  key: 'formattedPaymentState',
  get: ({ get }) => {
    const payment = get(paymentState);

    const info = payment?.info;

    if (!info) return undefined;

    const { paymentType, transactionId, authCode, cardNumber, cardType, amount, response } = info;

    return {
      ...info,
      paymentType,
      transactionId,
      authCode: authCode || '',
      cardNumber,
      cardType,
      amount,
      response,
    };
  },
});

export { paymentState, formattedPaymentState };
